import { SizedSkeleton } from "@/comps/skeleton"
import { forwardRef, useEffect, useState } from "react"

export interface KeyframeWithSource {
	source: string
	time: number
}

export interface ExtractResult {
	keyframes: KeyframeWithSource[]
	duration: number
}

export function extractUniformFrames(
	frames: KeyframeWithSource[],
	targetLength: number,
): KeyframeWithSource[] {
	targetLength = Math.min(targetLength, frames.length)

	const skip = Math.floor(frames.length / targetLength)
	const result: KeyframeWithSource[] = []

	for (let i = 0; i < frames.length; i += skip) {
		result.push(frames[i])
	}

	while (result.length > targetLength) {
		result.pop()
	}

	return result
}

export function getVideoDurationMillis(
	videoFile: Blob,
): Promise<number> {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video")

		const videoUrl = URL.createObjectURL(videoFile)

		video.src = videoUrl
		video.preload = "metadata"
		video.muted = true

		video.load()
		video.onloadedmetadata = function () {
			resolve(video.duration * 1000)
		}

		video.onerror = function () {
			reject(frames)
		}
	})
}

export async function getVideoDimensions(
	videoFile: Blob,
): Promise<{ width: number; height: number }> {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video")

		const videoUrl = URL.createObjectURL(videoFile)

		video.src = videoUrl
		video.preload = "metadata"
		video.muted = true

		video.play()
		video.onloadedmetadata = function () {
			resolve({
				width: video.videoWidth,
				height: video.videoHeight,
			})
		}

		video.onerror = reject
	})
}

export async function getImageDimensions(image: Blob) {
	return new Promise<{ width: number; height: number }>(
		(resolve, reject) => {
			const img = new Image()
			img.src = URL.createObjectURL(image)
			img.onload = function () {
				resolve({
					width: img.naturalWidth,
					height: img.naturalHeight,
				})
			}
			img.onerror = reject
		},
	)
}

export function extractFramesFromVideo(
	videoFile: Blob,
	frameCount: number,
): Promise<ExtractResult> {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video")

		const videoUrl = URL.createObjectURL(videoFile)

		video.src = videoUrl
		video.preload = "metadata"
		video.muted = true

		video.play().then(() => video.pause())
		video.onloadedmetadata = function () {
			const duration = video.duration

			const canvas = document.createElement("canvas")
			canvas.width = video.videoWidth
			canvas.height = video.videoHeight

			const context = canvas.getContext("2d")
			if (!context) {
				reject(new Error("Could not get context"))
				return
			}

			const frames: KeyframeWithSource[] = []

			video.currentTime = 0
			const delta = duration / frameCount

			video.onseeked = function () {
				context.drawImage(
					video,
					0,
					0,
					video.videoWidth,
					video.videoHeight,
				)

				canvas.toBlob((blob) => {
					if (blob === null) {
						resolve({ keyframes: frames, duration })
						return
					}

					const source = URL.createObjectURL(blob)
					const time = video.currentTime

					frames.push({ source, time })

					if (frames.length >= frameCount) {
						resolve({ keyframes: frames, duration })
						return
					}

					video.currentTime += delta
				})
			}

			video.onerror = function () {
				reject(frames)
			}
		}
	})
}

export async function loadUrlToBlob(
	url: string,
): Promise<Blob> {
	const response = await fetch(url + "?")
	const blob = await response.blob()

	return blob
}

export interface ImageWithSkeletonProps
	extends React.AllHTMLAttributes<HTMLImageElement> {
	src: string
	className?: string
	onSuccess?: () => void
	onError?: (e: unknown) => void
}

export const ImageWithSkeleton = forwardRef<
	HTMLImageElement,
	ImageWithSkeletonProps
>(function ImageWithSkeleton(props, ref) {
	const {
		src,
		className,
		onSuccess,
		onError,
		...otherProps
	} = props
	const [url, setUrl] = useState<null | string>(null)

	useEffect(() => {
		if (url !== null) {
			return
		}

		loadUrlToBlob(src)
			.then((blob) => {
				setUrl(URL.createObjectURL(blob))
				onSuccess && onSuccess()
			})
			.catch((e) => {
				console.error(e)
				onError?.(e)
			})
	}, [onError, onSuccess, src, url])

	if (url === null) {
		return <SizedSkeleton className={className} />
	}

	return (
		<img
			ref={ref}
			className={className}
			src={url}
			alt="image skeleton"
			{...otherProps}
		/>
	)
})

ImageWithSkeleton.displayName = "ImageWithSkeleton"

export interface VideoWithSkeletonProps
	extends React.AllHTMLAttributes<HTMLVideoElement> {
	src: string
	className?: string
	onSuccess?: () => void
	onError?: (e: unknown) => void
}

export const VideoWithSkeleton = forwardRef<
	HTMLVideoElement,
	VideoWithSkeletonProps
>(function VideoWithSkeleton(props, ref) {
	const {
		src,
		className,
		onSuccess,
		onError,
		...otherProps
	} = props
	const [url, setUrl] = useState<null | string>(null)

	useEffect(() => {
		if (url !== null) {
			return
		}

		loadUrlToBlob(src)
			.then((blob) => {
				const videoBlob = new Blob([blob], {
					type: "video/mp4",
				})
				setUrl(URL.createObjectURL(videoBlob))
				onSuccess && onSuccess()
			})
			.catch((e) => {
				console.error(e)
				onError?.(e)
			})
	}, [onError, onSuccess, src, url])

	if (url === null) {
		return <SizedSkeleton className={className} />
	}

	return (
		<video
			ref={ref}
			className={className}
			src={url}
			{...otherProps}
		/>
	)
})

interface MediaDimensions {
	width: number
	height: number
}

export function resizeDimensions(
	dimensions: MediaDimensions,
	max: number,
): MediaDimensions {
	if (Math.max(dimensions.width, dimensions.height) < max) {
		return dimensions
	}

	if (dimensions.width > dimensions.height) {
		return {
			width: Math.floor(
				(max / dimensions.height) * dimensions.width,
			),
			height: max,
		}
	}

	return {
		width: max,
		height: Math.floor(
			(max / dimensions.width) * dimensions.height,
		),
	}
}

VideoWithSkeleton.displayName = "VideoWithSkeleton"

export async function getLoadedVideo(
	source: string | Blob,
) {
	return new Promise<HTMLVideoElement>(
		(resolve, reject) => {
			const video = document.createElement("video")
			video.preload = "metadata"
			const url =
				source instanceof Blob
					? URL.createObjectURL(source)
					: source

			video.src = url

			video.onloadedmetadata = function () {
				resolve(video)
				if (source instanceof Blob) {
					URL.revokeObjectURL(url)
				}
			}
			video.onerror = function (e) {
				reject(e)
				if (source instanceof Blob) {
					URL.revokeObjectURL(url)
				}
			}
		},
	)
}

export async function getLoadedImage(
	source: string | Blob,
) {
	return new Promise<HTMLImageElement>(
		(resolve, reject) => {
			const image = document.createElement("img")
			const url =
				source instanceof Blob
					? URL.createObjectURL(source)
					: source

			image.src = url

			image.onload = function () {
				resolve(image)
				if (source instanceof Blob) {
					URL.revokeObjectURL(url)
				}
			}
			image.onerror = function (e) {
				reject(e)
				if (source instanceof Blob) {
					URL.revokeObjectURL(url)
				}
			}
		},
	)
}
