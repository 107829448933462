import { CustomThemedResource } from "@/comps/image"
import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import { APP_HOST } from "@/utils/variables"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useContext } from "react"
import {
	DialogProps,
	SocialButton,
	socials,
} from "../history/generations"

interface ThanksPopupProps {
	open: boolean
	setOpen: (v: boolean) => void
	username: string
	image: string
	handleSocialShare: (
		desktop?: boolean,
		desktopFunction?: (link: string) => string,
		url?: (link: string) => string
	) => Promise<string>
	shareDialog: DialogProps
	setAsShared: () => void
	id: number
}

export function ThanksPopup(props: ThanksPopupProps) {
	const {
		open,
		username,
		image,
		id,
		setOpen,
		handleSocialShare,
		setAsShared,
		shareDialog,
	} = props

	const { t } = useTranslation()
	const { notify } = useContext(NotificationContext)

	async function handleCopyLink(
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		const target = e.currentTarget
		target.disabled = true
		setTimeout(() => {
			target.disabled = false
		}, 3000)

		try {
			await navigator.clipboard.writeText(
				`AI Art by ${username} on Zoomerang: ${APP_HOST}/ai-gallery/single/${id}`
			)
			notify(
				<SuccessMessage>
					{t("common:txt_successfully_copied")}
				</SuccessMessage>
			)
		} catch (e) {
			console.error(e)
			notify(
				<ErrorMessage>
					{t("common:txt_couldnt_copy")}
				</ErrorMessage>
			)
		}
	}

	return (
		<div
			className={clsx(
				open ? "flex" : "hidden",
				"fixed z-[999] flex items-start gap-[6px] tablet:top-[30%] desktop:left-1/2 desktop:top-1/2",
				"animate-appear tablet:left-[50%] tablet:-translate-x-1/2 tablet:-translate-y-1/2",
				"bottom-0 left-0 right-0 tablet:bottom-auto tablet:left-auto tablet:right-auto",
				"desktop:w-[576px] tablet:w-[538px]"
			)}>
			<div
				className={clsx(
					"flex items-start gap-[16px] rounded-[16px] bg-color-cell p-[14px] pr-0",
					"w-full tablet:rounded-[10px] justify-center tablet:justify-normal"
				)}>
				<img
					src={image}
					alt="image"
					className="hidden h-[224px] w-[171px] rounded-[4px] object-cover tablet:flex"
				/>
				<div
					className={clsx(
						"flex flex-col tablet:items-start gap-[7px] tablet:w-[337px]",
					)}>
					<div className="flex flex-col items-start gap-[10px] w-full">
						<span className="text-[20px] font-500 text-blue-900 tablet:text-[24px]">
							{t("txt_give_thanks")}
						</span>
						<div className="flex items-start gap-0">
							<p className="w-[150px] text-[16px] font-500 text-blue-700">
								{t("txt_give_thanks_desc")}
							</p>
							<span className="-ml-[10px] text-[16px] font-600 text-blue-800">
								{username}
							</span>
						</div>

						<div
							className={clsx(
								"items-center justify-between bg-color-background p-[14px]",
								"flex  rounded-[6px] border border-color-separator tablet:w-[323px]",
								"max-w-[347px] w-full"
							)}>
							<div className="flex items-center gap-[4px]">
								<span className="text-[16px] font-400 text-blue-600">
									AI Art by
								</span>
								<span className="w-[60px] truncate text-[16px] font-400 text-blue-800">
									@{username}
								</span>
								<span className="text-[16px] font-400 text-blue-600">
									on
								</span>
								<span className="text-[16px] font-400 text-blue-800">
									Zoomerang
								</span>
							</div>
							<button
								onClick={handleCopyLink}
								className={clsx(
									"relative flex items-center justify-center p-1",
									"group transition-colors hover:bg-blue-100",
									"shrink-0 overflow-visible rounded-md bg-color-background"
								)}>
								<img
									src={assetUrl(
										"/ai-tools/history/copy.svg"
									)}
									alt="copy"
									className="h-[20px] w-[20px]"
								/>
								<div
									className={clsx(
										"absolute -bottom-7 opacity-0 hover:opacity-0 group-hover:opacity-100",
										"rounded-[4px] bg-color-tooltip px-3 py-1 transition-all",
										"z-10 text-xs font-500 text-blue-900"
									)}>
									{t("txt_copy")}
								</div>
							</button>
						</div>
					</div>
					<div
						className={clsx(
							"no-scrollbar flex items-center gap-[8px] tablet:w-[335px] desktop:w-[337px]",
							"mb-[54px] overflow-hidden overflow-x-scroll pr-[14px] tablet:mb-0"
						)}>
						{socials.map((props) => (
							<SocialButton
								{...props}
								title=""
								handleSocialShare={handleSocialShare}
								dialogContent={shareDialog}
								setAsShared={setAsShared}
								key={props.icon ?? props.light}
							/>
						))}
					</div>
				</div>
			</div>

			<button
				className="hidden rounded-full bg-color-cell p-2 desktop:block"
				onClick={() => setOpen(false)}>
				<CustomThemedResource
					source="/general/close"
					format="svg"
					className="h-[16px] w-[16px]"
				/>
			</button>
		</div>
	)
}
