import Floating, { Relative } from "@/comps/floating"
import { useProgressive } from "@/comps/progressive"
import Skeleton from "@/comps/skeleton"
import { RawText } from "@/comps/text"

import { onDesktop, onTablet } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import { LastViewedContext } from "@/utils/last-viewed"
import getShortString from "@/utils/millions"
import clsx from "clsx"
import { useTranslation } from "next-i18next"

import { useContext, useMemo } from "react"
import { FlexBox, FlexItem } from "react-styled-flex"
import styled, { css } from "styled-components"

import {
  Template,
  TemplateReviewStatus,
} from "types/endpoints/tutorial"

interface PreviewProps {
  template: Template
  className?: string
  hideFeatured?: boolean
  setTemplateIndex?: (v: string) => void
}

const PreviewInfoIcon = styled.img`
  width: 14px;
  height: 14px;
`

const PreviewImageContainer = styled.div`
  width: calc(50vw - 24px);
  aspect-ratio: 275 / 342;

  ${onTablet(css`
    width: 233px;
    aspect-ratio: 275 / 342;
  `)}

  ${onDesktop(css`
    width: 275px;
    aspect-ratio: 275 / 342;
  `)}

	border-radius: 12px;

  overflow: hidden;
  object-fit: cover;

  & img {
    width: 100%;
  }
`

const Container = styled.a`
  display: flex;
  flex-direction: column;

  align-items: stretch;

  width: calc(50vw - 24px);

  ${onTablet(css`
    width: 233px;
  `)}

  ${onDesktop(css`
    width: 275px;
  `)}
`

const GradientBottom = styled.img`
  width: 100%;
  top: 0;
  left: 0;

  border-radius: 0 0 12px 12px;
`

const PreviewName = styled.div`
  height: 30px;

  margin: 8px 10px 0 0;
  font-weight: 700;
  font-size: 14px;

  ${onTablet(css`
    margin-left: 10px;
    font-size: 16px;
  `)}
  overflow: hidden;
`

const NameSkeleton = styled.div`
  height: 20px;
  border-radius: 30px;
  background-color: var(--color-skeleton);

  margin: 12px 64px 0 0;
  width: 100px;
`

const ImageSkeleton = styled(Skeleton)`
  border-radius: 12px;

  width: 100% !important;

  height: auto;
  aspect-ratio: 189 / 235;
`

const CoinContainer = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 2px 6px;

  display: flex;
  align-items: center;
  gap: 2px;

  border-radius: 8px;
`

interface LoadingProps {
  href: string
  className?: string
}

const Loading = (props: LoadingProps) => (
  <Container href={props.href} className={props.className}>
    <ImageSkeleton />
    <NameSkeleton />
  </Container>
)

const Preview = (props: PreviewProps) => {
  const {
    id,
    name,
    preview,
    views,
    setups,
    price,
    pricing,
    isFeatured,
    reviewStatus,
    privacy,
  } = props.template

  const { className, hideFeatured, setTemplateIndex } =
    props
  const priceTag = useMemo(
    () =>
      pricing === "coins" ? getShortString(price ?? 0) : "",
    [price, pricing]
  )

  const image =
    preview?.webp ?? preview?.gif ?? preview?.jpg
  const ready = useProgressive(image)

  const url = "/template/" + id

  const last = useContext(LastViewedContext) === id

  const { t } = useTranslation("common")

  if (!ready)
    return <Loading className={className} href={url} />

  const underReview =
    reviewStatus == TemplateReviewStatus.UnderReview

  const showForeground = last || underReview

  return (
    <Container
      href={url}
      className={className}
      onClick={() => setTemplateIndex?.(id)}>
      <Relative>
        <PreviewImageContainer>
          <img src={image} alt={name} />
        </PreviewImageContainer>
        {showForeground && (
          <div
            className={clsx(
              "absolute inset-0 flex flex-col items-center justify-center gap-1",
              "rounded-[12px] bg-color-black/80 text-sm font-600 text-color-white"
            )}>
            <img
              className="w-[20px]"
              src={assetUrl(
                underReview
                  ? "/player/warning.webp"
                  : "/player/play-preview.webp"
              )}
			  alt="play icon"
            />
            <span className="max-w-[100px] text-center">
              {underReview
                ? t("txt_under_review")
                : t("txt_last_viewed")}
            </span>
          </div>
        )}
        <Floating position={{ bottom: 0, left: 0 }}>
          <GradientBottom
            src={assetUrl(
              "/icons/home/templates/gradient-single.webp"
            )}
			alt="gradient image"
          />
        </Floating>
        <div className="absolute bottom-[6px] right-[10px]">
          {privacy === "private" && (
            <img
              src={assetUrl("/general/private-icon.svg")}
			  alt="private-icon"
              className="aspect-square w-[22px]"
            />
          )}
          {privacy === "friends" && (
            <img
              src={assetUrl("/general/friends-icon.svg")}
			  alt="friends-icon"
              className="aspect-square w-[22px]"
            />
          )}
        </div>
        <Floating position={{ top: 6, left: 6 }}>
          <FlexBox
            center
            justifyContent="flex-start"
            gap={2}>
            {pricing === "coins" && (
              <CoinContainer>
                <RawText
                  content={priceTag}
                  weight="700"
                  size="16"
                  color="white"
                />
                <img
                  src={assetUrl("/comps/coin.svg")}
                  alt="coin icon"
                  className="h-[20px]"
                />
              </CoinContainer>
            )}
          </FlexBox>
        </Floating>
        <Floating position={{ top: 15, left: 10 }}>
          {pricing === "pro" && (
            <img
              src={assetUrl("/icons/prime-icon.svg")}
              alt="coin icon"
              className="h-[20px]"
            />
          )}
        </Floating>
        <Floating position={{ bottom: 6, left: 10 }}>
          <div className="flex flex-col gap-1">
            {isFeatured && !hideFeatured && (
              <span className="self-start rounded-md bg-[linear-gradient(90deg,#4F6BFE_0%,#00A1FB_100%)] px-2 py-[2px] text-xs font-600 uppercase text-color-white">
                {t("lbl_featured")}
              </span>
            )}
            <FlexItem
              box
              alignContent="flex-start"
              gap={12}
              shrink={0}>
              <FlexBox center gap={2}>
                <PreviewInfoIcon
                  src={assetUrl(
                    "/player/play-preview.webp"
                  )}
				  alt="play icon"
                />
                <RawText
                  content={getShortString(views)}
                  size="14"
                  weight="600"
                  color="white"
                />
              </FlexBox>
              <FlexBox center gap={2}>
                <PreviewInfoIcon
                  src={assetUrl(
                    "/player/setup-preview.webp"
                  )}
				  alt="setup icon"
                />
                <RawText
                  content={getShortString(setups)}
                  size="14"
                  weight="600"
                  color="white"
                />
              </FlexBox>
            </FlexItem>
          </div>
        </Floating>
      </Relative>
      <PreviewName>{name}</PreviewName>
    </Container>
  )
}

export default Preview
