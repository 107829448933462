import Cookies from "@/comps/cookies"
import Favicon from "@/comps/favicon"
import "@/styles/globals.css"
import {
	AdjustProvider,
	UsePageViewEvent,
} from "@/utils/adjust"

import useClarity from "@/utils/clarity"
import { CoinsProvider } from "@/utils/coins"
import { GoogleOneTap } from "@/utils/googleapi"
import { NotificationProvider } from "@/utils/notification"
import useUnflash from "@/utils/unflash"

import {
	SSRAuthProvider,
	UserInfo,
} from "@/utils/client-auth"
import "intl-pluralrules"
import { appWithTranslation } from "next-i18next"
import { ThemeProvider } from "next-themes"
import type { AppProps } from "next/app"
import { ReactNode } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import {
	QueryClient,
	QueryClientProvider,
} from "react-query"
import { createGlobalStyle } from "styled-components"
import { EditorParamsProviders } from "../sections/editor/editor"

export const queryClient = new QueryClient()

const GlobalStyle = createGlobalStyle`
	.grecaptcha-badge {
		display: none;
	}
`

interface CustomProps extends AppProps {
	Component: AppProps["Component"] & {
		theme?: string
		Layout?: (props: AppProviderProps) => JSX.Element
	}
}

const MyApp = (props: CustomProps) => {
	const { Component, pageProps } = props
	useUnflash()

	const { theme, Layout } = Component
	const { userInfo } = pageProps as {
		userInfo: UserInfo
	}
	useClarity(userInfo?.userId)

	const Providers = Layout ?? AppProviders

	return (
		<Providers theme={theme} userInfo={userInfo}>
			<Component {...pageProps} />
		</Providers>
	)
}

export interface AppProviderProps {
	theme?: string
	children: ReactNode
	userInfo: UserInfo
}

function AppProviders(props: AppProviderProps) {
	const { children, theme, userInfo } = props

	return (
		<ThemeProvider
			attribute="class"
			forcedTheme={theme}
			defaultTheme="system"
			enableSystem={true}>
			<GlobalStyle />
			<QueryClientProvider client={queryClient}>
				<SSRAuthProvider userInfo={userInfo}>
					<NotificationProvider timeout={3000}>
						<GoogleReCaptchaProvider
							scriptProps={{ async: true }}
							reCaptchaKey={
								process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!
							}>
							<CoinsProvider>
								<AdjustProvider>
									<Favicon />
									<Cookies />
									<EditorParamsProviders>
										{children}
									</EditorParamsProviders>
									<UsePageViewEvent />
									<GoogleOneTap />
								</AdjustProvider>
							</CoinsProvider>
						</GoogleReCaptchaProvider>
					</NotificationProvider>
				</SSRAuthProvider>
			</QueryClientProvider>
		</ThemeProvider>
	)
}

export default appWithTranslation(MyApp)
