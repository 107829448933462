import {
  AIPhotoshoot,
  deforumIcon,
  generativeFillIcon,
  lookAiIcon,
  musicGeneratorIcon,
  photoAiIcon,
  restyleIcon,
  textToImageIcon,
  textToVideoIcon,
  videoBGRemoverIcon,
} from "@/comps/ai-tools/icons"
import Footer from "@/comps/footer"
import Header from "@/comps/header"
import { ResponsiveImage } from "@/comps/image"
import Meta from "@/comps/meta"
import { Device, useDeviceSize } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { ReactNode, useEffect, useRef } from "react"

export const ALL_TOOLS = [
  "ai_restyle",
  "deform",
  "ai_photo",
  "text_to_image",
  "text_to_video",
  "ai_photoshoot",
  "generative_fill",
  "video_bg_remover",
  "ai_music_generator",
  "look_ai",
]

export type AllTools = (typeof ALL_TOOLS)[number]

export interface OtherProduct {
  tool: AllTools
  label: string
  location: string
  url: string
  icon: ReactNode
  mobileOnly?: boolean
}

const allTools: OtherProduct[] = [
  {
    tool: "deform",
    label: "txt_deforum_ai",
    location: "deforum",
    url: "/tools/ai-video-transformation",
    icon: deforumIcon(),
  },
  {
    tool: "ai_restyle",
    label: "txt_restyle_ai",
    location: "restyle",
    url: "/tools/ai-animation-generator",
    icon: restyleIcon(),
  },
  {
    tool: "ai_photo",
    label: "txt_photo_ai",
    location: "photo-ai",
    url: "/tools/ai-photo-editor",
    icon: photoAiIcon(),
  },
  {
    tool: "text_to_image",
    label: "txt_text_to_image",
    location: "text-to-image",
    url: "/tools/text-to-image",
    icon: textToImageIcon(),
  },
  {
    tool: "ai_photoshoot",
    label: "txt_ai_photoshoot",
    location: "ai-photoshoot",
    url: "/tools/ai-photoshoot-generator",
    icon: AIPhotoshoot(),
  },
  {
    tool: "text_to_video",
    label: "txt_text_to_video",
    location: "text-to-video",
    url: "/tools/text-to-video",
    icon: textToVideoIcon(),
  },
  {
    tool: "look_ai",
    label: "txt_look_ai",
    location: "look-ai",
    url: "/tools/ai-portrait-generator",
    icon: lookAiIcon(),
    mobileOnly: true,
  },
  {
    tool: "video_bg_remover",
    label: "txt_video_bg_remover",
    location: "video-bg-remover",
    url: "/tools/video-background-remover",
    icon: videoBGRemoverIcon(),
    mobileOnly: true,
  },
  {
    tool: "ai_music_generator",
    label: "txt_ai_music_generator",
    location: "ai-music-generator",
    url: "/tools/royalty-free-music",
    icon: musicGeneratorIcon(),
  },
  {
    tool: "generative_fill",
    label: "txt_generative_fill",
    location: "generative-fill",
    url: "/tools/ai-image-replacer",
    icon: generativeFillIcon(),
    mobileOnly: true,
  },
]

export default function AllTools() {
  const { t } = useTranslation("common")

  return (
    <div className="flex min-h-[100vh] flex-col overflow-hidden bg-color-cell">
      <Meta
        title={t("lbl_ai_tools")}
        description={t("lbl_ai_tools")}
      />
      <Header />
      <div className="relative flex w-full flex-1 justify-center">
        <div className="pointer-events-none absolute left-0 top-0 w-full overflow-hidden px-4 tablet:-translate-y-[70px] desktop:left-[calc(50%-588px)] desktop:w-[1175px] desktop:px-0">
          <ResponsiveImage
            src="/ai-tools/index/intro"
            className="w-full desktop:w-[1175px]"
          />
        </div>

        <Tools />
      </div>

      <Footer />
    </div>
  )
}

function Tools() {
  const { t } = useTranslation("common")

  const device = useDeviceSize()

  return (
    <>
      <div className="relative mb-[20px] mt-[170px] flex w-full flex-col items-center justify-start tablet:mt-[215px] desktop:mt-[320px]">
        <div className="pointer-events-none absolute left-0 top-0 flex h-full w-full translate-y-[10%] flex-col overflow-x-clip tablet:translate-y-0 desktop:h-auto desktop:translate-y-[8%] desktop:overflow-auto">
          <img
            src={assetUrl(
              "/ai-tools/index/gradient-desktop.webp"
            )}
            className=" w-full flex-1 scale-x-[1.3] scale-y-[2] tablet:scale-[1.5] desktop:flex-none desktop:scale-100"
			alt="gradient"
          />

          <img
            src={assetUrl(
              "/ai-tools/index/gradient-desktop.webp"
            )}
            className="hidden w-full flex-1 scale-x-[1.1] tablet:block tablet:scale-[1.5] desktop:flex-none desktop:scale-100"
			alt="gradient"
          />
        </div>

        <div className="z-10 flex w-full flex-col items-center justify-start gap-7 tablet:gap-16 desktop:w-[1175px] desktop:gap-8">
          <h1 className="text-h3">{t("lbl_ai_tools")}</h1>

          <div
            className={clsx(
              "no-scrollbar flex w-full flex-col gap-3 px-4",
              "tablet:grid tablet:w-auto tablet:grid-cols-3 tablet:gap-4",
              "desktop:grid-cols-5 desktop:gap-6 desktop:px-0"
            )}>
            {allTools.map((product) => (
              <SingleProduct
                key={product.url}
                product={product}
                isMobile={device === Device.Mobile}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export interface OtherProductsSectionProps {
  product: OtherProduct
  isMobile: boolean
}

export function SingleProduct(
  props: OtherProductsSectionProps
) {
  const { product, isMobile } = props
  const { t } = useTranslation("common")

  const player = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (player.current)
      if (player.current.clientHeight >= 208)
        player.current.autoplay = false
      else player.current.play().catch(console.error)
  }, [player])

  return (
    <>
      <a
        href={product.url}
        className={clsx(
          "relative flex w-full shrink-0 flex-row items-center",
		  "justify-between gap-[10px] rounded-[8px] bg-color-cell py-[8px] pl-[8px] pr-[12px]",
          "tablet:items-start tablet:justify-start tablet:rounded-[14px] tablet:p-0 tablet:pb-4"
        )}>
        <p
          className={clsx(
            "absolute left-[10px] top-[10px] hidden h-[30px] items-center justify-center px-[10px]",
            " rounded-[40px] bg-color-black/40 backdrop-blur-[23px]",
            "text-[14px] font-500 text-color-white",
            product.mobileOnly !== true
              ? "hidden"
              : "hidden tablet:flex"
          )}>
          {t("txt_in_mobile")}
        </p>
        <div
          className={clsx(
            "flex flex-row items-center gap-[10px]",
            "tablet:w-full tablet:flex-col tablet:gap-[14px]"
          )}>
          <video
            muted
            loop
            playsInline
            preload="metadata"
            ref={player}
            onPointerEnter={(e) => {
              if (isMobile) return

              e.currentTarget.play().catch(console.error)
            }}
            onPointerLeave={(e) => {
              if (isMobile) return

              e.currentTarget.pause()
              e.currentTarget.currentTime = 0.001
            }}
            onTouchMove={(e) => {
              if (isMobile) return

              e.currentTarget.play().catch(console.error)
            }}
            onTouchEnd={(e) => {
              if (isMobile) return

              e.currentTarget.pause()
              e.currentTarget.currentTime = 0.001
            }}
            src={assetUrl(
              `/ai-tools/${product.location}/intro.mp4#t=0.001`
            )}
            className={clsx(
              "aspect-1 h-[88px] rounded-[6px] object-cover object-top tablet:h-auto",
              "transition-all duration-300 tablet:rounded-[14px] tablet:hover:rounded",
              "atablet:h-[208px] adesktop:h-[200px] tablet:aspect-none tablet:w-full"
            )}
          />
          <div className="flex flex-col gap-[3px] tablet:w-full tablet:gap-[10px] tablet:px-4">
            <div className="flex flex-col items-start gap-[3px] text-[16px] font-600 text-blue-700 [--icon-color:var(--color-blue-700)] tablet:flex-row tablet:items-center tablet:gap-3 tablet:text-[18px] tablet:text-blue-800 desktop:text-[20px]">
              {product.icon}
              <p className=" w-[80%] truncate leading-[19px] tablet:leading-normal">
                {t(product.label)}
              </p>
            </div>
            <p className="line-clamp-2 w-full text-[12px] font-500 leading-[15px] text-blue-600 tablet:text-[16px] tablet:leading-normal tablet:text-blue-700">
              {t(`${product.label}_description`)}
            </p>
          </div>
        </div>
        <div className="[--icon-color:var(--color-placeholder)] tablet:hidden">
          <Arrow />
        </div>
      </a>
    </>
  )
}

export function Arrow() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66675 12L10.6667 8L6.66675 4"
        stroke="var(--icon-color)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { getServerSideProps } from "@/ssr/common"
