import { useBoop } from "@/comps/animate/display-trigger"
import {
  Hashtag,
  ShareRow,
  Username,
  Word,
} from "@/comps/template/panel"

import { appStoreConstant } from "@/utils/branch"

import { devices } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import HoverPopup from "@/utils/hover"
import prettyMillions from "@/utils/millions"
import showPopup, { PopupContext } from "@/utils/popups"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"

import { QRCodeCanvas } from "qrcode.react"
import React, {
  createContext,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { animated } from "react-spring"
import styled, { css } from "styled-components"
import { VideoContext, VideoProvider } from "video-provider"
import {
  TaggedTextSlice,
  TutorialTemplate,
} from "../types/endpoints/tutorial"
import Comments from "./template/comments"

export const playerMargin = 90

const PlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  -webkit-touch-callout: none;
`

const PlayButton = styled.img<{ show: boolean }>`
  width: 44px;
  height: 44px;

  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));

  transition: opacity 300ms ease-in-out;

  ${(props) =>
    !props.show &&
    css`
      opacity: 0 !important;
    `}
`

const PlayHover = styled.div<{ enabled: boolean }>`
  padding: 8px;
  background: rgba(0, 0, 0, 0);

  ${(props) =>
    props.enabled &&
    css`
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    `};

  border-radius: 100%;
  transition: background 200ms ease-in-out;
`

export const PlayWithProvider = () => {
  const player = useContext(VideoContext)
  if (player === null) return <></>

  const { paused, play, pause } = player

  return (
    <PlayContainer onClick={paused ? play : pause}>
      <PlayHover enabled={paused}>
        <PlayButton
          src={assetUrl("/icons/player/play.png")}
		  alt="play icon"
          show={paused}
        />
      </PlayHover>
    </PlayContainer>
  )
}

const TimelineContainer = styled.div<{ radius?: string }>`
  width: 100%;
  height: 2px;

  background: rgba(0, 0, 0, 0.4);

  @media ${devices.desktop} {
    height: 44px;

    ${(props) =>
      props.radius
        ? css`
            border-radius: ${props.radius};
          `
        : css`
            border-radius: 0 0 20px 20px;
          `}

    display: flex;
    flex-direction: row;

    gap: 16px;

    align-items: center;

    padding: 0 16px;
  }
`

export const NewTimeline = () => {
  const player = useContext(VideoContext)

  if (player === null) return <></>

  return (
    <div
      className={clsx("absolute bottom-0 left-0 w-full")}>
      <div
        className={clsx(
          "h-[2px] w-full bg-color-popup desktop:h-[44px]",
          "flex items-center gap-[16px] py-[16px]",
        )}></div>
    </div>
  )
}

export const Timeline = (props: { radius?: string }) => {
  const player = useContext(VideoContext)

  if (player === null) return <></>

  return (
    <TimelineContainer radius={props.radius}>
      <Progress />
      <Timestamp />
      <Volume />
    </TimelineContainer>
  )
}

const ProgressWrapper = styled.div`
  flex: 1;

  cursor: pointer;

  &:active {
    cursor: grabbing;
  }

  @media ${devices.desktop} {
    padding: 20px 0;
  }
`

const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 2px;

  background: rgba(255, 255, 255, 0.2);

  overflow: hidden;

  @media ${devices.desktop} {
    height: 4px;
    border-radius: 8px;
  }
`

const updateInterval = 300

const Hoverbar = styled.div.attrs<{ progress: number }>(
  (props) => ({
    style: {
      width: props.progress + "%",
    },
  }),
)<{ progress: number }>`
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;

  height: 4px;

  border-radius: 4px;

  background: var(--color-white);
  opacity: 0.2;
`

export const Progressbar = styled.div.attrs<{
  progress: number
}>((props) => ({
  style: {
    width: props.progress + "%",
  },
}))<{ progress: number }>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  height: 2px;

  transition: width ${updateInterval}ms linear;

  background: #ecedef;

  @media ${devices.desktop} {
    border-radius: 4px;
    height: 4px;
  }
`

export const Progress = () => {
  const [hoverProgress, setHoverProgress] = useState(0)

  const player = useContext(VideoContext)

  const ref = useRef<HTMLDivElement>(null)

  const calculateProgress = (mouse: number) => {
    const rect = ref.current?.getBoundingClientRect()
    if (!rect) return 0

    const x = rect.left
    const w = rect.width
    return (mouse - x) / w
  }

  type MoveHandlerReturnsNumber = (
    e: React.MouseEvent,
  ) => number
  const handleMoveCallback: MoveHandlerReturnsNumber = (
    e,
  ): number => {
    const progress = calculateProgress(e.clientX)

    if (progress > 0) {
      setHoverProgress(progress * 100)
    }

    return progress
  }

  const handleMove = useCallback(handleMoveCallback, [])

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      if (player === null) return

      const newProgress = handleMove(e)

      player.seek(newProgress * player.duration)
      setHoverProgress(0)
    },
    [player, handleMove],
  )

  if (player === null) return <></>

  const { now, duration } = player
  const progress = (now / duration) * 100

  return (
    <ProgressWrapper
      onClick={handleClick}
      onPointerMove={handleMove}
      ref={ref}>
      <ProgressContainer>
        <Progressbar progress={progress} />
        <Hoverbar progress={hoverProgress} />
      </ProgressContainer>
    </ProgressWrapper>
  )
}

const TimestampText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  text-align: right;
  width: 80px;

  display: none;

  @media ${devices.desktop} {
    display: block;
  }
`

export const prettyTime = (seconds: number): string => {
  const roundedSeconds = Math.floor(seconds)
  const remainder = roundedSeconds % 60
  const minutes = Math.floor(roundedSeconds / 60)

  return (
    minutes.toFixed(0).padStart(2, "0") +
    ":" +
    remainder.toFixed(0).padStart(2, "0")
  )
}

const Timestamp = () => {
  const player = useContext(VideoContext)

  if (player === null) return <></>

  const { now, duration } = player

  if (!duration) return <></>

  const time =
    prettyTime(now) + " / " + prettyTime(duration)

  return <TimestampText>{time}</TimestampText>
}

const VolumeIcon = styled.img`
  width: 20px;
  height: 20px;

  position: absolute;
  z-index: 70;
  top: 10px;
  left: 10px;
`

const VolumeStepIcon = styled(VolumeIcon)<{
  opacity: number
}>`
  opacity: ${(props) => props.opacity};

  transition: opacity 100ms ease-in-out;
`

const VolumeControlWrapper = styled.div`
  position: absolute;
  top: -190px;
  right: -54px;

  padding: 22px;
`

const VolumeBarForeground = styled.div<{ volume: number }>`
  height: ${(props) => props.volume * 78}px;
  background-color: var(--color-primary-500);
  border-radius: 4px;

  width: 4px;
`

const VolumeBarBackground = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  width: 4px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const VolumeBarBall = styled.div`
  width: 8px;
  height: 8px;

  margin: -4px -2px;
  background-color: #ecedef;
  border-radius: 100%;

  z-index: 100;
`

const VolumeControlContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  cursor: pointer;

  padding: 12px 0;

  width: 28px;
  height: 102px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  &:active {
    cursor: grabbing;
  }

  &:hover ${VolumeBarForeground} {
    width: 6px;
  }

  &:hover ${VolumeBarBall} {
    width: 10px;
    height: 10px;
  }
`

const VolumeButton = styled.div`
  position: relative;
  padding: 20px;

  cursor: pointer;

  flex-shrink: 0;

  width: 40px;
  height: 40px;

  display: none;

  @media ${devices.desktop} {
    display: block;
  }
`

const Volume = () => {
  const [holding, setHolding] = useState(false)

  const [base, setBase] = useState(false)
  const [floating, setFloating] = useState(false)

  const player = useContext(VideoContext)
  const ref = useRef<HTMLDivElement>(null)
  const volumeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleMouseUp = () => {
      if (holding) {
        setHolding(false)
      }
    }
    addEventListener("mouseup", handleMouseUp)
    return () =>
      removeEventListener("mouseup", handleMouseUp)
  }, [holding, setHolding])

  const handleMove: MouseEventHandler = useCallback(
    (e) => {
      if (player === null) return
      if (holding) {
        player.muted && player.mute(false)
        player.setVolume(calculateVolume(e.clientY))
      }
    },
    [player, holding],
  )

  if (player === null) return <></>

  const { muted, mute, volume, setVolume } = player

  const calculateVolume = (mouse: number) => {
    const rect = volumeRef.current?.getBoundingClientRect()
    if (!rect) return 0

    const y = rect.top
    const h = rect.height
    const result = 2 - (h - y + mouse) / h

    if (result > 1) return 1
    if (result < 0.05) return 0
    return result
  }

  const noSound = volume < 0.05 || muted

  const highOpacity = volume < 0.5 ? 0 : (volume - 0.5) * 2
  const mediumOpacity = volume > 0.5 ? 1 : volume * 2

  return (
    <div>
      <VolumeButton onClick={() => mute(!muted)} ref={ref}>
        {noSound ? (
          <VolumeIcon
            src={assetUrl("/icons/player/volume-slash.svg")}
			alt="volume icon"
          />
        ) : (
          <>
            <VolumeIcon
              src={assetUrl("/icons/player/speaker.svg")}
			alt="volume icon"
            />
            <VolumeStepIcon
              opacity={highOpacity}
			alt="volume icon"
              src={assetUrl(
                "/icons/player/volume-high.svg",
              )}
            />
            <VolumeStepIcon
              opacity={mediumOpacity}
			alt="volume icon"
              src={assetUrl(
                "/icons/player/volume-medium.svg",
              )}
            />
          </>
        )}
      </VolumeButton>
      <HoverPopup
        sibling={ref}
        {...{
          base,
          setBase,
          floating,
        }}>
        <VolumeControlWrapper
          onMouseEnter={() => base && setFloating(true)}
          onMouseLeave={() => setFloating(false)}>
          <VolumeControlContainer
            onPointerDown={(e) => {
              setHolding(true)
              muted && mute(false)
              setVolume(calculateVolume(e.clientY))
            }}
            onPointerMove={handleMove}>
            <VolumeBarBackground ref={volumeRef}>
              <VolumeBarBall />
              <VolumeBarForeground volume={volume} />
            </VolumeBarBackground>
          </VolumeControlContainer>
        </VolumeControlWrapper>
      </HoverPopup>
    </div>
  )
}

const ActionsNumber = styled.span<{ hide?: boolean }>`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  text-align: center;

  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `};
`

const ActionIcon = styled.img<{ big?: boolean }>`
  width: 28px;
  height: 28px;

  ${(props) =>
    props.big &&
    css`
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 1px solid var(--color-separator);
    `};
`

const Actions = ({ mobile }: { mobile?: boolean }) => {
  const template = useContext(TemplateContext)
  const popup = useContext(PopupContext)

  const { t } = useTranslation()
  const [isFollowed, setIsFollowed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById("hide-after-see")
        ?.classList.add("opacity-0")
    }, 5000)
  }, [isFollowed])

  const { userInfo } = useAuth()

  if (!template) return <></>

  const {
    comments,
    id,
    creator,
    share,
    name,
    allowComments,
  } = template

  const isSelf = userInfo.userId === creator.uid

  return (
    <div className="flex shrink-0 flex-col justify-end gap-[16px] pb-[40px]">
      <div
        className={clsx(
          "relative flex flex-col gap-[7px] desktop:hidden",
        )}>
        <a
          className="flex h-[44px] w-[44px] items-center justify-center rounded-full"
          href={"/" + creator.username}>
          <ActionIcon
            big
            onError={(e) =>
              e.currentTarget.setAttribute(
                "src",
                assetUrl("/icons/avatar-default.svg"),
              )
            }
            src={
              creator.picture ??
              assetUrl("/icons/avatar-default.svg")
            }
			alt="action icon"
          />
        </a>
        {isFollowed ? (
          <img
            src={assetUrl(
              "/general/check-other-primary.svg",
            )}
            alt="check icon"
            id="hide-after-see"
            className={clsx(
              "h-[20px] w-[20px] animate-appear",
              "absolute -bottom-[7px] left-1/2 -translate-x-1/2",
            )}
          />
        ) : (
          <button
            className={clsx(
              isSelf && "hidden",
              "absolute -bottom-[7px] left-1/2 -translate-x-1/2",
            )}
            onClick={async () => {
              setIsFollowed(!isFollowed)

              await axios.post("/api/user-follow", {
                uid_to: creator.uid,
              })
            }}>
            <img
              src={assetUrl(
                "/general/plus-other-primary.svg",
              )}
              alt="pluse icon"
            />
          </button>
        )}
      </div>

      <div
        className={clsx(
          "flex flex-col gap-[7px] desktop:hidden",
        )}>
        <div
          className="flex h-[44px] w-[44px] items-center justify-center rounded-full"
          onClick={() => {
            mobile &&
              showPopup(popup, (close) => (
                <Comments
                  allowed={allowComments}
                  close={close}
                  template={id}
                  count={comments}
                  username={creator.username}
                />
              ))
          }}>
          <ActionIcon
            src={assetUrl("/icons/player/comments.svg")}
			alt="comment icon"
          />
        </div>
        <ActionsNumber>
          {allowComments ? prettyMillions(comments) : 0}
        </ActionsNumber>
      </div>

      <div
        className={clsx(
          "flex flex-col gap-[7px] desktop:hidden",
        )}>
        <div
          className="flex h-[44px] w-[44px] items-center justify-center rounded-full"
          onClick={() => {
            mobile &&
              showPopup(popup, (_) => (
                <ShareRow
                  share={share ?? appStoreConstant}
                  name={name}
                />
              ))
          }}>
          <ActionIcon
            src={assetUrl("/player/share.webp")}
			alt="share icon"
          />
        </div>
        <ActionsNumber>{t("lbl_share")}</ActionsNumber>
      </div>
    </div>
  )
}

const UseTemplateButton = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  width: calc(100% - 32px);
  max-width: 400px;

  margin: 0 16px;

  @media ${devices.desktop} {
    display: none;
  }

  padding: 16px 0;
  text-align: center;
  border-radius: 10px;

  align-self: center;
  justify-self: center;

  background-color: var(--color-primary-500);

  transition: background-color 300ms ease-in-out;

  &:hover {
    background-color: #e4164b;
  }
`

const QrContainer = styled.div`
  border-radius: 4px;
  padding: 4px;
  background-color: #ffffff;
`

const ScanText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--color-blue-700) !important;
`

const NameDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 60px;

  flex: 1;

  @media ${devices.desktop} {
    display: none;
  }
`

const NameText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
`

const DescriptionText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;

  display: flex;
  flex-wrap: wrap;
`

const CategoryText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  display: flex;
  align-items: center;
  gap: 4px;
`

export const MarkupTaggedSlice = (props: {
  tags: TaggedTextSlice[]
}) => (
  <>
    {props.tags.map((tag, index) => {
      switch (tag.format) {
        case "username":
          return (
            <Username key={index} href={"/user/" + tag.uid}>
              @{tag.label}
            </Username>
          )
        case "hashtag":
          return (
            <Hashtag key={index}>#{tag.content} </Hashtag>
          )
        default:
          return <Word key={index}>{tag.content}</Word>
      }
    })}
  </>
)

const NameDescription = () => {
  const { name, description, category, isFeatured } =
    useContext(TemplateContext) ?? {}

  const { t } = useTranslation()

  return (
    <NameDescriptionContainer>
      {isFeatured && (
        <span
          className={clsx(
            "self-start rounded-md bg-[linear-gradient(90deg,#4F6BFE_0%,#00A1FB_100%)]",
            "px-2 py-[2px] text-xs font-600 uppercase",
          )}>
          {t("lbl_featured")}
        </span>
      )}
      <NameText>{name}</NameText>
      <DescriptionText>
        <MarkupTaggedSlice tags={description || []} />
      </DescriptionText>
      {category && (
        <CategoryText>
          <img
            src={assetUrl("/player/category.svg")}
            alt="category icon"
          />
          {category}
        </CategoryText>
      )}
    </NameDescriptionContainer>
  )
}

const TemplateContext =
  createContext<TutorialTemplate | null>(null)

interface PlayerProps {
  template: TutorialTemplate
  active?: boolean
}

const Player = (props: PlayerProps) => {
  const { active, template } = props

  const { preview, creator, video, share, name, id } =
    template

  const [buttonSelected, setButtonSelected] =
    useState(false)
  const [qrSelected, setQrSelected] = useState(false)
  const [hidden, setHidden] = useState(true)

  const ref = useRef<HTMLVideoElement>(null)
  const { t } = useTranslation()

  if (hidden && (buttonSelected || qrSelected))
    setHidden(false)

  const style = useBoop({
    scale: 1.3,
    trigger: buttonSelected || qrSelected,
    close: () => {
      setHidden(true)
    },
  })

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      ref.current?.pause()
    }
  }, [ref])

  useEffect(() => {
    if (active) {
      ref.current?.play().catch(console.error)

      const artwork = creator.picture
        ? [
            {
              src: creator.picture,
              sizes: "512x512",
              type: "image/jpeg",
            },
          ]
        : [
            {
              src: assetUrl("/icons/avatar-default.svg"),
              sizes: "68x68",
              type: "image/svg+xml",
            },
          ]

      if (navigator.mediaSession)
        navigator.mediaSession.metadata = new MediaMetadata(
          {
            title: name,
            artist: creator.username,
            album: "Zoomerang",
            artwork,
          },
        )
    } else ref.current?.pause()
  }, [active, creator.picture, creator.username, name, ref])

  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
    )

    return () =>
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange,
      )
  }, [handleVisibilityChange])

  return (
    <div
      className={clsx(
        !active && "opacity-20",
        "flex snap-start scroll-mt-0 flex-col justify-center text-[#ffffff]",
        "desktop:transition-opacity desktop:duration-100 desktop:ease-in-out",
        "desktop:scroll-mt-[0px] desktop:last-of-type:mb-[170px]",
        "h-full items-center ",
      )}
      data-index={id}>
      <div
        className={clsx(
          "relative flex select-none flex-col items-center justify-center",
          "tablet:rounded-[20px]",
        )}
        style={{
          ...({
            WebkitMediaControls: {
              display: "none !important",
            },
          } as React.CSSProperties),
        }}>
        <video
          ref={ref}
          preload="none"
          src={video + "#t=0.001"}
          loop
          playsInline
          autoPlay={active}
          poster={preview.jpg}
          className={clsx(
            "cursor-pointer tablet:max-w-[447px] tablet:object-contain desktop:max-w-none",
            "adesktop:max-w-[406px] aspect-[9/16]",
            "adesktop:w-auto tablet:rounded-[20px]",
            "h-[100svh] tablet:h-full desktop:h-[calc(100svh-110px)]",
          )}
        />
        <VideoProvider
          element={ref}
          remember={["volume", "mute"]}>
          <TemplateContext.Provider value={template}>
            <div
              className={clsx(
                "absolute bottom-0 left-0 z-50 w-full",
                "flex flex-col items-stretch justify-end gap-[16px]",
                "tablet:rounded-b-[20px]",
                "bg-gradient-to-t from-[#191919] via-[#19191966] to-[#1919191A]",
              )}>
              <div
                className={clsx(
                  "flex items-center px-[16px] py-0",
                  "justify-between gap-[16px] desktop:hidden",
                )}>
                <NameDescription />
                <div className="absolute bottom-[60px] right-[16px]">
                  <Actions mobile />
                </div>
              </div>
              <UseTemplateButton href={share ?? "/"}>
                {t("txt_use_template")}
              </UseTemplateButton>
              <Timeline />
            </div>
            <div className="absolute -right-[60px] bottom-[60px] hidden desktop:block">
              <Actions />
            </div>
            <PlayWithProvider />
          </TemplateContext.Provider>
        </VideoProvider>
      </div>
      <div className="relative hidden w-full desktop:flex">
        <button
          className={clsx(
            "h-[48px] rounded-[10px] text-[16px] font-700 leading-[19px]",
            "m-[16px] mt-[20px] w-full bg-primary-500 transition-colors duration-300 ease-in-out",
            "hover:bg-primary-600 desktop:m-0 desktop:mt-[20px]",
          )}
          onMouseEnter={() => setButtonSelected(true)}
          onMouseLeave={() => setButtonSelected(false)}>
          {t("txt_use_template")}
        </button>
        <div
          className={clsx(
            hidden && "hidden",
            "absolute bottom-[50px] left-0 right-0 z-[60]",
            "flex items-center justify-center",
          )}>
          <animated.div style={style}>
            <div
              className="cursor-pointer px-[10px] pb-[80px] pt-[10px]"
              onMouseEnter={() =>
                buttonSelected && setQrSelected(true)
              }
              onMouseLeave={() => setQrSelected(false)}>
              <div
                className={clsx(
                  "rounded-[12px] px-[8px] pb-[16px] pt-[8px]",
                  "flex flex-col items-center gap-[8px] bg-color-cell",
                )}>
                <QrContainer>
                  <QRCodeCanvas
                    value={share ?? appStoreConstant}
                    size={150}
                    fgColor="#000000"
                    bgColor="#ffffff"
                  />
                </QrContainer>
                <ScanText>
                  {t("txt_scan_to_shoot")}
                </ScanText>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  )
}

export default Player
