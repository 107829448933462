import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import useScrollListener from "@/utils/listener"
import clsx from "clsx"

import { Crisp } from "crisp-sdk-web"
import { getAnalytics, logEvent } from "firebase/analytics"
import { useTranslation } from "next-i18next"
import { useCallback, useEffect, useState } from "react"
import styled, {
  createGlobalStyle,
  css,
} from "styled-components"

const Container = styled.div<{ show: boolean }>`
  ${(props) =>
    props.show
      ? "opacity:1;"
      : css`
          opacity: 0;
          pointer-events: none;
        `};

  position: fixed;
  z-index: 79;
  bottom: 24px;

  width: 52px;
  height: 52px;

  cursor: pointer;

  border-radius: 52px;

  background-color: var(--color-primary-500);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 300ms ease-in-out,
    200ms ease-in-out;

  right: 24px;

  :hover {
    background-color: var(--color-primary-600);
  }
`

const CrispIcon = styled.img`
  width: 30px;
  height: 30px;
`

const GlobalStyle = createGlobalStyle`
	#crisp-chatbox > div > a {
		display: none !important;
	}

	#crisp-chatbox > div > :first-child {
		right: 24px !important;
	}
`

const GlobalChatStyle = createGlobalStyle`
	#crisp-chatbox > div > a {
		display: none !important;
	}

	#crisp-chatbox > div > :first-child {
		right: 12px !important;
	}
`

interface CrispButtonProps {
  percent?: number
  page: string
}

const CrispButton = (props: CrispButtonProps) => {
  const { percent, page } = props
  const { userInfo } = useAuth()

  const [ready, setReady] = useState(false)
  const [opened, setOpened] = useState(false)

  const [threshold, setThreshold] = useState(false)

  useEffect(() => {
    Crisp.configure(
      process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID || "",
    )
    Crisp.session.onLoaded(() => {
      setReady(true)

      const isOpen = Crisp.chat.isChatOpened()
      setOpened(isOpen)

      Crisp.chat.onChatOpened(() => setOpened(true))
      Crisp.chat.onChatClosed(() => setOpened(false))
    })
  }, [])

  const scrollHandler = useCallback(() => {
    if (percent === undefined) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    )

    const full = height - window.innerHeight
    const now = window.scrollY

    const shouldHide = now / full < percent / 100

    setThreshold(shouldHide)
  }, [percent])

  useScrollListener(scrollHandler)

  const isActive = opened
  const showEvenInactive = ready && !threshold

  const logAdjust = useAdjustLogger()

  return (
    <>
      <GlobalStyle />
      <Container
        show={isActive || showEvenInactive}
        onClick={() => {
          $crisp.push(["do", "chat:toggle"])
          logEvent(getAnalytics(), "web_crisp_toggle", {
            page,
            uid: userInfo.userId,
          })
          logAdjust?.logEvent("contact")
        }}>
        <CrispIcon
          src={
            opened
              ? assetUrl("/comps/crisp-close.svg")
              : assetUrl("/comps/crisp.svg")
          }
		  alt="crisp icon"
        />
      </Container>
    </>
  )
}

export const CrispChatButton = () => {
  const { userInfo } = useAuth()
  const { t } = useTranslation()

  const [ready, setReady] = useState(false)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    Crisp.configure(
      process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID || "",
    )
    Crisp.session.onLoaded(() => {
      setReady(true)

      const isOpen = Crisp.chat.isChatOpened()
      setOpened(isOpen)

      Crisp.chat.onChatOpened(() => setOpened(true))
      Crisp.chat.onChatClosed(() => setOpened(false))
    })
  }, [])

  const logAdjust = useAdjustLogger()

  return (
    <>
      <GlobalChatStyle />
      <button
        disabled={!ready}
        className={clsx(
          "flex h-[44px] items-center justify-center tablet:w-[400px]",
          "cursor-pointer rounded-[8px] bg-color-chat hover:opacity-80",
          "max-w-[350px] gap-[8px] disabled:cursor-default disabled:opacity-80",
          "transition-colors duration-300",
        )}
        onClick={() => {
          $crisp.push(["do", "chat:toggle"])
          logEvent(getAnalytics(), "web_crisp_toggle", {
            page: "chat",
            uid: userInfo.userId,
          })
          logAdjust?.logEvent("contact")
        }}>
        <img
          src={assetUrl("/general/contact-support.png")}
          alt="contact support icon"
          className="h-[18px] w-[18px]"
        />
        <span className="text-[16px] font-500 text-color-white">
          {t("txt_contact_support")}
        </span>
      </button>
    </>
  )
}

export default CrispButton
