import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useContext } from "react"
import {
  DialogProps,
  SocialButton,
  socials,
} from "sections/history/generations"

interface ShareContentProps {
  shareDialog: DialogProps
  isPublic: boolean
  setShareDialog: (obj: DialogProps) => void
  id: number
  handleMakePublic?: (
    privacy: "public" | "private",
    id: number,
  ) => Promise<void>
  setAsShared?: () => void
}

export function ShareContent(props: ShareContentProps) {
  const {
    shareDialog,
    setShareDialog,
    id,
    handleMakePublic,
    setAsShared,
  } = props

  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)

  async function handleSocialShare(
    desktop?: boolean,
    desktopFunction?: (link: string) => string,
    url?: (link: string) => string,
  ): Promise<string> {
    try {
      if (!shareDialog.isPublic && handleMakePublic) {
        await handleMakePublic("private", id)
      }

      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })

      if (desktop && desktopFunction)
        return desktopFunction(shareDialog.link)
      else if (url) return url(shareDialog.link)
      else throw Error("No function")
    } catch (e) {
      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
      return ""
    }
  }

  async function handleCopyPrompt(text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  return (
    <div
      className={clsx(
        "fixed inset-0 flex items-end justify-start bg-color-popup tablet:items-center tablet:justify-center",
        shareDialog.open
          ? "z-[102] transition-opacity duration-300"
          : "hidden",
      )}
      id="popup-content"
      onClick={(event) => {
        if (event.target instanceof Element) {
          if (event.target.id === event.currentTarget.id) {
            setShareDialog({
              open: false,
              isPublic: false,
              link: shareDialog.link,
              id: null,
            })
          }
        }
      }}>
      <div
        className={clsx(
          "relative w-full rounded-t-2xl bg-color-cell pb-8 pt-4 transition-all",
          "tablet:w-auto tablet:rounded-2xl tablet:py-4",
          shareDialog.open
            ? "translate-y-0"
            : "translate-y-full tablet:translate-y-0",
        )}>
        <button
          onClick={() =>
            setShareDialog({
              open: false,
              isPublic: false,
              link: shareDialog.link,
              id: null,
            })
          }
          className={clsx(
            "absolute -right-10 top-0 hidden h-8 w-8 items-center justify-center rounded-full desktop:flex",
            "bg-color-cell transition-colors hover:bg-blue-100",
          )}>
          <img src={assetUrl("/general/close.svg")} alt="close icon" />
        </button>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-center gap-1 px-4">
            <p className="text-[18px] font-500 text-blue-700 tablet:text-[24px]">
              {t("txt_share")}
            </p>
            <div className="h-[1px] w-full bg-color-separator"></div>
          </div>
          <div className="flex flex-col gap-[22px]">
            <div
              className={clsx(
                "no-scrollbar flex flex-row gap-5 overflow-y-hidden overflow-x-scroll bg-color-cell px-4",
              )}>
              <div className="flex flex-col items-center gap-2">
                <button
                  onClick={() => {
                    handleCopyPrompt(shareDialog.link)
                    setAsShared && setAsShared()
                    setShareDialog({
                      ...shareDialog,
                      open: false,
                    })
                  }}
                  className={clsx(
                    "flex h-[52px] w-[52px] items-center justify-center rounded-full",
                    "bg-color-background p-3 transition-colors hover:bg-blue-100 disabled:cursor-default",
                    "disabled:hover:bg-primary-600/10",
                  )}>
                  <img
                    src={assetUrl(
                      "/ai-tools/history/copy-primary.svg",
                    )}
                    alt="copy icon"
                    className="h-[24px] w-[24px]"
                  />
                </button>
                <span className=" shrink-0 text-[14px] font-600 text-blue-600">
                  {t("lbl_link")}
                </span>
              </div>

              {socials.map((props) => (
                <SocialButton
                  {...props}
                  handleSocialShare={handleSocialShare}
                  setAsShared={setAsShared}
                  dialogContent={shareDialog}
                  key={props.icon ?? props.light}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
