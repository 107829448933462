import Preview from "@/comps/home/templates/preview"
import { CustomThemedResource } from "@/comps/image"
import Loader from "@/comps/loader"
import { UserGallery } from "@/ssr/profile"
import {
  Desktop,
  DesktopOrTablet,
  devices,
  Mobile,
  TabletOrMobile,
  wrapped,
} from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import {
  flattenAndFilterGallery,
  GalleryViewProvider,
  SingleGalleryPage,
  useGalleryViewContext,
} from "@/utils/discover"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import useInfiniteScroll from "@/utils/infinite"
import { LastViewedProvider } from "@/utils/last-viewed"
import { redirectToLogin } from "@/utils/login-redirect"
import prettyMillions from "@/utils/millions"
import queryfy from "@/utils/queryfy"
import {
  CreatedScrollContext,
  flattenAndFilter,
  LikedScrollContext,
  ScrollDataResult,
  setScrollContext,
  useScrollContext,
} from "@/utils/scroll-context"
import axios from "axios"
import clsx from "clsx"
import { serialize } from "cookie"
import { useTranslation } from "next-i18next"
import {
  galleryProducts,
  GalleryTools,
  indexFiltersGallery,
  MediaType,
  SortBy,
} from "pages/ai-gallery"
import { UserReportRequest } from "pages/api/user-report"
import { TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY } from "pages/template/[slug]"
import { useEffect, useMemo, useRef, useState } from "react"
import {
  UseInfiniteQueryResult,
  useQuery,
} from "react-query"
import Masonry, {
  ResponsiveMasonry,
} from "react-responsive-masonry"
import {
  SingleGenerated,
  sortLabels,
  sortOptions,
} from "sections/gallery/all-gallery"
import {
  GalleryPopup,
  GalleryPopupProps,
} from "sections/gallery/gallery-popup"
import { ReportPopup } from "sections/history/report-popup"
import styled, { css } from "styled-components"
import * as UserPosInLead from "types/endpoints/user-pos-in-lead"
import * as UserProfile from "types/endpoints/user-profile"
import {
  FollowStatus,
  Social,
  SocialProvider,
} from "types/endpoints/user-profile"
import {
  Template,
  TutorialTemplate,
  TutorialTemplateCreator,
} from "../types/endpoints/tutorial"
import {
  ButtonArrowIcon,
  FilterIcon,
  moreIcon,
} from "./ai-tools/icons"
import Button, { NewButton } from "./button"
import { DropDown } from "./dropdown"

const Container = styled.div`
  width: var(--w-screen);
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = styled.div`
  width: 100%;

  @media ${devices.tablet} {
    width: 728px;
  }

  @media ${devices.desktop} {
    width: 1175px;
  }
`

const TopWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${wrapped};

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: flex-end;
    gap: 0;
  }
`

const Maximize = styled.div`
  display: none;

  @media ${devices.tablet} {
    display: block;
    flex: 1;
  }
`

const TopContainer = styled(Container)`
  align-items: center;
  padding: 16px;

  @media ${devices.tablet} {
    padding-bottom: 12px;
  }
`

const BottomContainer = styled(Container)`
  background-color: var(--color-background-cell);
`

const ProfilePicture = styled.img`
  border-radius: 100%;

  width: 70px;
  height: 70px;
  border: 2px solid var(--color-separator);
`

const PictureAligner = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  @media ${devices.tablet} {
    visibility: hidden;
    width: 116px;
  }
`

const NamePositionContainer = styled.div`
  display: flex;
  gap: 10px;

  align-items: center;
`

const NameText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;

  @media ${devices.tablet} {
    line-height: 21px;
  }
`

const BioText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: var(--color-blue-700);

  @media ${devices.tablet} {
    display: none;
  }
`

const BioTextDesktop = styled(BioText)`
  @media ${devices.tablet} {
    display: block;
  }

  margin-top: 16px;
`

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 4px;

  @media ${devices.tablet} {
    gap: 10px;
  }
`

const SocialAnchor = styled.a`
  border: 1px solid var(--color-separator);
  border-radius: 8px;

  width: 32px;
  height: 32px;
  padding: 5px 0;

  background-color: var(--color-background-cell);
`

const SocialIcon = styled(CustomThemedResource)`
  height: 20px;
  margin: 0 auto;
`

const SocialPlusContainer = styled.div`
  border: 1px solid var(--color-separator);
  border-radius: 8px;

  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-background-cell);
`

const SocialPlus = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;

  color: var(--color-primary-500);
`

type SocialLinks = {
  [key in SocialProvider]: string
}

const socialLinks: SocialLinks = {
  tiktok: "https://tiktok.com/@",
  instagram: "https://instagram.com/",
  facebook: "https://facebook.com/",
  youtube: "https://youtube.com/channel/",
  snapchat: "https://snapchat.com/add/",
  discord: "https://discord.gg/",
  behance: "https://behance.net/",
  dribbble: "https://dribbble.com/",
  vimeo: "https://vimeo.com/",
  art_station: "https://artstation.com/",
}

interface SocialsProps {
  list: Social[]
}

const Socials = (props: SocialsProps) => {
  const { list } = props

  return (
    <>
      <Desktop>
        <SocialsContainer>
          {list.map(({ provider, account }, index) => (
            <SocialAnchor
              key={index}
              href={socialLinks[provider] + account}>
              <SocialIcon
                source={"/social/colorful/" + provider}
              />
            </SocialAnchor>
          ))}
        </SocialsContainer>
      </Desktop>

      <TabletOrMobile>
        <SocialsContainer>
          {list
            .filter((_, i) => i < 2)
            .map(({ provider, account }, index) => (
              <SocialAnchor
                key={index}
                href={socialLinks[provider] + account}>
                <SocialIcon
                  source={"/social/colorful/" + provider}
                />
              </SocialAnchor>
            ))}
          {list.length > 2 && (
            <SocialPlusContainer>
              <SocialPlus>+{list.length - 2}</SocialPlus>
            </SocialPlusContainer>
          )}
        </SocialsContainer>
      </TabletOrMobile>
    </>
  )
}

const LeaderboardContainer = styled.div<{ show: boolean }>`
  ${(props) => (props.show ? "opacity: 1" : "opacity: 0")};

  transition: opacity 200ms ease-in-out;

  display: flex;
  gap: 4px;
`

const LeadIconContainer = styled.div<{ full: boolean }>`
  height: 24px;

  display: flex;
  gap: 2px;

  align-items: center;

  padding: 4px 8px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const FireContainer = styled(LeadIconContainer)`
  background-color: rgba(154, 84, 244, 0.13);
  color: #a566f5;

  ${(props) =>
    props.full
      ? css`
          border-radius: 26px;
        `
      : css`
          border-radius: 26px 0 0 26px;
        `};
`

const StarContainer = styled(LeadIconContainer)`
  height: 24px;

  background-color: rgba(243, 84, 74, 0.13);
  color: #f3544a;

  ${(props) =>
    props.full
      ? css`
          border-radius: 26px;
        `
      : css`
          border-radius: 0 26px 26px 0;
        `};
`

const LeaderboardIcon = styled.img`
  width: 12px;
  height: 12px;
`

const getLeaderboard =
  (uid: string, token: string) =>
  async (): Promise<UserPosInLead.Response> =>
    axios
      .get(
        `/api/user-pos-in-lead` +
          queryfy({
            uid,
          }),
        {
          headers: {
            Authorization: "Bearer " + token,
            "web-version": HEADER_WEB_VERSION,
          },
        },
      )
      .then((res) => res.data)

interface LeaderboardProps {
  uid: string
}

const Leaderboard = (props: LeaderboardProps) => {
  const { uid } = props
  const { userInfo } = useAuth()

  const leaderboard = useQuery(
    "lead/" + uid,
    getLeaderboard(uid, userInfo.accessToken),
  )

  const fire = leaderboard.data?.trending ?? null
  const star = leaderboard.data?.top ?? null

  const show = leaderboard.data !== undefined

  return (
    <LeaderboardContainer show={show}>
      {fire && (
        <FireContainer full={star === null}>
          <LeaderboardIcon
            src={assetUrl("/general/fire.png")}
			alt="fire image"
          />
          {fire}
        </FireContainer>
      )}
      {star && (
        <StarContainer full={fire === null}>
          <LeaderboardIcon
            src={assetUrl("/general/star.png")}
			alt="star image"
          />
          {star}
        </StarContainer>
      )}
    </LeaderboardContainer>
  )
}

const StatsContainer = styled.div`
  display: flex;
  gap: 6px;

  width: 100%;

  padding: 8px 0;

  @media ${devices.tablet} {
    display: inline-flex;
    width: unset;
    gap: 0;
  }
`

const StatsSingle = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  gap: 4px;

  height: 60px;

  border-radius: 6px;
  background-color: var(--color-background-cell);

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: baseline;
    border-radius: 0;
    height: 21px;
    padding-right: 10px;

    & + & {
      border-left: 1px solid var(--color-separator);
      padding-left: 10px;
    }
  }
`

const StatsUnit = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--color-blue-400);

  text-transform: capitalize;
`

const StatsValue = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: var(--color-blue-800);
`

interface AllStatsProps {
  followers: number
  following: number
  shoots: number
}

const AllStats = (props: AllStatsProps) => {
  const { t } = useTranslation()

  const { followers, following, shoots } = props

  return (
    <StatsContainer>
      <StatsSingle>
        <StatsValue>{prettyMillions(following)}</StatsValue>
        <StatsUnit>{t("lbl_following")}</StatsUnit>
      </StatsSingle>

      <StatsSingle>
        <StatsValue>{prettyMillions(followers)}</StatsValue>
        <StatsUnit>{t("lbl_followers")}</StatsUnit>
      </StatsSingle>

      <StatsSingle>
        <StatsValue>{prettyMillions(shoots)}</StatsValue>
        <StatsUnit>{t("lbl_shoots")}</StatsUnit>
      </StatsSingle>
    </StatsContainer>
  )
}

const UserTemplatesContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
`

const CustomSwipePage = styled.div<{
  active: boolean
}>`
  width: calc(var(--w-screen));
  ${wrapped};

  transition: all 300ms;

  opacity: 1;
  transform: translateY(0);
  transition-delay: 200ms;

  ${(props) =>
    !props.active &&
    css`
      opacity: 0;
      transform: translateY(50px);
      transition-delay: 0ms;
      pointer-events: none;

      // TODO: use calc for different sizes
      height: 350px;
      overflow: hidden;
    `};

  grid-row-start: 1;
  grid-column-start: 1;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;

  flex-direction: row;
  flex-wrap: wrap;

  padding: 0 16px 16px;
  gap: 16px;

  @media ${devices.tablet} {
    gap: 14px;
    padding: 0 0 16px 0;
  }

  @media ${devices.desktop} {
    padding-bottom: 25px;
    gap: 25px;
  }
`

export const LockIconContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;

  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
`

export const LockIcon = styled(CustomThemedResource)`
  width: 128px;
`

const SmallLockIconContainer = styled.svg`
  path {
    stroke: var(--color-blue-400);
  }

  @media ${devices.tablet} {
    path {
      stroke: var(--color-blue-700);
    }
  }
`

const SmallLockIcon = () => (
  <SmallLockIconContainer
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6863 7.24821V5.45904C13.6863 3.36487 11.9879 1.66654 9.89378 1.66654C7.79962 1.65737 6.09462 3.34737 6.08545 5.44237V5.45904V7.24821"
      stroke="#919191"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.8859 11.8354V13.6863"
      stroke="#919191"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5498 9.95638C3.5498 8.80579 4.48255 7.87305 5.63314 7.87305H14.3668C15.5174 7.87305 16.4502 8.80579 16.4502 9.95638V15.6245C16.4502 16.7751 15.5174 17.7079 14.3668 17.7079H5.63314C4.48255 17.7079 3.5498 16.7751 3.5498 15.6245V9.95638Z"
      stroke="#919191"
      strokeWidth="2"
    />
  </SmallLockIconContainer>
)

const TemplatesSectionContainer = styled.div`
  display: flex;
  justify-content: center;

  width: var(--w-screen);

  background-color: var(--color-background-cell);
`

const TemplatesSectionWrapper = styled.div`
  width: var(--w-screen);

  ${wrapped};
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 50px 0;
`

const indicatorWidth = 120
const indicatorHeight = 38
const indicatorPadding = 4

const Indicator = styled.div<{ active: number }>`
  position: absolute;
  z-index: 10;

  width: ${indicatorWidth}px;
  height: ${indicatorHeight}px;

  border-radius: 5px;

  top: ${indicatorPadding}px;
  left: ${(props) =>
    props.active * indicatorWidth + indicatorPadding}px;

  transition: left 200ms ease-in-out;

  background-color: var(--color-background-cell);

  display: none;

  @media ${devices.tablet} {
    display: block;
  }
`

const SingleTab = styled.div<{
  active: boolean
  available: boolean
}>`
  display: flex;
  position: relative;
  z-index: 15;

  align-items: center;
  justify-content: center;
  gap: 4px;

  flex: 1;

  height: 50px;

  @media ${devices.tablet} {
    flex: unset;
    width: ${indicatorWidth}px;
    height: ${indicatorHeight}px;
  }

  font-weight: 600;
  font-size: 17px;
  line-height: 20px;

  cursor: pointer;

  ${(props) =>
    props.available && props.active
      ? css`
          color: var(--color-blue-900);
        `
      : css`
          color: var(--color-blue-400);
        `};

  @media ${devices.tablet} {
    color: var(--color-blue-700);
  }

  ${(props) =>
    !props.available &&
    css`
      pointer-events: none;
      cursor: unset;
    `};

  transition: color 200ms ease-in-out;
`

const TabsContainer = styled.div`
  display: flex;
  position: relative;

  @media ${devices.tablet} {
    display: inline-flex;
    background-color: var(--color-background);

    padding: ${indicatorPadding}px;
    border-radius: 8px;

    margin: 20px 0;
  }
`

const ProfilePictureContainer = styled.div`
  display: flex;
  align-items: flex-start;

  gap: 16px;
`

const BioColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ProfilePictureUnbound = styled.img`
  margin-top: -40px;

  width: 100px;
  object-fit: cover;
  aspect-ratio: 1;

  border-radius: 100%;
  border: 2px solid var(--color-separator);
`

export interface PaginatedPage<T> {
  data: T[]
  next?: number
}

interface SinglePageProps {
  query: UseInfiniteQueryResult<
    PaginatedPage<TutorialTemplate>
  >
  index: number
  active: number
  available: boolean
  uid: string
}

const SinglePage = (props: SinglePageProps) => {
  const { query, index, active, available, uid } = props

  const { t } = useTranslation("common")

  const ref = useRef<HTMLDivElement>(null)
  const [templateIndex, setTemplateIndex] = useState("")

  const enabled = active === index && available
  useInfiniteScroll(query, ref, 1000, enabled)

  const flat = useMemo(
    () => flattenAndFilter(query.data?.pages || []),
    [query],
  )

  useEffect(() => {
    const cookieValue = {
      location: active === 0 ? "created" : "likes",
      userId: uid,
      offset:
        flat.findIndex(
          (template) => template.id === templateIndex,
        ) ?? 0,
    }

    document.cookie = serialize(
      TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY,
      JSON.stringify(cookieValue),
      {
        sameSite: "lax",
      },
    )
  }, [active, templateIndex, flat, uid])

  const showTemplates = available && query.isSuccess
  const noTemplates =
    query.isSuccess &&
    !query.isFetching &&
    flat.length === 0

  return (
    <CustomSwipePage ref={ref} active={active === index}>
      {!available && (
        <LockIconContainer>
          <LockIcon source={"/general/private"} />
          {t("txt_is_private")}
        </LockIconContainer>
      )}

      {showTemplates && (
        <>
          {flat.map((template) => (
            <Preview
              setTemplateIndex={setTemplateIndex}
              template={template}
              key={template.id}
            />
          ))}
        </>
      )}

      {noTemplates && (
        <LockIconContainer>
          <LockIcon source={"/general/empty"} />
          {t("txt_is_empty")}
        </LockIconContainer>
      )}

      {query.isFetchingNextPage && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </CustomSwipePage>
  )
}

interface GalleryPageProps {
  query: UseInfiniteQueryResult<SingleGalleryPage>
  index: number
  active: number
  available: boolean
  username: string
  setGalleryPopupState: (
    gallery: GalleryPopupProps | null,
  ) => void
  setFilterDialogOpen: (open: boolean) => void
}

const GalleryPage = (props: GalleryPageProps) => {
  const {
    query,
    index,
    active,
    available,
    username,
    setGalleryPopupState,
    setFilterDialogOpen,
  } = props

  const { t } = useTranslation("common")

  const ref = useRef<HTMLDivElement>(null)

  const enabled = active === index && available
  useInfiniteScroll(query, ref, 1000, enabled)

  const flat = useMemo(
    () => flattenAndFilterGallery(query.data?.pages || []),
    [query],
  )

  const showTemplates = available && query.isSuccess
  const noTemplates =
    query.isSuccess &&
    !query.isFetching &&
    flat.length === 0

  return (
    <div
      ref={ref}
      style={{ gridColumnStart: 1, gridRowStart: 1 }}
      className={clsx(
        " flex w-full flex-col content-start items-start justify-start transition-all tablet:w-[728px] desktop:w-[1175px]",
        active !== index
          ? "pointer-events-none h-[350px] translate-y-12 overflow-hidden opacity-0 delay-0"
          : "translate-y-0 opacity-100 delay-200",
      )}>
      <div
        className={clsx(
          "flex w-full flex-col",
          !noTemplates && "pb-24",
        )}>
        <div className="flex w-full flex-row justify-between px-4 tablet:h-10 desktop:h-11 desktop:px-0">
          <MediaTypeGallery />
          <div className="hidden h-full flex-row items-center justify-center gap-4 tablet:flex">
            <SortByGallery />
            <AvailableFilters />
          </div>

          <button
            type="button"
            onClick={() => setFilterDialogOpen(true)}
            className="flex h-full w-10 items-center justify-center rounded-[8px] bg-color-background [--icon-color:var(--color-placeholder)] tablet:hidden desktop:w-11">
            <FilterIcon />
          </button>
        </div>
        <ResponsiveMasonry
          className={clsx(
            "mx-auto w-full gap-3 p-4 tablet:w-[768px] tablet:gap-4 desktop:w-[1175px] desktop:gap-[15px] desktop:px-0 desktop:pt-4",
            noTemplates && "hidden",
            !showTemplates && "hidden",
          )}
          columnsCountBreakPoints={{
            1: 2,
            760: 4,
            1280: 5,
          }}>
          <Masonry gutter="15px">
            {flat.map((single) => (
              <SingleGenerated
                key={single.id}
                entity={single}
                setGalleryPopupState={setGalleryPopupState}
                closeLink={`/${username}`}
                notShallow
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>

        <div ref={ref} />

        <div className="flex w-full justify-center p-8">
          {query.isFetchingNextPage && <Loader />}
        </div>
      </div>
      {noTemplates && (
        <LockIconContainer className="pb-32">
          <LockIcon source={"/general/empty-gallery"} />
          {t("txt_no_result_yet")}
        </LockIconContainer>
      )}
    </div>
  )
}

function AvailableFilters() {
  const { dispatch, state } = useGalleryViewContext()

  const { t } = useTranslation()

  const { availableFilters } = useGalleryViewContext()

  const styles = availableFilters.styles

  const uniqueFilters = styles
    .filter((style) =>
      state.selectedTools.includes(style.tool),
    )
    .map((style) => style.name)

  const filteredStyles = Array.from(new Set(uniqueFilters))

  const uniqueTools = Array.from(
    new Set(styles.map((style) => style.tool)),
  )

  const commonTools = galleryProducts.filter((tool) =>
    uniqueTools.some((product) => product === tool.tool),
  )

  const setStyle = (name: string) => {
    if (
      state.selectedStyles.find(
        (style) => style.name === name,
      )
    ) {
      dispatch({
        type: "remove_style",
        name: name,
      })

      return
    }
    dispatch({
      type: "select_style",
      name: name,
      tools: commonTools.map((tool) => tool.tool),
    })
  }

  const setTool = (tool: GalleryTools) => {
    if (
      state.selectedTools.find(
        (selected) => selected === tool,
      )
    ) {
      dispatch({
        type: "remove_tool",
        tool,
      })

      return
    }
    dispatch({
      type: "select_tool",
      tool,
    })
  }

  const selectCustom = (custom: boolean) => {
    dispatch({ type: "set_include_custom", state: custom })
  }

  const clearStyle = () => {
    dispatch({ type: "clear_style" })
  }

  const clearAll = () => {
    dispatch({ type: "clear_all" })
  }

  const apply = () => {
    dispatch({
      type: "apply_filters",
      filters: state,
    })
  }
  const clearTools = () => {
    dispatch({ type: "clear_tools" })
  }

  return (
    <DropDown
      closeOnClick
      className="group bg-color-background tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]"
      trigger={
        <div
          className={clsx(
            "flex flex-row items-center justify-center [--icon-color:var(--color-blue-600)]",
            "tablet:h-10 tablet:w-10 tablet:rounded-[6px]",
            "hover:bg-blue-100 desktop:h-11 desktop:w-11 desktop:rounded-[10px]",
          )}>
          <FilterIcon />
        </div>
      }>
      <div className="absolute z-50 -translate-x-[calc(100%-44px)] pt-2 tablet:w-[349px]">
        <div className="flex w-full flex-col gap-[18px] rounded-[13px] bg-color-popup-cell px-4 py-3 [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
          <div className="flex w-full flex-col gap-3">
            <div className="flex w-full flex-row items-center justify-between">
              <p className="text-[14px] font-600 text-blue-800">
                {t("lbl_ai_tools")}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  clearTools()
                }}
                className="text-[12px] font-600 text-blue-500">
                {t("txt_clear")}
              </button>
            </div>
            <div className="flex w-full flex-wrap gap-[10px]">
              {galleryProducts.map((tool, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    setTool(tool.tool)
                  }}
                  className={clsx(
                    " flex flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 [--icon-color:var(--color-blue-600)]",
                    state.selectedTools.some(
                      (selected) => selected === tool.tool,
                    )
                      ? "border-blue-300 bg-blue-300 text-blue-800"
                      : "border-color-separator text-blue-700",
                  )}>
                  {tool.icon}
                  {t(tool.label)}
                </button>
              ))}
            </div>
          </div>

          <div className="h-[1px] w-full border-b border-color-separator" />

          <div className="flex w-full flex-col gap-3">
            <div className="flex w-full flex-row items-center justify-between">
              <p className="text-[14px] font-600 text-blue-800">
                {t("lbl_style")}
              </p>
              <button
                onClick={() => clearStyle()}
                className="text-[12px] font-600 text-blue-500">
                {t("txt_clear")}
              </button>
            </div>
            <div className="no-scrollbar flex max-h-[142px] w-full flex-wrap gap-[10px] overflow-y-auto">
              <div
                onClick={(e) => {
                  e.preventDefault()
                  selectCustom(!state.includeCustoms)
                }}
                className={clsx(
                  " flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
                  state.includeCustoms
                    ? "border-blue-300 bg-blue-300 text-blue-800"
                    : "border-color-separator text-blue-700",
                )}>
                {t("txt_custom")}
              </div>
              {filteredStyles.map((name, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    setStyle(name)
                  }}
                  className={clsx(
                    " flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
                    state.selectedStyles.some(
                      (style) => style.name === name,
                    )
                      ? "border-blue-300 bg-blue-300 text-blue-800"
                      : "border-color-separator text-blue-700",
                  )}>
                  {t(name)}
                </div>
              ))}
            </div>
          </div>

          <div className="flex h-11 w-full flex-row gap-[10px]">
            <button
              onClick={(e) => {
                e.preventDefault()
                clearAll()
              }}
              className="h-full cursor-pointer px-[18px] py-3 text-[16px] font-600 text-blue-500">
              {t("txt_clear_all")}
            </button>
            <Button
              onClick={apply}
              text={t("lbl_apply")}
              className="h-full flex-1"></Button>
          </div>
        </div>
      </div>
    </DropDown>
  )
}

function SortByGallery() {
  const { state, dispatch } = useGalleryViewContext()
  const { sortBy } = state

  const { t } = useTranslation()

  function setSortBy(sortBy: SortBy) {
    dispatch({ type: "set_sort_by", sortBy })
    dispatch({
      type: "apply_filters",
      filters: state,
    })
  }

  const currentLabel = sortLabels[sortBy]

  return (
    <DropDown
      hover
      className="group h-full rounded-[8px] bg-color-background"
      trigger={
        <div
          className={clsx(
            "flex flex-row items-center justify-between px-4",
            "tablet:h-10 tablet:w-[140px] tablet:rounded-[6px]",
            "desktop:h-11 desktop:w-[175px] desktop:rounded-[10px]",
          )}>
          <p className="text-[14px] font-500 text-blue-700">
            {t(currentLabel)}
          </p>
          <div
            className={clsx(
              "flex -rotate-90 items-center justify-center",
              "transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
            )}>
            <ButtonArrowIcon />
          </div>
        </div>
      }>
      <div className="absolute z-10 w-[110px] pt-2 tablet:w-[112px] desktop:w-[175px]">
        <div className="w-full rounded-[10px] bg-color-popup-cell px-[10px] py-[14px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
          <div className="flex w-full flex-col gap-1">
            {sortOptions.map((option) => (
              <div
                key={option}
                onClick={() => setSortBy(option)}
                className={clsx(
                  "flex w-full cursor-pointer items-center rounded-[8px] py-3 pl-4 text-[14px] font-500 text-blue-800 transition-all",
                  sortBy === option
                    ? "bg-blue-100 hover:bg-blue-100"
                    : "hover:bg-color-background",
                )}>
                {t(sortLabels[option])}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DropDown>
  )
}

function MediaTypeGallery() {
  const { dispatch, state } = useGalleryViewContext()

  const { t } = useTranslation()

  const { mediaType } = state
  function setMediaType(mediaType: MediaType) {
    dispatch({ type: "set_media_type", mediaType })
    dispatch({
      type: "apply_filters",
      filters: state,
    })
  }

  return (
    <DropDown
      hover
      className="group h-full rounded-[8px] bg-color-background"
      trigger={
        <div
          className={clsx(
            "flex h-10 w-[94px] flex-row items-center justify-between gap-[6px] px-[12px] desktop:h-11",
          )}>
          <p className="flex-1 text-center text-[15px] font-500 text-blue-600">
            {t(`lbl_${mediaType}`)}
          </p>
          <div
            className={clsx(
              "mr-1 flex -rotate-90 items-center justify-center",
              "transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
            )}>
            <ButtonArrowIcon />
          </div>
        </div>
      }>
      <div className="absolute z-10 w-[94px] pt-3">
        <div
          className={clsx(
            "flex flex-col items-center gap-1 rounded-[12px] bg-color-popup-cell",
            "px-[10px] py-[14px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]",
          )}>
          <div
            onClick={() => setMediaType("all")}
            className={clsx(
              "flex w-full cursor-pointer py-2 pl-4",
              "rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
              mediaType === "all"
                ? "bg-blue-300 hover:bg-blue-300"
                : "hover:bg-blue-100",
            )}>
            {t("lbl_all")}
          </div>
          <div
            onClick={() => setMediaType("image")}
            className={clsx(
              "flex w-full cursor-pointer py-2 pl-4",
              "rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
              mediaType === "image"
                ? "bg-blue-300 hover:bg-blue-300"
                : "hover:bg-blue-100",
            )}>
            {t("lbl_image")}
          </div>
          <div
            onClick={() => setMediaType("video")}
            className={clsx(
              "flex w-full cursor-pointer py-2 pl-4",
              "rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
              mediaType === "video"
                ? "bg-blue-300 hover:bg-blue-300"
                : "hover:bg-blue-100",
            )}>
            {t("lbl_video")}
          </div>
        </div>
      </div>
    </DropDown>
  )
}

interface FilterDialogMobileProps {
  filterDialogOpen: boolean
  setFilterDialogOpen: (open: boolean) => void
}

function FilterDialogMobile(
  props: FilterDialogMobileProps,
) {
  const { filterDialogOpen, setFilterDialogOpen } = props
  const { dispatch, state } = useGalleryViewContext()

  const { t } = useTranslation()

  const { availableFilters } = useGalleryViewContext()
  const { sortBy } = state

  const styles = availableFilters.styles

  const uniqueFilters = styles
    .filter((style) =>
      state.selectedTools.includes(style.tool),
    )
    .map((style) => style.name)

  const filteredStyles = Array.from(new Set(uniqueFilters))

  const uniqueTools = Array.from(
    new Set(styles.map((style) => style.tool)),
  )

  const commonTools = galleryProducts.filter((tool) =>
    uniqueTools.some((product) => product === tool.tool),
  )

  function setSortBy(sortBy: SortBy) {
    dispatch({ type: "set_sort_by", sortBy })
  }

  const setStyle = (name: string) => {
    if (
      state.selectedStyles.find(
        (style) => style.name === name,
      )
    ) {
      dispatch({
        type: "remove_style",
        name: name,
      })

      return
    }
    dispatch({
      type: "select_style",
      name: name,
      tools: commonTools.map((tool) => tool.tool),
    })
  }

  const setTool = (tool: GalleryTools) => {
    if (
      state.selectedTools.find(
        (selected) => selected === tool,
      )
    ) {
      dispatch({
        type: "remove_tool",
        tool,
      })

      return
    }
    dispatch({
      type: "select_tool",
      tool,
    })
  }

  const selectCustom = (custom: boolean) => {
    dispatch({ type: "set_include_custom", state: custom })
  }

  const clearStyle = () => {
    dispatch({ type: "clear_style" })
  }

  const clearTools = () => {
    dispatch({ type: "clear_tools" })
  }

  const clearAll = () => {
    dispatch({ type: "clear_all" })
  }

  const apply = () => {
    dispatch({
      type: "apply_filters",
      filters: state,
    })
  }

  return (
    <>
      <div
        className={clsx(
          "fixed inset-0 flex items-end justify-start bg-color-popup tablet:hidden",
          !filterDialogOpen &&
            "pointer-events-none opacity-0",
          "z-[99] transition-opacity duration-300",
        )}
        id="filter-popup"
        onClick={(event) => {
          if (event.target instanceof Element) {
            if (
              event.target.id === event.currentTarget.id
            ) {
              setFilterDialogOpen(false)
            }
          }
        }}>
        <div
          className={clsx(
            "relative h-[60vh] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
            filterDialogOpen
              ? "translate-y-0"
              : "translate-y-full",
          )}>
          <div className="no-scrollbar flex h-[calc(60vh-74px)] w-full flex-col gap-[18px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-4 py-2">
            {/* Sort By Section */}
            <div className="flex w-full flex-col gap-3">
              <div className="flex w-full flex-row items-center justify-between">
                <p className="text-[14px] font-600 text-blue-800">
                  {t("lbl_sort_by")}
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setSortBy("newest")
                  }}
                  className="text-[12px] font-600 text-blue-500">
                  {t("txt_clear")}
                </button>
              </div>
              <div className="flex w-full flex-wrap gap-[10px]">
                {sortOptions.map((option) => (
                  <button
                    key={option}
                    onClick={(e) => {
                      e.preventDefault()
                      setSortBy(option)
                    }}
                    className={clsx(
                      "flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
                      sortBy === option
                        ? "border-blue-100 bg-blue-100 text-blue-800"
                        : "border-color-separator text-blue-700",
                    )}>
                    <div
                      className={clsx(
                        "flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
                        sortBy === option
                          ? "border-primary-500"
                          : "border-[#9195AB]",
                      )}>
                      <div
                        className={clsx(
                          "h-2 w-2 rounded-full bg-primary-500 transition-opacity",
                          sortBy === option
                            ? "opacity-100"
                            : "opacity-0",
                        )}></div>
                    </div>
                    {t(sortLabels[option])}
                  </button>
                ))}
              </div>
            </div>

            {/* Tools Section */}
            <div className="flex w-full flex-col gap-3">
              <div className="flex w-full flex-row items-center justify-between">
                <p className="text-[14px] font-600 text-blue-800">
                  {t("lbl_ai_tools")}
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    clearTools()
                  }}
                  className="text-[12px] font-600 text-blue-500">
                  {t("txt_clear")}
                </button>
              </div>
              <div className="flex w-full flex-wrap gap-[10px]">
                {galleryProducts.map((tool, index) => (
                  <button
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()
                      setTool(tool.tool)
                    }}
                    className={clsx(
                      " flex flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all",
                      state.selectedTools.some(
                        (selected) =>
                          selected === tool.tool,
                      )
                        ? "border-blue-100 bg-blue-100 text-blue-800 [--icon-color:var(--color-blue-700)]"
                        : "border-color-separator text-blue-700 [--icon-color:var(--color-blue-600)]",
                    )}>
                    {tool.icon}
                    {t(tool.label)}
                  </button>
                ))}
              </div>
            </div>

            {/* Styles Section */}
            <div className="flex w-full flex-col gap-3">
              <div className="flex w-full flex-row items-center justify-between">
                <p className="text-[14px] font-600 text-blue-800">
                  {t("lbl_style")}
                </p>
                <button
                  onClick={() => clearStyle()}
                  className="text-[12px] font-600 text-blue-500">
                  {t("txt_clear")}
                </button>
              </div>
              <div className="no-scrollbar flex max-h-[184px] w-full flex-wrap gap-[10px] overflow-y-auto pb-2">
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    selectCustom(!state.includeCustoms)
                  }}
                  className={clsx(
                    " flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
                    state.includeCustoms
                      ? "border-blue-100 bg-blue-100 text-blue-800"
                      : "border-color-separator text-blue-700",
                  )}>
                  {t("txt_custom")}
                </div>
                {filteredStyles.map((name, index) => (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()
                      setStyle(name)
                    }}
                    className={clsx(
                      " flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
                      state.selectedStyles.some(
                        (style) => style.name === name,
                      )
                        ? "border-blue-100 bg-blue-100 text-blue-800"
                        : "border-color-separator text-blue-700",
                    )}>
                    {t(name)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 left-0 w-full border-t border-color-separator bg-color-cell px-4 py-[14px] dark:bg-[#2C2C34]">
            <div className="flex h-11 w-full flex-row gap-[10px]">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  clearAll()
                }}
                className="h-full px-[18px] py-3 text-[16px] font-600 text-blue-500">
                {t("txt_clear_all")}
              </button>
              <Button
                onClick={() => {
                  apply()
                  setFilterDialogOpen(false)
                }}
                text={t("lbl_apply")}
                className="h-full flex-1"></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface TemplatesSectionProps {
  templates: Template[]
  uid: string
  privateAccount: boolean
  privateLikes: boolean
  creator: TutorialTemplateCreator
  setGalleryPopupState: (
    gallery: GalleryPopupProps | null,
  ) => void
  setFilterDialogOpen: (open: boolean) => void
  activeTab: number
  username: string
}

const TemplatesSection = (props: TemplatesSectionProps) => {
  const {
    templates,
    uid,
    privateLikes,
    privateAccount,
    creator,
    setGalleryPopupState,
    setFilterDialogOpen,
    activeTab,
    username,
  } = props

  const { t } = useTranslation()

  const [active, setActive] = useState(activeTab)

  const { userInfo } = useAuth()
  const isSelf = userInfo.userId === uid
  const likesAvailable =
    isSelf || !(privateAccount || privateLikes)
  const templatesAvailable = isSelf || !privateAccount

  const createdReady = templatesAvailable

  const createdResult: ScrollDataResult = createdReady
    ? {
        data: new CreatedScrollContext(
          uid,
          templates,
          creator,
        ),
        ready: true,
      }
    : { ready: false, data: undefined }

  const likedReady = likesAvailable

  const likedResult: ScrollDataResult = likedReady
    ? {
        data: new LikedScrollContext(uid, [], creator),
        ready: true,
      }
    : { data: undefined, ready: false }

  const { query: created, templates: createdTemplates } =
    useScrollContext(createdResult)

  const { query: liked, templates: likedTemplates } =
    useScrollContext(likedResult)

  const { query: gallery } = useGalleryViewContext()

  useEffect(() => {
    const context =
      active === 0
        ? new CreatedScrollContext(
            uid,
            createdTemplates,
            creator,
          )
        : new LikedScrollContext(
            uid,
            likedTemplates,
            creator,
          )
    setScrollContext(context)
  }, [
    active,
    created,
    createdTemplates,
    creator,
    likedTemplates,
    uid,
  ])

  const tabs = useMemo(
    () =>
      [
        {
          label: "common:lbl_templates",
          query: created,
          available: templatesAvailable,
          type: "template",
        } as const,
        {
          label: "common:lbl_liked",
          query: liked,
          available: likesAvailable,
          type: "template",
        } as const,
        {
          label: "common:txt_ai_arts",
          query: gallery,
          available: templatesAvailable,
          type: "gallery",
        } as const,
      ] as const,
    [
      created,
      templatesAvailable,
      liked,
      likesAvailable,
      gallery,
    ],
  )

  return (
    <TemplatesSectionContainer>
      <TemplatesSectionWrapper>
        {templatesAvailable && (
          <TabsContainer>
            {tabs.map(({ available, label }, index) => (
              <SingleTab
                active={active === index}
                available={available}
                key={index}
                onClick={() => {
                  available && setActive(index)
                  const newUrl = `/${username}?active=${index}`
                  window.history.pushState(
                    { path: newUrl },
                    "",
                    newUrl,
                  )
                }}>
                {!available && <SmallLockIcon />}
                {t(label)}
              </SingleTab>
            ))}
            <Indicator active={active} />
          </TabsContainer>
        )}
        <UserTemplatesContainer>
          {tabs.map((tab, index): JSX.Element => {
            switch (tab.type) {
              case "template":
                return (
                  <SinglePage
                    active={active}
                    index={index}
                    query={tab.query}
                    key={index}
                    available={tab.available}
                    uid={uid}
                  />
                )
              case "gallery":
                return (
                  <GalleryPage
                    active={active}
                    index={index}
                    query={tab.query}
                    key={index}
                    available={tab.available}
                    username={creator.username}
                    setGalleryPopupState={
                      setGalleryPopupState
                    }
                    setFilterDialogOpen={
                      setFilterDialogOpen
                    }
                  />
                )
            }
          })}
        </UserTemplatesContainer>
      </TemplatesSectionWrapper>
    </TemplatesSectionContainer>
  )
}

interface AdvancedProfileProps {
  user: UserProfile.Response
  gallery: UserGallery
  activeTab: number
}

const AdvancedProfile = (props: AdvancedProfileProps) => {
  const {
    name,
    bio,
    picture,
    socials,
    followers,
    following,
    shoots,
    uid,
    username,
  } = props.user

  const { initial, availableFilters, jobs } = props.gallery

  const { userInfo } = useAuth()
  const isSelf = userInfo.userId === uid
  const [galleryPopupState, setGalleryPopupState] =
    useState<GalleryPopupProps | null>(null)
  const [reportPopup, setReportPopup] = useState(false)
  const [filterDialogOpen, setFilterDialogOpen] =
    useState(false)
  const [followStatus, setFollowStatus] =
    useState<FollowStatus>(
      props.user.followStatus ?? "not_following",
    )
  const [payload, setPayload] = useState<UserReportRequest>(
    {
      to_uid: uid,
      info: {
        reason: "",
        message: "",
      },
    },
  )

  const { t } = useTranslation()
  useEffect(() => {
    if (filterDialogOpen || galleryPopupState !== null) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [filterDialogOpen, galleryPopupState])

  const creator = useMemo(
    () => ({
      username,
      followers,
      picture,
      uid,
      followStatus,
    }),
    [username, followers, picture, uid, followStatus],
  )

  return (
    <>
      <TopContainer className="bg-color-background">
        <TopWrapper>
          <PictureAligner>
            <ProfilePicture
              src={
                picture ?? assetUrl("/general/avatar.svg")
              }
			  alt="avatar image"
            />
            <div className="flex w-full items-center justify-between">
              <Socials list={socials} />
              <div
                className={clsx(
                  isSelf && "hidden",
                  "flex items-center gap-[11px] tablet:hidden",
                )}>
                <div
                  className={clsx(
                    "flex w-[96px] items-center justify-center",
                    "h-[32px] overflow-hidden rounded-[10px]",
                  )}>
                  {followStatus !== null && (
                    <FollowButton
                      followStatus={followStatus}
                      uid={uid}
                      setFollowStatus={setFollowStatus}
                      userPage={true}
                    />
                  )}
                </div>
                <div
                  className={clsx(
                    "group relative cursor-pointer",
                    "-mb-[30px] pb-[30px]",
                  )}>
                  <div
                    className={clsx(
                      "h-[32px] w-[32px] [--icon-color:var(--color-blue-900)] hover:bg-blue-100",
                      "flex items-center justify-center rounded-[10px]",
                    )}>
                    {moreIcon()}
                  </div>
                  <button
                    onClick={() => {
                      setReportPopup(true)
                    }}
                    className={clsx(
                      "absolute hidden w-[123px] rounded-[12px] px-[10px]",
                      "bg-color-popup-cell py-[8px] group-hover:flex",
                      "items-center gap-[8px] shadow-xl hover:bg-blue-100",
                      "-right-[70px] top-[50px] -translate-x-1/2",
                    )}>
                    <CustomThemedResource
                      source="/general/report"
                      format="svg"
                    />
                    <span className="text-[16px] font-500 text-blue-800">
                      {t("txt_report")}
                    </span>
                  </button>
                  {reportPopup && setReportPopup && (
                    <ReportPopup
                      className={clsx(
                        "fixed bottom-0 left-0 right-0 z-[100]",
                      )}
                      payload={payload}
                      payloadLocation="user"
                      setPayload={setPayload}
                      endpoint={"user-report"}
                      setReportPopup={setReportPopup}
                      galleryOrTemplate="template"
                    />
                  )}
                </div>
              </div>
            </div>
          </PictureAligner>
          <NamePositionContainer>
            <NameText>{name}</NameText>
            <Leaderboard uid={uid} />
          </NamePositionContainer>
          <Maximize />
          <DesktopOrTablet>
            <Socials list={socials} />
          </DesktopOrTablet>
          <BioText>{bio}</BioText>
          <Mobile>
            <AllStats
              followers={followers}
              following={following}
              shoots={shoots}
            />
          </Mobile>
        </TopWrapper>
      </TopContainer>
      <BottomContainer>
        <Wrapper>
          <DesktopOrTablet>
            <ProfilePictureContainer>
              <ProfilePictureUnbound
                src={
                  picture ?? assetUrl("/general/avatar.svg")
                }
				alt="avatar"
              />
              <BioColumn>
                <BioTextDesktop>{bio}</BioTextDesktop>
                <div className="flex w-full items-center justify-between">
                  <AllStats
                    followers={followers}
                    following={following}
                    shoots={shoots}
                  />
                  <div
                    className={clsx(
                      isSelf && "hidden",
                      "flex items-center gap-[11px]",
                    )}>
                    <div
                      className={clsx(
                        "flex w-[150px] items-center justify-center",
                        "h-[44px] overflow-hidden rounded-[6px]",
                      )}>
                      {followStatus !== null && (
                        <FollowButton
                          followStatus={followStatus}
                          uid={uid}
                          setFollowStatus={setFollowStatus}
                          userPage={true}
                        />
                      )}
                    </div>
                    <div
                      className={clsx(
                        "group relative cursor-pointer",
                        "-mb-[30px] pb-[30px]",
                      )}>
                      <div
                        className={clsx(
                          "h-[44px] w-[44px] [--icon-color:var(--color-blue-900)] hover:bg-blue-100",
                          "flex items-center justify-center rounded-[10px]",
                          "border-2 border-color-separator",
                        )}>
                        {moreIcon()}
                      </div>
                      <button
                        onClick={() => {
                          setReportPopup(true)
                        }}
                        className={clsx(
                          "absolute hidden w-[123px] rounded-[12px] px-[10px]",
                          "bg-color-popup-cell py-[8px] group-hover:flex",
                          "items-center gap-[8px] shadow-xl hover:bg-blue-100",
                          "left-1/2 top-[50px] -translate-x-1/2",
                        )}>
                        <CustomThemedResource
                          source="/general/report"
                          format="svg"
                        />
                        <span className="text-[16px] font-500 text-blue-800">
                          {t("txt_report")}
                        </span>
                      </button>
                      {reportPopup && setReportPopup && (
                        <ReportPopup
                          className={clsx(
                            "absolute left-1/2 top-[50px] z-[100] -translate-x-2/3",
                          )}
                          payloadLocation="user"
                          payload={payload}
                          setPayload={setPayload}
                          endpoint={"user-report"}
                          setReportPopup={setReportPopup}
                          galleryOrTemplate="template"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </BioColumn>
            </ProfilePictureContainer>
          </DesktopOrTablet>
        </Wrapper>
      </BottomContainer>
      <LastViewedProvider>
        <GalleryViewProvider
          availableFilters={availableFilters}
          initialFilters={initial ?? indexFiltersGallery}
          initialData={jobs ?? undefined}
          pageNumber={1}
          page="ai-gallery">
          <TemplatesSection
            {...props.user}
            creator={creator}
            activeTab={props.activeTab}
            setGalleryPopupState={setGalleryPopupState}
            setFilterDialogOpen={setFilterDialogOpen}
          />
          <FilterDialogMobile
            filterDialogOpen={filterDialogOpen}
            setFilterDialogOpen={setFilterDialogOpen}
          />
        </GalleryViewProvider>
      </LastViewedProvider>

      {galleryPopupState !== null && (
        <GalleryPopup {...galleryPopupState} />
      )}
    </>
  )
}

interface FollowButtonProps {
  followStatus: FollowStatus
  uid: string
  setFollowStatus: (v: FollowStatus) => void
  userPage: boolean
  className?: string
}
export function FollowButton(props: FollowButtonProps) {
  const {
    followStatus,
    userPage,
    uid,
    setFollowStatus,
    className,
  } = props

  const { t } = useTranslation()
  const auth = useAuth()

  async function handleFollow(follow: boolean) {
    try {
      if (follow) {
        await axios.post("/api/user-follow", {
          uid_to: uid,
        })
        setFollowStatus("following")
      } else {
        await axios.post("/api/user-unfollow", {
          uid_to: uid,
        })
        setFollowStatus("not_following")
      }
    } catch (error) {
      console.error(error)
    }
  }

  switch (followStatus) {
    case "not_following":
      return (
        <NewButton
          text={t(`lbl_${followStatus}`)}
          variant="secondary"
          className={className}
          onClick={() => {
            if (
              auth.userInfo &&
              auth.userInfo.isAnonymous
            ) {
              redirectToLogin()
              return
            }
            handleFollow(true)
          }}
        />
      )
    case "follow_back":
      return (
        <NewButton
          text={t(`lbl_${followStatus}`)}
          variant="secondary"
          className={className}
          onClick={() => {
            if (
              auth.userInfo &&
              auth.userInfo.isAnonymous
            ) {
              redirectToLogin()
              return
            }
            handleFollow(true)
          }}
        />
      )
    case "following":
      return userPage ? (
        <button
          className={clsx(
            "flex h-[44px] w-full items-center justify-center rounded-[6px] bg-blue-300",
            className,
          )}
          onClick={() => {
            if (
              auth.userInfo &&
              auth.userInfo.isAnonymous
            ) {
              redirectToLogin()
              return
            }
            handleFollow(false)
          }}>
          <span className="text-[16px] font-600 text-blue-800">
            {t(`lbl_${followStatus}`)}
          </span>
        </button>
      ) : (
        <></>
      )
    case "requested":
      return (
        <div
          className={clsx(
            "flex h-[44px] w-full items-center justify-center rounded-[6px] bg-blue-300",
            className,
          )}>
          <span className="text-[16px] font-600 text-blue-800">
            {t(`lbl_${followStatus}`)}
          </span>
        </div>
      )
  }
}

export default AdvancedProfile
