import {
	allIcon,
	deforumIcon,
	photoAiIcon,
	restyleIcon,
	textToImageIcon,
	textToVideoIcon,
} from "@/comps/ai-tools/icons"
import { DropDown } from "@/comps/dropdown"
import { AITool } from "@/comps/editors/deform"
import Footer from "@/comps/footer"
import Header from "@/comps/header"
import { CustomThemedResource } from "@/comps/image"
import Loader from "@/comps/loader"
import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import Meta from "@/comps/meta"
import PrimaryButton from "@/comps/primary-button"
import { MasonrySkeleton } from "@/comps/skeleton"
import { appStoreConstant } from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import useAuth, {
	getUserEntitlements,
} from "@/utils/client-auth"
import { mutateWithNotify } from "@/utils/errors"
import { NotificationContext } from "@/utils/notification"
import { useRegenerate } from "@/utils/regenerate"
import { withNotify } from "@/utils/trigger"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import {
	DeforumHistoryRequest,
	DeforumHistoryResponse,
	HistoryEntity,
} from "pages/api/deforum-history"
import { DeforumPrivacyRequest } from "pages/api/deforum-privacy"
import {
	DeforumStatusResponse,
	DeforumStatusSingle,
} from "pages/api/deforum-status-new"
import {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"
import {
	useInfiniteQuery,
	useQuery,
	useQueryClient,
} from "react-query"
import Masonry, {
	ResponsiveMasonry,
} from "react-responsive-masonry"
import { GeneratedVideo } from "sections/dashboard/generated-video"
import {
	HistoryPopup,
	HistoryPopupProps,
} from "sections/history/history-popup"

export const HISTORY_ITEMS_IN_PAGE = 10
export async function fetchVideos(
	filters: AITool[],
	offset: number,
	limit = HISTORY_ITEMS_IN_PAGE,
) {
	const payload: DeforumHistoryRequest = {
		offset,
		limit,
		filters,
	}

	return await axios
		.post<DeforumHistoryResponse>(
			"/api/deforum-history",
			payload,
		)
		.then((response) => response.data)
}

export async function fetchLast() {
	return await axios
		.get<DeforumStatusResponse>("/api/deforum-status-new")
		.then((response) => response.data)
}

export interface FilterOption {
	icon: ReactNode
	tools: AITool[]
	name: string
}

interface CreateNewTool {
	url: string
	icon: ReactNode
	title: string
	subtitle: string
	color: string
}

const createNewTools: CreateNewTool[] = [
	{
		icon: deforumIcon(),
		title: "txt_deforum_ai",
		subtitle: "txt_deforum_ai_description",
		url: "/tools/ai-video-transformation",
		color: "[--icon-color:var(--color-blue-600)]",
	},
	{
		icon: restyleIcon(),
		title: "txt_restyle_ai",
		subtitle: "txt_restyle_ai_description",
		url: "/tools/ai-animation-generator",
		color: "[--icon-color:var(--color-blue-600)]",
	},
	{
		icon: photoAiIcon(),
		title: "txt_photo_ai",
		subtitle: "txt_photo_ai_description",
		url: "/tools/ai-photo-editor",
		color: "[--icon-color:var(--color-blue-600)]",
	},
	{
		icon: textToImageIcon(),
		title: "txt_text_to_image",
		subtitle: "txt_text_to_image_description",
		url: "/tools/text-to-image",
		color: "[--icon-color:var(--color-blue-600)]",
	},
	{
		icon: textToVideoIcon(),
		title: "txt_text_to_video",
		subtitle: "txt_text_to_video_description",
		url: "/tools/text-to-video",
		color: "[--icon-color:var(--color-blue-600)]",
	},
]

export const filterOptions: FilterOption[] = [
	{
		icon: allIcon(),
		tools: [
			"deform",
			"ai_restyle",
			"ai_photo",
			"text_to_image",
			"text_to_video",
		],
		name: "lbl_all",
	},
	{
		icon: deforumIcon(),
		tools: ["deform"],
		name: "txt_deforum_ai",
	},
	{
		icon: restyleIcon(),
		tools: ["ai_restyle"],
		name: "txt_restyle_ai",
	},
	{
		icon: photoAiIcon(),
		tools: ["ai_photo"],
		name: "txt_photo_ai",
	},
	{
		icon: textToImageIcon(),
		tools: ["text_to_image"],
		name: "txt_text_to_image",
	},
	{
		icon: textToVideoIcon(),
		tools: ["text_to_video"],
		name: "txt_text_to_video",
	},
]

type HistoryState = "loading" | "empty" | "data"

export const socials: SocialsData[] = [
	{
		title: "Facebook",
		icon: assetUrl("/comps/facebook-colorful.svg"),
		url: (link) =>
			`https://www.facebook.com/sharer/sharer.php?u=${link}`,
	},
	{
		title: "Messenger",
		icon: assetUrl("/comps/messenger-colorful.svg"),
		url: (link) =>
			`fb-messenger://share?link=${link}&app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}}`,
		desktop: (link) =>
			`https://www.facebook.com/dialog/send?link=${link}&app_id=${
				process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
			}&redirect_uri=${encodeURIComponent(
				"https://zoomerang.app",
			)}`,
	},
	{
		title: "Whatsapp",
		icon: assetUrl("/comps/whatsapp-colorful.svg"),
		url: (link) => `https://wa.me/?text=${link}`,
	},
	{
		title: "Viber",
		light: assetUrl("/comps/viber-colorful-light.svg"),
		dark: assetUrl("/comps/viber-colorful-dark.svg"),
		url: (link) => `viber://forward?text=${link}`,
	},
	{
		title: "Pinterest",
		icon: assetUrl("/comps/pinterest-colorful.svg"),
		url: (link) =>
			`https://pinterest.com/pin/create/button/?url=${link}`,
	},
	{
		title: "Email",
		icon: assetUrl("/comps/email-colorful.svg"),
		url: (link) => `mailto:?body=${link}`,
	},
]

function getHistoryState(
	_: DeforumStatusResponse | undefined,
	historyQuery: unknown[][] | undefined,
): HistoryState {
	if (historyQuery === undefined) {
		return "loading"
	}

	if (historyQuery.find((page) => page.length)) {
		return "data"
	}

	return "empty"
}

export function getConcurrentJobCount(
	hasConcurrentJobs: boolean,
) {
	if (hasConcurrentJobs) return 3

	return 1
}

export interface DialogProps {
	open: boolean
	isPublic: boolean
	link: string
	id: number | null
}

export const STATUS_UPDATE_MILLIS = 5_000
export const HISTORY_UPDATE_MILLIS = 30_000

export default function ProfileGenerationsPage() {
	const lastRowRef = useRef<HTMLDivElement>(null)

	const { notify } = useContext(NotificationContext)
	const { userInfo } = useAuth()

	const regenerate = useRegenerate()
	const [filter, setFilter] = useState<FilterOption>(
		filterOptions[0],
	)

	const lastQuery = useQuery({
		queryFn: fetchLast,
		queryKey: ["deforum-last", "user", "pro"],
		refetchInterval: STATUS_UPDATE_MILLIS,
	})

	const query = useInfiniteQuery({
		queryFn: async (context) => {
			const offset: number | undefined = context.pageParam
			return await fetchVideos(filter.tools, offset ?? 0)
		},
		getNextPageParam: (lastPage, allPages) => {
			if (lastPage.length < HISTORY_ITEMS_IN_PAGE) {
				return undefined
			}

			return allPages.length * HISTORY_ITEMS_IN_PAGE
		},
		queryKey: [
			"deforum-history",
			"user",
			"pro",
			filter.tools,
			lastQuery.data?.filter((job) => {
				return (
					job.status === "in-queue" ||
					job.status === "in-progress"
				)
			}).length,
		],
		onError: withNotify((t) =>
			notify(<ErrorMessage>{t}</ErrorMessage>),
		),
		refetchInterval: HISTORY_UPDATE_MILLIS,
	})

	useEffect(() => {
		if (lastRowRef.current === null) {
			return
		}

		if (!query.isStale) {
			return
		}

		const observer = new IntersectionObserver(
			(entries) => {
				const intersecting = entries.find(
					(entry) => entry.isIntersecting,
				)
				if (!intersecting) {
					return
				}

				if (!query.isStale) {
					return
				}

				if (!query.hasNextPage) {
					return
				}

				query.fetchNextPage()
			},
			{
				rootMargin: "200px",
			},
		)

		observer.observe(lastRowRef.current)

		return () => {
			observer.disconnect()
		}
	}, [lastRowRef, query])

	const historyState = getHistoryState(
		lastQuery.data,
		query.data?.pages,
	)
	const isFetchingMore =
		query.isFetching && historyState !== "loading"
	const currentRunningJobs =
		lastQuery.data?.filter((job) => {
			return (
				job.status === "in-queue" ||
				job.status === "in-progress"
			)
		}).length ?? -1

	const { hasConcurrentJobs } = getUserEntitlements(
		userInfo.entitlements,
	)
	const canTryAgain =
		currentRunningJobs <
		getConcurrentJobCount(hasConcurrentJobs)

	const { t } = useTranslation("common")

	const [dialogOpen, setDialogOpen] = useState<DialogProps>(
		{ open: false, isPublic: false, link: "", id: null },
	)

	const [historyPopupState, setHistoryPopupState] =
		useState<HistoryPopupProps | null>(null)

	useEffect(() => {
		if (historyPopupState !== null) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [historyPopupState])

	const [isPublicLoading, setIsPublicLoading] =
		useState(false)

	useEffect(() => {
		if (dialogOpen.open) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [dialogOpen])

	async function handleDelete(id: number) {
		await mutateWithNotify(
			notify,
			() =>
				axios
					.post("/api/deforum-delete", { id })
					.then((res) => res.data),
			t("txt_successfully_deleted"),
			t("txt_error_deleting"),
		)

		query.refetch()
	}

	const handleMakePublic = useCallback(
		async function () {
			if (isPublicLoading) {
				return
			}

			setIsPublicLoading(true)

			try {
				const payload: DeforumPrivacyRequest = {
					privacy: "public",
					job: dialogOpen.id ?? 0,
				}

				await axios.post("/api/deforum-privacy", payload)
				query.refetch()
			} catch (error) {
				console.error(error)
				notify(
					<ErrorMessage>
						{t("txt_could_not_make_public")}
					</ErrorMessage>,
				)
			}

			setIsPublicLoading(false)
		},
		[dialogOpen.id, isPublicLoading, notify, query, t],
	)

	async function handleShare() {
		try {
			if (!dialogOpen.isPublic) {
				await handleMakePublic()
			}

			await navigator.clipboard.writeText(dialogOpen.link)
			setDialogOpen({
				open: false,
				isPublic: false,
				link: dialogOpen.link,
				id: null,
			})
			notify(
				<SuccessMessage>
					{t("common:txt_successfully_copied")}
				</SuccessMessage>,
			)
		} catch (e) {
			setDialogOpen({
				open: false,
				isPublic: false,
				link: dialogOpen.link,
				id: null,
			})
			console.error(e)
			notify(
				<ErrorMessage>
					{t("common:txt_couldnt_copy")}
				</ErrorMessage>,
			)
		}
	}

	async function handleSocialShare(
		desktop?: boolean,
		desktopFunction?: (link: string) => string,
		url?: (link: string) => string,
	): Promise<string> {
		try {
			if (!dialogOpen.isPublic) {
				await handleMakePublic()
			}

			setDialogOpen({
				open: false,
				isPublic: false,
				link: dialogOpen.link,
				id: null,
			})

			if (desktop && desktopFunction)
				return desktopFunction(dialogOpen.link)
			else if (url) return url(dialogOpen.link)
			else throw Error("No function")
		} catch (e) {
			setDialogOpen({
				open: false,
				isPublic: false,
				link: dialogOpen.link,
				id: null,
			})
			console.error(e)
			notify(
				<ErrorMessage>
					{t("common:txt_couldnt_copy")}
				</ErrorMessage>,
			)
			return ""
		}
	}

	return (
		<>
			<Meta
				title={t("txt_generations_title")}
				description={t("txt_generations_description")}
			/>
			<Header />
			<h4
				className={clsx(
					"text-h4 mt-[22px] tablet:mt-[24px]",
					"text-center desktop:mt-[56px]",
					"mb-[28px] tablet:mb-[16px] desktop:mb-[28px]",
				)}>
				{t("txt_history")}
			</h4>
			<div className="mx-auto flex w-full select-none flex-col gap-4 py-4 tablet:w-[768px] desktop:w-[1175px] desktop:gap-6">
				{lastQuery.data &&
					lastQuery.data.filter((job) => {
						return (
							job.status === "in-queue" ||
							job.status === "in-progress"
						)
					}).length > 0 && (
						<div className="relative h-[70px] w-full">
							<div className="no-scrollbar absolute top-0 flex h-[140px] w-full flex-row gap-3 overflow-x-scroll px-4">
								{lastQuery.data.map((job) => (
									<LastLoading key={job.id} video={job} />
								))}
							</div>
						</div>
					)}

				<div className="flex h-12 w-full flex-row items-center justify-between px-4">
					<div className="flex h-full items-center justify-center">
						<DropDown
							closeOnClick
							hover
							className="group h-full w-[215px]"
							contentClassName="py-2"
							trigger={
								<div
									className={clsx(
										"flex h-[48px] w-[215px] gap-3 bg-color-cell px-4 py-2 text-blue-800",
										"[--icon-color:var(--color-blue-600)] hover:[--icon-color:var(--color-blue-800)]",
										"items-center rounded-[8px] text-[14px] font-500",
									)}>
									{filter.icon}
									{t(filter.name)}
									<div className="flex-1" />
									<CustomThemedResource
										format="svg"
										source="/general/arrow"
										className="h-5 w-5 rotate-90 transition-all group-hover:-rotate-90"
									/>
								</div>
							}>
							<div className="absolute left-0 top-full z-50 w-full pt-3">
								<div className="flex w-full flex-col gap-1 rounded-[12px] bg-color-popup-cell p-2 [box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]">
									{filterOptions.map((option) => (
										<button
											key={option.tools.join(",")}
											onClick={() => setFilter(option)}
											className={clsx(
												"flex items-center gap-3 rounded-[8px] px-4 py-3",
												"transition-colors [--icon-color:var(--color-blue-600)]",
												"hover:bg-[rgba(111,131,187,0.1)] hover:[--icon-color:var(--color-blue-900)]",
											)}>
											{option.icon}
											{t(option.name)}
										</button>
									))}
								</div>
							</div>
						</DropDown>
					</div>

					<PrimaryButton
						className="hidden h-[48px] justify-center gap-2 tablet:flex desktop:hidden"
						href={appStoreConstant}>
						<img
							src={assetUrl("/general/plus-white.svg")}
							className="self-center"
							alt="plus white"
						/>
						{t("txt_create_new")}
					</PrimaryButton>
					<div className="hidden desktop:flex">
						<DropDown
							hover
							className={clsx(
								!canTryAgain &&
									"pointer-events-none cursor-not-allowed opacity-50",
							)}
							contentClassName="py-[10px] absolute w-[450px] right-0"
							trigger={
								<div
									className={clsx(
										"flex gap-1 bg-primary-500 py-3 font-600 text-color-white",
										"w-[215px] justify-center rounded-[10px] hover:bg-primary-600",
									)}>
									<img
										src={assetUrl(
											"/general/plus-white.svg",
										)}
										alt="plus white"
									/>
									{t("txt_create_new")}
								</div>
							}>
							<>
								<div className="absolute right-[100px] top-2 z-[-1] h-6 w-6 -translate-x-1/2 rotate-45 rounded-[8px] bg-color-cell" />
								<div className="flex flex-col gap-2 rounded-[22px] bg-color-popup-cell p-[10px] [box-shadow:0px_0px_30px_rgba(0,0,0,0.13)]">
									{createNewTools.map((tools) => (
										<a
											key={tools.url}
											href={tools.url}
											className={clsx(
												"group flex gap-2 rounded-[16px] bg-color-background p-4",
												"items-center transition-colors hover:bg-[#eff1fb] dark:hover:bg-[#212127]",
												!canTryAgain &&
													"pointer-events-none cursor-not-allowed opacity-50",
											)}>
											<div
												className={clsx(
													"flex h-[44px] w-[44px] scale-125 items-center justify-center rounded-[6px]",
													tools.color,
												)}>
												{tools.icon}
											</div>

											<div className="mr-[10px] h-[45px] w-[1px] bg-color-separator" />

											<div className="flex flex-1 flex-col overflow-hidden">
												<span className="text-[18px] font-600 text-blue-800">
													{t(tools.title)}
												</span>
												<span className="truncate text-blue-700">
													{t(tools.subtitle)}
												</span>
											</div>
											<CustomThemedResource
												format="svg"
												source="/general/arrow"
												className="h-5 w-5 translate-x-0 transition-transform group-hover:translate-x-1"
											/>
										</a>
									))}
								</div>
							</>
						</DropDown>
					</div>
				</div>
			</div>

			{/* Share Dialog */}
			<>
				<div
					className={clsx(
						"fixed inset-0 flex items-end justify-start bg-color-popup tablet:items-center tablet:justify-center",
						!dialogOpen.open &&
							"pointer-events-none opacity-0",
						"z-[99] transition-opacity duration-300",
					)}
					id="popup-content"
					onClick={(event) => {
						if (event.target instanceof Element) {
							if (
								event.target.id === event.currentTarget.id
							) {
								setDialogOpen({
									open: false,
									isPublic: false,
									link: dialogOpen.link,
									id: null,
								})
							}
						}
					}}>
					<div
						className={clsx(
							"relative w-full rounded-t-2xl bg-color-cell pb-8 pt-4 transition-all tablet:w-auto tablet:rounded-2xl tablet:py-4",
							dialogOpen.open
								? "translate-y-0"
								: "translate-y-full tablet:translate-y-0",
						)}>
						<button
							onClick={() =>
								setDialogOpen({
									open: false,
									isPublic: false,
									link: dialogOpen.link,
									id: null,
								})
							}
							className={clsx(
								"absolute -right-10 top-0 hidden h-8 w-8 items-center justify-center rounded-full desktop:flex",
								"bg-color-cell transition-colors hover:bg-blue-100",
							)}>
							<img
								src={assetUrl("/general/close.svg")}
								alt="close icon"
							/>
						</button>
						<div className="flex flex-col gap-6">
							<div className="flex flex-col gap-1 px-4">
								<p className="text-[18px] font-500 text-blue-900 tablet:text-[24px]">
									{t("txt_share")}
								</p>
							</div>
							<div className="flex flex-col gap-[22px]">
								<div
									className={clsx(
										"no-scrollbar flex flex-row gap-5 overflow-x-scroll px-4",
										!dialogOpen.open && "hidden",
									)}>
									{socials.map((props) => (
										<SocialButton
											{...props}
											handleSocialShare={handleSocialShare}
											dialogContent={dialogOpen}
											key={props.icon ?? props.light}
										/>
									))}
								</div>
								<div className="flex w-full flex-col items-center gap-3 px-4 tablet:flex-row tablet:justify-end">
									<p className="text-[16px] font-400 text-color-placeholder">
										{t("txt_will_be_available_public")}
									</p>
									<button
										disabled={isPublicLoading}
										onClick={handleShare}
										className=" bg-primary-500/10 hover:bg-primary-600/30 disabled:hover:bg-primary-600/10 flex h-11 w-full shrink-0 flex-row items-center justify-center gap-1 rounded-[10px] text-[16px] font-600 text-primary-500 transition-colors disabled:cursor-default tablet:w-[251px]">
										<img
											src={assetUrl(
												"/ai-tools/history/copy-primary.svg",
											)}
											alt="copy icon"
										/>
										{t("txt_copy")}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

			{historyPopupState !== null && (
				<HistoryPopup {...historyPopupState} />
			)}

			{historyState === "data" && (
				<ResponsiveMasonry
					className="mx-auto w-full gap-3 p-4 tablet:w-[768px] tablet:gap-4 tablet:p-4 desktop:w-[1175px] desktop:gap-[25px]"
					columnsCountBreakPoints={{
						1: 2,
						760: 4,
						1280: 4,
					}}>
					<Masonry gutter="20px">
						{query.data?.pages.map((page) =>
							page
								.filter(
									(video) => video.status !== "canceled",
								)
								.map((video: HistoryEntity) => (
									<GeneratedVideo
										historyPopupState={historyPopupState}
										setHistoryPopupState={
											setHistoryPopupState
										}
										canTryOther={canTryAgain}
										key={video.id}
										entity={video}
										setDialogContent={setDialogOpen}
										handleDelete={handleDelete}
										{...regenerate}
									/>
								)),
						)}
					</Masonry>
				</ResponsiveMasonry>
			)}

			<div ref={lastRowRef} />

			<NoGenerations
				noGenerations={historyState === "empty"}
			/>

			{historyState === "loading" && <HistorySkeleton />}

			<div className="flex w-full justify-center p-8">
				{isFetchingMore && <Loader />}
			</div>
			<Footer />
		</>
	)
}

function HistorySkeleton() {
	const skeletonList = [
		369, 200, 136, 285, 369, 369, 369, 369,
	]

	return (
		<>
			<ResponsiveMasonry
				className="mx-auto w-full gap-3 p-4 tablet:w-[768px] tablet:gap-4 tablet:p-4 desktop:w-[1175px] desktop:gap-[25px]"
				columnsCountBreakPoints={{
					1: 2,
					760: 4,
					1280: 4,
				}}>
				<Masonry gutter="20px">
					{skeletonList.map((height, index) => (
						<div
							key={index}
							style={{ height: height, opacity: 20 }}>
							<MasonrySkeleton className="h-full rounded-xl" />
						</div>
					))}
				</Masonry>
			</ResponsiveMasonry>
		</>
	)
}

interface NoGenerationsProps {
	noGenerations: boolean
}

function NoGenerations(props: NoGenerationsProps) {
	const { noGenerations } = props
	const { t } = useTranslation()

	if (!noGenerations) {
		return <></>
	}

	return (
		<div className="flex w-full items-center justify-center gap-[25px]">
			<div className="hidden aspect-[9/16] w-[269px] rounded-[12px] bg-color-cell opacity-40 desktop:block" />
			<div className="-ml-10 aspect-[9/16] w-[180px] rounded-[12px] bg-color-cell opacity-60 tablet:mx-0 tablet:w-[287px]" />

			<div
				className={clsx(
					"aspect-[9/16] w-[197px] rounded-[14px] bg-color-cell tablet:w-[332px]",
					"flex flex-col items-center gap-[42px] font-500 text-blue-700",
					"relative justify-center",
				)}>
				<CustomThemedResource
					source="/deforum/no-generations"
					className="aspect-square w-[98px]"
				/>
				<div className="flex flex-col items-center gap-1 text-[10px] tablet:text-[20px]">
					<span>{t("txt_no_videos")}</span>
					<span>{t("txt_create_ai_video")}</span>
				</div>
			</div>

			<div className="-mr-10 aspect-[9/16] w-[180px] rounded-[12px] bg-color-cell opacity-60 tablet:mx-0 tablet:w-[287px]" />
			<div className="hidden aspect-[9/16] w-[269px] rounded-[12px] bg-color-cell opacity-40 desktop:block" />
		</div>
	)
}

interface LastVideoProps {
	video: Exclude<DeforumStatusSingle, null>
}

function LastLoading(props: LastVideoProps) {
	const {
		status,
		thumbnail,
		estimatedSeconds,
		id,
		isVideo,
		tool,
	} = props.video

	const [loading, setLoading] = useState(false)

	const ceil = Math.ceil(estimatedSeconds / 60)
	const minutes = ceil < 1 ? 1 : ceil

	const { t } = useTranslation("common")
	const { notify } = useContext(NotificationContext)

	const queryClient = useQueryClient()

	if (status === "ready" || status === "canceled") {
		return <></>
	}

	async function handleCancel() {
		setLoading(true)

		await mutateWithNotify(
			notify,
			() =>
				axios
					.post("/api/deforum-cancel", { id })
					.then((res) => res.data),
			t("txt_successfully_canceled"),
			t("txt_error_canceling"),
		)

		await queryClient.invalidateQueries("deforum-status")
		setLoading(false)
	}

	return (
		<div
			className={clsx(
				"relative flex flex-row gap-2",
				"h-[70px]",
				"bg-color-cell",
				"shrink-0 rounded-[8px] p-[6px]",
				" text-blue-800",
				status === "in-progress" &&
					"border-2 border-[#007BFF]",
				status === "in-queue" &&
					"border-2 border-[#FF8C22]",
				status === "in-queue" ? " w-[228px]" : " w-[208px]",
			)}>
			<div
				className={clsx(
					"absolute right-2 top-3",
					status !== "in-queue" && "hidden",
				)}>
				<DropDown
					className="!z-[67]"
					trigger={
						<img
							src={assetUrl(
								"/deforum/dots-with-background.webp",
							)}
							className="h-6 w-6"
							alt="dots icon"
						/>
					}>
					<div className="absolute right-0 top-full flex w-[214px] flex-col items-stretch gap-1 rounded-[12px] bg-color-popup-cell px-3 py-4 [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
						<button
							onClick={handleCancel}
							disabled={loading}
							className={clsx(
								"text-color-error",
								"hover:bg-[rgba(111,131,187,0.1)]",
								"flex gap-3 rounded-[8px] bg-color-cell",
								"items-center px-3 py-2 font-500",
								"disabled:pointer-events-none",
							)}>
							<CustomThemedResource
								source="/deforum/delete-red"
								format="svg"
							/>
							{t("lbl_cancel")}
						</button>
					</div>
				</DropDown>
			</div>
			{isVideo && thumbnail ? (
				<video
					src={thumbnail}
					loop
					playsInline
					muted
					autoPlay
					className={clsx(
						"aspect-1 h-full object-cover",
						"rounded-[5px]",
					)}
				/>
			) : thumbnail ? (
				<img
					src={thumbnail}
					alt="thumbnail"
					className={clsx(
						"aspect-1 h-full object-cover",
						"rounded-[5px]",
					)}
				/>
			) : (
				<div className="flex aspect-1 h-full items-center justify-center [--icon-color:var(--color-blue-500)]">
					{tool === "text_to_image" && textToImageIcon()}
					{tool === "text_to_video" && textToVideoIcon()}
					{tool === "ai_suggest" && (
						<img
							src={assetUrl("/general/model-icon.svg")}
							alt="model icon"
						/>
					)}
				</div>
			)}

			<div
				className={clsx(
					"flex h-full flex-col justify-center gap-1",
				)}>
				<p className=" text-[14px] text-blue-800">
					{t("txt_status")}
					<strong>
						{status === "in-progress"
							? t("txt_in_progress")
							: t("txt_in_queue")}
					</strong>
				</p>
				<p className=" text-[14px] text-blue-800">
					{t("txt_remaining")}
					<strong>
						{t("fs_minutes_short", { count: minutes })}
					</strong>
				</p>
			</div>
		</div>
	)
}

type SocialButtonIconProps =
	| {
			dark?: undefined
			light?: undefined
			icon: string
	  }
	| {
			dark: string
			light: string
			icon?: undefined
	  }

type SocialButtonProps = SocialsData & {
	dialogContent: DialogProps
	title: string
	tooltipTitle?: string
	setAsShared?: () => void
	handleSocialShare: (
		desktop?: boolean,
		desktopFunction?: (link: string) => string,
		url?: (link: string) => string,
	) => Promise<string>
}

type SocialsData = SocialButtonIconProps &
	UrlOrOnClick & {
		desktop?: (link: string) => string
		title: string
	}

type UrlOrOnClick =
	| {
			url: (link: string) => string
			onClick?: undefined
	  }
	| {
			onClick: (link: string) => unknown
			url?: undefined
	  }

export function SocialButton(props: SocialButtonProps) {
	const {
		url,
		dialogContent,
		desktop,
		title,
		handleSocialShare,
		tooltipTitle,
		setAsShared,
	} = props

	const imageClasses =
		"bg-color-surface transition-colors hover:bg-blue-200 p-3 w-[52px] h-[52px]"

	const anchorClasses =
		"flex rounded-full overflow-hidden shrink-0 w-[52px] h-[52px]"

	return (
		<>
			<div className="group relative flex w-[70px] shrink-0 flex-col items-center gap-2">
				<button
					onClick={async () => {
						const anchor = document.createElement("a")
						anchor.target = "_blank"
						anchor.rel = "noreferrer"

						setAsShared && setAsShared()

						const desktopUrl = await handleSocialShare(
							true,
							desktop,
							url,
						)
						anchor.href = desktopUrl

						anchor.click()
					}}
					className={clsx(
						"hidden desktop:block",
						!dialogContent.open
							? "pointer-events-none"
							: "pointer-events-auto cursor-pointer",
						anchorClasses,
					)}>
					{props.icon ? (
						<img
							src={props.icon}
							className={imageClasses}
							alt="social icon"
						/>
					) : (
						<>
							<img
								src={props.dark}
								data-hide-on-theme="light"
								className={imageClasses}
								alt="social icon"
							/>
							<img
								src={props.light}
								data-hide-on-theme="dark"
								className={imageClasses}
								alt="social icon"
							/>
						</>
					)}
				</button>
				<button
					onClick={async () => {
						const anchor = document.createElement("a")
						anchor.target = "_blank"
						anchor.rel = "noreferrer"

						setAsShared && setAsShared()

						const mobileUrl = await handleSocialShare(
							false,
							undefined,
							url,
						)
						anchor.href = mobileUrl

						anchor.click()
					}}
					className={clsx(
						"desktop:hidden",
						anchorClasses,
						!dialogContent.open
							? "pointer-events-none"
							: "pointer-events-auto cursor-pointer",
					)}>
					{props.icon ? (
						<img
							src={props.icon}
							className={imageClasses}
							alt="social icon"
						/>
					) : (
						<>
							<img
								src={props.dark}
								data-hide-on-theme="light"
								className={imageClasses}
								alt="social icon"
							/>
							<img
								src={props.light}
								data-hide-on-theme="dark"
								className={imageClasses}
								alt="social icon"
							/>
						</>
					)}
				</button>
				<p className="text-[14px] font-600 text-blue-600">
					{title}
				</p>
				<div
					className={clsx(
						"opacity-0 hover:opacity-0 group-hover:opacity-100",
						"pointer-events-none absolute -bottom-[22px]",
						"rounded-[4px] px-3 py-1",
						"bg-color-tooltip transition-all",
						"text-xs font-500 text-blue-100",
					)}>
					{tooltipTitle}
				</div>
			</div>
		</>
	)
}
