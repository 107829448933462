import { assetUrl } from "@/utils/cdn"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { withNotify } from "@/utils/trigger"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
  AllParams,
  PromptOption,
  PromptParams,
} from "pages/api/deforum-params"
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useQuery } from "react-query"
import {
  ChannelsParams,
  DynamicEditorProps,
  useEditorParamsContext,
} from "sections/editor/editor"
import { z } from "zod"
import {
  createPromptStateFrom,
  RestyleConfigurator,
} from "../ai-config"
import { musicGeneratorIcon } from "../header/icon"
import { CustomThemedResource } from "../image"
import { ErrorMessage } from "../message"
import { SubscriptionPopup } from "../subscription-popup"

const DEFORUM_STORAGE_KEY = "last"
export const AiMusicGeneratorStorageSchema = z.object({
  tool: z
    .literal("ai_music_generator")
    .default("ai_music_generator"),
  id: z.literal(DEFORUM_STORAGE_KEY),
  pat: z.string(),
})

type AiMusicGeneratorStorageType = z.infer<
  typeof AiMusicGeneratorStorageSchema
>

const mubertResponseSchema = z.object({
  data: z.object({
    tasks: z.array(
      z.object({
        task_id: z.string(),
        task_status_code: z.union([
          z.literal(1),
          z.literal(2),
        ]),
        download_link: z.string(),
      }),
    ),
  }),
})

function getPromptParamsOfChannels(): PromptParams[] {
  return [
    {
      id: "duration",
      type: "slider",
      kind: "normal",
      hint: "Track duration in seconds",
      defaultValue: 60,
      maxFreeValue: 300,
      name: "Duration",
      values: [10, 60, 120, 180, 240],
      suffix: "s",
      invisible: false,
    },
    {
      id: "intensity",
      type: "slider",
      kind: "step",
      hint: "The complexity of the arrangement, fewer or more instruments are playing simultaneously",
      defaultValue: 50,
      maxFreeValue: 100,
      name: "Intensity",
      values: [10, 50, 100],
      suffix: "%",
      invisible: false,
    },
  ]
}

const intensityMap: { [key: number]: string } = {
  10: "low",
  50: "medium",
  100: "high",
} as const

function fetchStatus(pat: string, taskId: string | null) {
  return async function fetcher() {
    try {
      const data = {
        method: "TrackStatus",
        params: {
          pat,
        },
      }
      const response = await axios
        .post<unknown>(
          "https://api-b2b.mubert.com/v2/TrackStatus",
          data,
        )
        .then((res) => res.data)

      const parsed = mubertResponseSchema.parse(response)

      const status_code = parsed.data.tasks.find(
        (task) => task.task_id === taskId,
      )?.task_status_code

      const canPlay = status_code === 2 ? true : false

      return canPlay
    } catch {
      return false
    }
  }
}

export default function AiMusicGeneratorEditor(props: {
  content: DynamicEditorProps
  params: {
    styles: PromptOption[]
    params: AllParams
  } | null
  channels: ChannelsParams | null
  blob: Blob | null
}) {
  if (props.content.tool !== "ai_music_generator") {
    throw new Error(
      "You are not in ai music generator editor.",
    )
  }

  const { channels, content } = props

  if (channels === null) {
    throw new Error("Parameters for filters are null.")
  }

  const { pat } = content

  const editor = useEditorParamsContext()

  const { t } = useTranslation()

  const { notify } = useContext(NotificationContext)
  const [categoryId, setCategoryId] = useState(
    channels.categories[0].category_id,
  )
  const [taskId, setTaskId] = useState<string | null>(null)
  const [music, setMusic] = useState<string | null>(null)
  const [title, setTitle] = useState("")
  const [name, setName] = useState("")
  const [playlist, setPlaylist] = useState<string | null>(
    null,
  )
  const [image, setImage] = useState(
    assetUrl(
      `/ai-tools/ai-music-generator/audio-visuals/default.webp`,
    ),
  )
  const [generatingMusic, setGeneratingMusic] = useState<{
    [key: string]: boolean
  }>({})
  const { userInfo } = useAuth()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )

  const router = useRouter()

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const [actionLoading, setActionLoading] = useState(false)

  const params = getPromptParamsOfChannels()

  const config = createPromptStateFrom(params)

  const [promptState, setPromptState] = useState(config)

  const [fetchEnabled, setFetchEnabled] = useState(true)

  useQuery({
    queryKey: [
      "music-status",
      `task-${taskId}`,
      `playlist-${playlist}`,
    ],
    queryFn: fetchStatus(pat, taskId),
    enabled:
      fetchEnabled && taskId !== null && playlist !== null,
    onSuccess: (res) => {
      setGeneratingMusic((prev) => ({
        ...prev,
        [playlist ?? "null"]: !res,
      }))

      if (res) {
        setFetchEnabled(false)
      }
    },
    refetchInterval: 2000,
  })

  const indexed = useIndexedDB(
    "ai_art",
    "ai_music_generator",
    AiMusicGeneratorStorageSchema,
  )

  async function addToStorage() {
    if (indexed.status !== "ready") {
      console.error("Indexed is not ready")
      return
    }

    const payload: AiMusicGeneratorStorageType = {
      tool: "ai_music_generator",
      id: DEFORUM_STORAGE_KEY,
      pat,
    }

    await indexed.setData(payload)
  }

  async function submitData(playlist: string) {
    if (actionLoading) {
      return
    }

    try {
      setActionLoading(true)
      setGeneratingMusic((prev) => ({
        ...prev,
        [playlist]: true,
      }))

      const submitData = {
        method: "RecordTrack",
        params: {
          pat,
          playlist,
          duration: promptState
            ? promptState["duration"]
            : "60",
          format: "mp3",
          intensity: promptState
            ? intensityMap[Number(promptState["intensity"])]
            : "medium",
          bitrate: "320",
        },
      }

      const response = await axios
        .post<unknown>(
          "https://api-b2b.mubert.com/v2/RecordTrack",
          submitData,
        )
        .then((res) => res.data)

      const result = mubertResponseSchema.parse(response)

      setTaskId(result.data.tasks[0].task_id)
      setMusic(result.data.tasks[0].download_link)
      setFetchEnabled(true)

      setActionLoading(false)
      setPlaylist(playlist)
      window.removeEventListener(
        "beforeunload",
        handleLeave,
      )
    } catch (error) {
      const notifier = withNotify((t) =>
        notify(<ErrorMessage>{t}</ErrorMessage>),
      )

      notifier(error)

      console.error(error)

      setSubscriptionPopupOpen(false)
      setActionLoading(false)
    }
  }

  const canPlay =
    music !== null &&
    music.trim().length !== 0 &&
    !Object.values(generatingMusic).some(
      (value) => value === true,
    )

  return (
    <div className="flex w-full justify-center overflow-y-scroll">
      <div className="relative mx-auto mb-[70px] mt-4 flex w-full flex-col gap-4 tablet:mb-[100px] tablet:mt-4 tablet:w-[727px] tablet:gap-8 desktop:my-[100px] desktop:w-[975px]">
        <button
          onClick={async () => {
            window.removeEventListener(
              "beforeunload",
              handleLeave,
            )

            if (
              router.asPath === "/editor/ai-music-generator"
            ) {
              router.back()
            }
            editor.closePage()
          }}
          className="group absolute left-[0] hidden flex-row items-center gap-3 desktop:top-[-70px] desktop:flex">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-color-cell inner-border inner-border-[transparent] group-hover:inner-border-color-separator">
            <CustomThemedResource
              format="svg"
              source="/general/arrow"
              className="rotate-180"
            />
          </div>
          <p className=" text-lg font-500 text-blue-700 group-hover:text-blue-900">
            {t("lbl_back")}
          </p>
        </button>

        <div className="flex w-full flex-row items-center justify-between pr-4 tablet:p-0">
          <div className="no-scrollbar flex flex-1 flex-row overflow-x-scroll pl-4 tablet:overflow-auto tablet:p-0 desktop:gap-1">
            {channels.categories.map((cat) => (
              <button
                key={cat.category_id}
                onClick={() =>
                  setCategoryId(cat.category_id)
                }
                className={clsx(
                  "h-9 rounded-[9px] border px-[18px] text-[16px] font-500 transition-all desktop:h-11 desktop:rounded-[14px] desktop:border-[2px]",
                  categoryId === cat.category_id
                    ? " border-primary-500 bg-color-cell text-primary-500"
                    : " border-[transparent] text-blue-700",
                )}>
                {cat.name}
              </button>
            ))}
          </div>

          {promptState && config && (
            <ParamtersTriggerSmall
              params={params}
              actionLoading={actionLoading}
              promptState={promptState}
              setPromptState={setPromptState}
              showFiltered={
                JSON.stringify(promptState) !==
                JSON.stringify(config)
              }
              className="!py-[18px]"
            />
          )}
        </div>

        <div className="grid w-full grid-cols-1 px-4 tablet:px-0">
          {channels.categories.map((cat) => (
            <div
              key={cat.category_id}
              style={{
                gridColumnStart: 1,
                gridRowStart: 1,
              }}
              className={clsx(
                "flex w-full flex-col gap-4 transition-all tablet:grid tablet:grid-cols-3",
                categoryId !== cat.category_id
                  ? "pointer-events-none h-[400px] translate-y-12 overflow-hidden opacity-0 delay-0 tablet:h-[200px]"
                  : "translate-y-0 opacity-100 delay-200",
              )}>
              {cat.groups.map((group) => (
                <div
                  key={group.playlist}
                  className="relative flex h-[70px] w-full shrink-0 overflow-hidden rounded-[12px] tablet:h-[196px] tablet:rounded-[10px] desktop:h-[193px] desktop:rounded-[14px]">
                  <img
                    src={assetUrl(
                      `/ai-tools/ai-music-generator/audio-visuals/${group.name
                        .toLowerCase()
                        .replace(/\s/g, "")}.webp`,
                    )}
                    alt={group.name}
                    onErrorCapture={(e) =>
                      (e.currentTarget.src = assetUrl(
                        `/ai-tools/ai-music-generator/audio-visuals/default.webp`,
                      ))
                    }
                    className="h-full w-full object-cover"
                  />

                  <div className="pointer-events-none absolute left-0 top-0 z-0 h-full w-[130px] bg-gradient-to-r from-color-black/50 via-color-black/20 to-[transparent] tablet:hidden"></div>

                  <div className="absolute bottom-0 left-0 z-[1] flex h-full w-full flex-row items-center justify-between pl-5 pr-4 tablet:h-[66px] tablet:bg-[#00000020] tablet:px-[14px] tablet:py-[14px] tablet:backdrop-blur-[22px] desktop:px-[10px]">
                    <p className="text-[19px] font-500 text-color-white tablet:text-[14px] tablet:font-600 tablet:text-[#ECEDEF] desktop:text-[16px]">
                      {group.name}
                    </p>
                    <button
                      disabled={Object.values(
                        generatingMusic,
                      ).some((value) => value === true)}
                      onClick={() => {
                        if (isPro) {
                          setImage(
                            assetUrl(
                              `/ai-tools/ai-music-generator/audio-visuals/${group.name
                                .toLowerCase()
                                .replace(/\s/g, "")}.webp`,
                            ),
                          )
                          setTitle(cat.name)
                          setName(group.name)
                          submitData(group.playlist)
                        } else {
                          setSubscriptionPopupOpen(true)
                        }
                      }}
                      className="flex h-[38px] flex-row items-center gap-[6px] rounded-[8px] bg-[#00000038] px-5 text-[16px] font-500 text-color-white backdrop-blur-[16px] [--icon-color:var(--color-white)] disabled:cursor-not-allowed tablet:px-3 tablet:text-[14px] tablet:backdrop-blur-0 desktop:text-[16px]">
                      {generatingMusic[group.playlist] ===
                      undefined ? (
                        musicGeneratorIcon()
                      ) : generatingMusic[
                          group.playlist
                        ] ? (
                        <img
                          src={assetUrl(
                            "/ai-tools/ai-music-generator/loading-small.webp",
                          )}
						  alt="loading small"
                          className=" h-4 animate-[spin_1s_infinite_linear]"
                        />
                      ) : (
                        <img
                          src={assetUrl(
                            "/ai-tools/ai-music-generator/regenerate.webp",
                          )}
						  alt="regenerate icon"
                          className=" h-4"
                        />
                      )}

                      {generatingMusic[group.playlist] ===
                        undefined ||
                      generatingMusic[group.playlist]
                        ? t("lbl_generate")
                        : t("txt_regenerate")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="h-[150px] w-full shrink-0"></div>
      </div>

      <AudioPlayer
        music={music}
        canPlay={canPlay}
        title={title}
        name={name}
        image={image}
      />
      <SubscriptionPopup
        isOpen={subscriptionPopupOpen}
        close={() => setSubscriptionPopupOpen(false)}
        location="/editor/ai-music-generator"
        addToStorage={addToStorage}
      />
    </div>
  )
}

function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

function formatTime(time: number): string {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`
}

interface AudioPlayerProps {
  music: string | null
  canPlay: boolean
  title: string
  name: string
  image: string
}

function AudioPlayer(props: AudioPlayerProps) {
  const { music, canPlay, title, name, image } = props
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentTime, setCurrentTime] = useState("00:00")
  const [currentNumber, setCurrentNumber] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [volumePercentage, setVolumePercentage] =
    useState(100)
  const [isVolumeDragging, setIsVolumeDragging] =
    useState(false)
  const volumeBarRef = useRef<HTMLDivElement | null>(null)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const progressBarRef = useRef<HTMLDivElement | null>(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (canPlay && music) {
      navigator.mediaSession.metadata =
        new window.MediaMetadata({
          title: title,
          artist: name,
          artwork: [
            {
              src: image,
              sizes: "312x312",
              type: "image/webp",
            },
          ],
        })
    } else {
      navigator.mediaSession.metadata = null
    }

    return () => {
      navigator.mediaSession.metadata = null
    }
  }, [canPlay, image, music, name, title])

  const handleProgressBarClick = (
    event: React.PointerEvent<HTMLDivElement>,
  ) => {
    if (!progressBarRef.current || !audioRef.current) return

    const progressBarWidth =
      progressBarRef.current.clientWidth
    const clickPositionX =
      event.clientX -
      progressBarRef.current.getBoundingClientRect().left

    const newPositionInSeconds =
      (clickPositionX / progressBarWidth) *
      audioRef.current.duration

    audioRef.current.currentTime = newPositionInSeconds
  }

  const handlePointerDown = (
    event: React.PointerEvent<HTMLDivElement>,
  ) => {
    event.preventDefault()
    setIsDragging(true)
  }

  useEffect(() => {
    const progressBar = progressBarRef.current
    const audio = audioRef.current

    if (!progressBar || !audio) return

    progressBar.focus()

    const handlePointerUp = () => {
      window.removeEventListener(
        "pointerup",
        handlePointerUp,
      )
      window.removeEventListener(
        "pointermove",
        handlePointerMove,
      )
      setIsDragging(false)
    }

    const handlePointerMove = (event: PointerEvent) => {
      if (
        !progressBar ||
        !audio ||
        !isDragging ||
        !event.clientX
      ) {
        return
      }

      const progressBarWidth = progressBar.clientWidth
      const clickPositionX =
        event.clientX -
        progressBar.getBoundingClientRect().left
      const newPositionInSeconds =
        (clickPositionX / progressBarWidth) * audio.duration
      setCurrentNumber(
        Math.min(newPositionInSeconds, audio.duration),
      )
      audio.currentTime = newPositionInSeconds
    }

    progressBar.addEventListener(
      "pointerdown",
      (event: PointerEvent) => {
        event.preventDefault()
        setIsDragging(true)
      },
    )

    window.addEventListener(
      "pointermove",
      handlePointerMove,
    )
    window.addEventListener("pointerup", handlePointerUp)

    return () => {
      window.removeEventListener(
        "pointermove",
        handlePointerMove,
      )
      window.removeEventListener(
        "pointerup",
        handlePointerUp,
      )
    }
  }, [progressBarRef, audioRef, isDragging])
  const handleVolumeBarClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (!volumeBarRef.current || !audioRef.current) return

    const volumeBarWidth = volumeBarRef.current.clientWidth
    const clickPositionX =
      event.clientX -
      volumeBarRef.current.getBoundingClientRect().left
    const newVolumePercentage =
      (clickPositionX / volumeBarWidth) * 100

    setVolumePercentage(newVolumePercentage)
    const newVolume = newVolumePercentage / 100
    audioRef.current.volume = newVolume
  }

  const handleVolumeBarMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsVolumeDragging(true)
    },
    [],
  )

  useEffect(() => {
    const volumeBar = volumeBarRef.current
    const audio = audioRef.current

    if (!volumeBar || !audio) return

    volumeBar.focus()

    const handleMouseUp = () => {
      window.removeEventListener("mouseup", handleMouseUp)
      window.removeEventListener(
        "mousemove",
        handleVolumeBarDrag,
      )
      setIsVolumeDragging(false)
    }

    const handleVolumeBarDrag = (event: MouseEvent) => {
      if (
        !volumeBar ||
        !audio ||
        !isVolumeDragging ||
        !event.clientX
      )
        return

      const volumeBarWidth = volumeBar.clientWidth
      let newVolumePercentage =
        ((event.clientX -
          volumeBar.getBoundingClientRect().left) /
          volumeBarWidth) *
        100

      newVolumePercentage = Math.max(
        0,
        Math.min(100, newVolumePercentage),
      )

      setVolumePercentage(newVolumePercentage)
      const newVolume = newVolumePercentage / 100
      audio.volume = newVolume
    }

    volumeBar?.addEventListener(
      "mousedown",
      (event: MouseEvent) => {
        event.preventDefault()
        setIsVolumeDragging(true)
      },
    )

    window.addEventListener(
      "mousemove",
      handleVolumeBarDrag,
    )
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
      window.removeEventListener(
        "mousemove",
        handleVolumeBarDrag,
      )
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [
    volumeBarRef,
    audioRef,
    isVolumeDragging,
    handleVolumeBarMouseDown,
  ])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (!audioRef.current) return

      if (event.key === "ArrowUp") {
        const newVolumePercentage = Math.min(
          volumePercentage + 10,
          100,
        )
        setVolumePercentage(newVolumePercentage)
        audioRef.current.volume = newVolumePercentage / 100
      } else if (event.key === "ArrowDown") {
        const newVolumePercentage = Math.max(
          volumePercentage - 10,
          0,
        )
        setVolumePercentage(newVolumePercentage)
        audioRef.current.volume = newVolumePercentage / 100
      }
    },
    [volumePercentage],
  )

  const handleAudioKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      const progressBar = progressBarRef.current
      const audio = audioRef.current

      if (!progressBar || !audio) return
      if (event.key === "ArrowLeft") {
        audio.currentTime -= 10
      } else if (event.key === "ArrowRight") {
        audio.currentTime += 10
      }
    },
    [],
  )

  if (music === null) {
    return <></>
  }

  return (
    <div
      className={clsx(
        "fixed bottom-6 flex w-full items-center justify-center overscroll-none px-4 transition-all tablet:px-0 desktop:bottom-10",
        canPlay
          ? "translate-y-0"
          : "pointer-events-none translate-y-[100px] opacity-0",
      )}>
      <div className="relative flex h-[60px] w-full flex-row justify-between overflow-hidden rounded-[10px] bg-color-cell p-[10px] tablet:h-[114px] tablet:w-[745px] tablet:gap-4 tablet:rounded-[20px] tablet:p-5 desktop:w-[975px] desktop:gap-7">
        <div className="pointer-events-none absolute left-0 top-0 h-full w-[60%] blur-[30px]">
          <img
            src={image}
            alt={title + " - " + name}
            className="h-full w-full opacity-70 blur-[30px] tablet:opacity-50"
          />
        </div>

        {/* Image and Title Section */}
        <div className="pointer-events-none z-[2] flex flex-row items-center gap-2 tablet:gap-[15px]">
          <img
            src={image}
            alt={title + " - " + name}
            className="aspect-1 h-full rounded-[10px] object-cover"
          />
          <div className="flex flex-col">
            <p className="text-[16px] font-500 leading-5 text-blue-800 tablet:leading-normal">
              {title}
            </p>
            <p className="text-[13px] font-400 leading-4 text-blue-700 tablet:leading-normal">
              {name}
            </p>
          </div>
        </div>

        {/* Progress Bar Section */}
        <div className="z-[1] flex flex-row items-center gap-3">
          <button
            className="relative hidden h-6 w-6 overflow-hidden tablet:block"
            onClick={() => {
              if (audioRef.current?.paused) {
                setIsPlaying(true)
                audioRef.current.play()
              } else {
                setIsPlaying(false)
                audioRef.current?.pause()
              }
            }}>
            <div
              className={clsx(
                "absolute left-0 top-0 flex items-center transition-opacity [--icon-color:var(--color-blue-700)]",
                !isPlaying && "opacity-0",
              )}>
              {pauseIcon()}
            </div>
            <div
              className={clsx(
                "absolute left-0 top-0 flex items-center transition-opacity [--icon-color:var(--color-blue-700)]",
                isPlaying && "opacity-0",
              )}>
              {playIcon()}
            </div>
          </button>

          <p className="hidden w-[35px] text-[12px] font-500 text-blue-700 tablet:block">
            {currentTime}
          </p>

          <div
            ref={progressBarRef}
            className={clsx(
              "group absolute left-0 top-0 h-full w-full cursor-pointer focus:outline-none",
              "tablet:relative tablet:h-1 tablet:w-[257px] tablet:rounded-md tablet:bg-blue-200 desktop:w-[284px]",
            )}
            onClick={(
              event: React.PointerEvent<HTMLDivElement>,
            ) => handleProgressBarClick(event)}
            onPointerDown={handlePointerDown}
            onKeyDown={handleAudioKeyDown}
            tabIndex={0}>
            <div
              style={{
                width: `${
                  (currentNumber /
                    (audioRef.current?.duration ?? 1)) *
                  100
                }%`,
              }}
              className={clsx(
                "absolute hidden h-full rounded-md transition-colors tablet:block",
                isDragging ? "bg-blue-800" : "bg-blue-700",
              )}
            />
            <div
              className={clsx(
                "absolute top-0 hidden h-[10px] w-[10px] -translate-x-[5px] -translate-y-[3px]",
                "transform rounded-full bg-blue-800 transition-opacity inner-border-[1px] inner-border-blue-400 tablet:block",
                isDragging
                  ? "opacity-100"
                  : "opacity-0 hover:opacity-100 group-hover:opacity-100",
              )}
              style={{
                left: `${
                  (currentNumber /
                    (audioRef.current?.duration ?? 1)) *
                  100
                }%`,
              }}
            />
            <div
              style={{
                left: `${
                  (currentNumber /
                    (audioRef.current?.duration ?? 1)) *
                  100
                }%`,
              }}
              className={clsx(
                "absolute top-0 h-full w-[144px] -translate-x-full bg-gradient-to-l to-[transparent] transition-colors tablet:hidden",
                isDragging
                  ? "from-[#ff316470]"
                  : "from-[#ff31643c]",
              )}
            />
          </div>

          <p className="hidden w-[35px] text-[12px] font-500 text-blue-700 tablet:block">
            {formatTime(audioRef.current?.duration ?? 0)}
          </p>
        </div>

        {/* Volume Section */}
        <div className="hidden flex-row items-center gap-2 desktop:flex">
          <button
            onClick={(e) => {
              e.preventDefault()
              if (
                !audioRef.current ||
                !volumeBarRef.current
              )
                return

              volumeBarRef.current.focus()

              if (volumePercentage > 0) {
                audioRef.current.volume = 0
                setVolumePercentage(0)
              } else {
                audioRef.current.volume = 0.7
                setVolumePercentage(70)
              }
            }}
            className="h-6 w-6 [--icon-color:var(--color-blue-700)]">
            {volumePercentage > 50
              ? volumeFullIcon()
              : volumePercentage !== 0
              ? volumeHalfIcon()
              : volumeMuteIcon()}
          </button>
          <div
            ref={volumeBarRef}
            className="group relative h-1 w-[70px] cursor-pointer rounded-md bg-blue-200 focus:outline-none"
            onClick={(
              event: React.MouseEvent<HTMLDivElement>,
            ) => handleVolumeBarClick(event)}
            onMouseDown={handleVolumeBarMouseDown}
            onKeyDown={handleKeyDown}
            tabIndex={0}>
            <div
              style={{
                width: `${volumePercentage}%`,
              }}
              className={clsx(
                "absolute h-full rounded-md transition-colors",
                isVolumeDragging
                  ? "bg-blue-800"
                  : "bg-blue-700",
              )}
            />
            <div
              className={clsx(
                "absolute top-0 h-[10px] w-[10px] -translate-x-[5px] -translate-y-[3px] transform rounded-full bg-blue-800 transition-opacity inner-border-[1px] inner-border-blue-400",
                isVolumeDragging
                  ? "opacity-100"
                  : "opacity-0 hover:opacity-100 group-hover:opacity-100",
              )}
              style={{
                left: `${volumePercentage}%`,
              }}
            />
          </div>
        </div>

        {/* Download Section */}
        <div className="z-[1] flex flex-row items-center gap-5">
          <button
            className="relative h-6 w-6 overflow-hidden tablet:pointer-events-none tablet:hidden"
            onClick={() => {
              if (audioRef.current?.paused) {
                setIsPlaying(true)
                audioRef.current.play()
              } else {
                setIsPlaying(false)
                audioRef.current?.pause()
              }
            }}>
            <div
              className={clsx(
                "absolute left-0 top-0 flex items-center transition-opacity [--icon-color:var(--color-blue-700)]",
                !isPlaying && "opacity-0",
              )}>
              {pauseIcon()}
            </div>
            <div
              className={clsx(
                "absolute left-0 top-0 flex items-center transition-opacity [--icon-color:var(--color-blue-700)]",
                isPlaying && "opacity-0",
              )}>
              {playIcon()}
            </div>
          </button>

          <a
            href={`/api/download-music?link=${encodeURIComponent(
              music,
            )}`}
            target="_blank"
            rel="noreferrer noopener"
            download={title + " - " + name}
            className={clsx(
              "h-10 w-10 rounded-full bg-primary-500",
              "transition-colors tablet:pointer-events-none tablet:hidden",
              "flex flex-row items-center justify-center",
            )}>
            <img
              className={clsx("h-4 w-4")}
              src={assetUrl(
                "/ai-tools/ai-music-generator/download.webp",
              )}
              alt="download"
            />
          </a>

          <a
            href={`/api/download-music?link=${encodeURIComponent(
              music,
            )}`}
            target="_blank"
            rel="noreferrer noopener"
            download={title + " - " + name}
            className={clsx(
              "h-11 rounded-[8px] font-500 tablet:w-[129px] desktop:w-[171px]",
              "text-[16px] text-color-white",
              "hover:bg-primary-600 bg-primary-500",
              "hidden transition-colors",
              "flex-row items-center justify-center gap-1 tablet:flex",
            )}>
            <div className="flex flex-row">
              <img
                className={clsx("h-4 w-4")}
                src={assetUrl(
                  "/ai-tools/ai-music-generator/download.webp",
                )}
                alt="download"
              />
            </div>

            {t("lbl_download")}
          </a>
        </div>
      </div>
      {canPlay && (
        <audio
          ref={audioRef}
          playsInline
          autoPlay
          loop
          title={title + " - " + name}
          onTimeUpdate={(e) => {
            setCurrentTime(
              formatTime(e.currentTarget.currentTime),
            )
            setCurrentNumber(e.currentTarget.currentTime)
          }}
          className="h-0 w-0 opacity-0"
          src={music}
        />
      )}
    </div>
  )
}

export interface PromptState {
  [id: string]: number | string | boolean
}

interface ParametersTriggerProps {
  promptState: PromptState
  params: PromptParams[] | null
  setPromptState: (state: PromptState | null) => void
  actionLoading?: boolean
  showFiltered: boolean
  className?: string
}

export function ParamtersTriggerSmall(
  props: ParametersTriggerProps,
) {
  const [open, setOpen] = useState(false)
  const { actionLoading, className, params, showFiltered } =
    props

  return (
    <>
      <button
        onClick={() => {
          if (actionLoading) return
          setOpen(true)
        }}
        className={clsx(
          "group relative flex h-11 w-11 items-center justify-center gap-2 rounded-[11px] border border-blue-200 bg-color-cell transition-all desktop:w-[77px] desktop:rounded-[15px] desktop:border-none desktop:px-4 desktop:py-6 desktop:hover:bg-blue-200",
          className,
        )}>
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.9998 3.33203H12.1665"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.83309 3.33203H2.99976"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 10H10.5"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.16667 10H3"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.9997 16.6641H13.833"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 16.6641H3"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1663 1.66406V4.9974"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.1665 8.33203V11.6654"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.833 15V18.3333"
            stroke="#888CA0"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div
          className={clsx(
            "absolute right-[-2px] top-[-2px] h-2 w-2 rounded-full bg-primary-500 transition-opacity desktop:right-[0px] desktop:top-[1px]",
            showFiltered ? "opacity-100" : "opacity-0",
          )}></div>
      </button>
      <RestyleConfigurator
        {...props}
        open={open}
        setOpen={setOpen}
        params={params}
      />
    </>
  )
}

const playIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1198 13.68C20.3378 12.8919 20.3378 11.1098 19.1198 10.3217L7.80134 2.99798C6.47062 2.13692 4.71484 3.09211 4.71484 4.67712V19.3245C4.71484 20.9095 6.47062 21.8647 7.80135 21.0037L19.1198 13.68Z"
        fill="var(--icon-color)"
      />
    </svg>
  )
}

const pauseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.65039"
        y="2.84961"
        width="5.1"
        height="18.3"
        rx="2.55"
        fill="var(--icon-color)"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
      />
      <rect
        x="14.25"
        y="2.84961"
        width="5.1"
        height="18.3"
        rx="2.55"
        fill="var(--icon-color)"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
      />
    </svg>
  )
}

const volumeFullIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9.99979V13.9998C2 15.9998 3 16.9998 5 16.9998H6.43C6.8 16.9998 7.17 17.1098 7.49 17.2998L10.41 19.1298C12.93 20.7098 15 19.5598 15 16.5898V7.40979C15 4.42979 12.93 3.28979 10.41 4.86979L7.49 6.69979C7.17 6.88979 6.8 6.99979 6.43 6.99979H5C3 6.99979 2 7.99979 2 9.99979Z"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
      />
      <path
        d="M18 8C19.78 10.37 19.78 13.63 18 16"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8301 5.5C22.7201 9.35 22.7201 14.65 19.8301 18.5"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const volumeHalfIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9.99979V13.9998C2 15.9998 3 16.9998 5 16.9998H6.43C6.8 16.9998 7.17 17.1098 7.49 17.2998L10.41 19.1298C12.93 20.7098 15 19.5598 15 16.5898V7.40979C15 4.42979 12.93 3.28979 10.41 4.86979L7.49 6.69979C7.17 6.88979 6.8 6.99979 6.43 6.99979H5C3 6.99979 2 7.99979 2 9.99979Z"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
      />
      <path
        d="M18 8C19.78 10.37 19.78 13.63 18 16"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const volumeMuteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9.99979V13.9998C2 15.9998 3 16.9998 5 16.9998H6.43C6.8 16.9998 7.17 17.1098 7.49 17.2998L10.41 19.1298C12.93 20.7098 15 19.5598 15 16.5898V7.40979C15 4.42979 12.93 3.28979 10.41 4.86979L7.49 6.69979C7.17 6.88979 6.8 6.99979 6.43 6.99979H5C3 6.99979 2 7.99979 2 9.99979Z"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
      />
    </svg>
  )
}
