import { CustomThemedResource } from "@/comps/image"
import { SquareSwitch, Switch } from "@/comps/toggle"
import {
	CameraStyle,
	PromptParams,
	SliderKind,
} from "@/ssr/ai-tools"
import { assetUrl } from "@/utils/cdn"
import {
	extractFramesFromVideo,
	getVideoDimensions,
	getVideoDurationMillis,
	KeyframeWithSource,
} from "@/utils/video"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import {
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"
import { VideoContext } from "video-provider"
import { ConditionalButton } from "./button"
import { ParamsContext } from "./editors/deform"
import { calculateDurationPrice } from "./editors/restyle"

export interface PromptState {
	[id: string]: number | string | boolean
}

export function createPromptStateFrom(
	params: PromptParams[] | null,
): PromptState | null {
	if (!params) return null
	return params.reduce((acc, param) => {
		switch (param.type) {
			case "switcher":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "slider":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "cameras":
				return {
					...acc,
					[param.id]: "",
				}
			case "checkbox":
				return {
					...acc,
					[param.id]: Boolean(param.defaultValue),
				}
			case "select":
				return {
					...acc,
					[param.id]: param.defaultValue,
				}
			case "dynamic":
				return acc
		}
	}, {})
}

export interface UseVideoClipperConfig {
	file: Blob | null
	start?: number
}

export interface UseVideoClipperResult {
	startTime: number
	selectedDuration: number
	setStartTime: (value: number) => void
	setSelectedDuration: (value: number) => void
	duration: number
	width: number
	height: number
}

export function useKeyFrames(
	video: Blob,
	count: number,
): KeyframeWithSource[] | null {
	const [frames, setFrames] = useState<
		KeyframeWithSource[] | null
	>(null)

	useEffect(() => {
		if (frames !== null) {
			return
		}

		extractFramesFromVideo(video, count).then(
			({ keyframes }) => setFrames(keyframes),
		)
	}, [count, frames, video])

	return frames
}

const INITIAL_CROPPED_MILLIS = 1_000

const PICKER_FRAME_COUNT = 10
export function useVideoClipper(
	config: UseVideoClipperConfig,
): UseVideoClipperResult | null {
	const { file, start } = config

	const [duration, setDuration] = useState<number | null>(
		null,
	)
	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0,
	})

	useEffect(() => {
		async function loadState() {
			if (file === null) return
			const [extractedDimensions, extractedDuration] =
				await Promise.all([
					getVideoDimensions(file),
					getVideoDurationMillis(file),
				])

			const initialStartTime = Math.min(
				start ?? 0,
				extractedDuration - INITIAL_CROPPED_MILLIS,
			)
			const initialDuration = Math.min(
				INITIAL_CROPPED_MILLIS,
				extractedDuration - initialStartTime,
			)
			setStartTime(initialStartTime)
			setSelectedDuration(initialDuration)
			setDimensions(extractedDimensions)
			setDuration(extractedDuration)
		}
		loadState().catch(console.error)
	}, [file, start])

	const [startTime, setStartTime] = useState(0)
	const [selectedDuration, setSelectedDuration] = useState(
		INITIAL_CROPPED_MILLIS,
	)

	if (duration === null) {
		return null
	}

	return {
		...dimensions,
		startTime,
		selectedDuration,
		setStartTime,
		setSelectedDuration,
		duration,
	}
}

interface MoveInitialState {
	time: number
	startTime: number
}

interface ClipVideoSectorProps {
	className?: string
	controller: UseVideoClipperResult
	file: Blob
}

type CropClickPosition = "left" | "right" | "middle"

function clampNumber(x: number, min: number, max: number) {
	return Math.max(Math.min(max, x), min)
}

export const MAX_FREE_MILLIS = 1_000
const MIN_ALLOWED_MILLIS = 300
const MAX_ALLOWED_MILLIS = 30_000

export function ClipVideoSector(
	props: ClipVideoSectorProps,
) {
	const { className, controller, file } = props
	const {
		startTime,
		selectedDuration,
		setStartTime,
		setSelectedDuration,
		duration,
	} = controller
	const keyframes = useKeyFrames(file, PICKER_FRAME_COUNT)
	const [position, setPosition] =
		useState<CropClickPosition | null>(null)

	const { now } = useContext(VideoContext) || {}

	const [initialMouse, setInitialMouse] =
		useState<MoveInitialState | null>(null)

	const leftWidth = (startTime / duration) * 100
	const endTime = startTime + selectedDuration
	const rightWidth = ((duration - endTime) / duration) * 100

	const frameWidth = 100 / PICKER_FRAME_COUNT

	const costsCoins =
		calculateDurationPrice(selectedDuration) > 0

	const durationSeconds = duration / 1000
	const startPercent = (startTime / duration) * 100
	const endPercent = (endTime / duration) * 100
	const currentPercent =
		((now ?? 0) / durationSeconds) * 100
	const progressPercent = Math.max(
		startPercent,
		Math.min(endPercent, currentPercent),
	)

	return (
		<div
			className={clsx(
				"relative flex w-full",
				position === "middle"
					? "cursor-grab"
					: "cursor-ew-resize",
				className,
			)}
			onPointerLeave={(_) => {
				setPosition(null)
				setInitialMouse(null)
			}}
			onPointerUp={(_) => {
				setPosition(null)
				setInitialMouse(null)
			}}
			onPointerMove={function (event) {
				if (!position) {
					return
				}

				const { x, width } =
					event.currentTarget.getBoundingClientRect()
				const percentUnclamped = (event.clientX - x) / width
				const percent = clampNumber(percentUnclamped, 0, 1)
				const unclampedTime = percent * duration

				switch (position) {
					case "left":
						const currentEnd = startTime + selectedDuration
						const clampedStartDuration = Math.max(
							MIN_ALLOWED_MILLIS,
							Math.min(
								MAX_ALLOWED_MILLIS,
								currentEnd - unclampedTime,
							),
						)
						setSelectedDuration(
							Math.round(clampedStartDuration),
						)
						const clampedStart = Math.max(
							0,
							currentEnd - clampedStartDuration,
						)
						setStartTime(Math.round(clampedStart))
						break
					case "right":
						const clampedDuration = Math.max(
							MIN_ALLOWED_MILLIS,
							Math.min(
								MAX_ALLOWED_MILLIS,
								unclampedTime - startTime,
							),
						)
						setSelectedDuration(Math.round(clampedDuration))
						break
					case "middle":
						if (initialMouse === null) {
							setInitialMouse({
								time: unclampedTime,
								startTime,
							})
							break
						}

						const delta = unclampedTime - initialMouse.time
						const newStartTime = clampNumber(
							initialMouse.startTime + delta,
							0,
							duration - selectedDuration,
						)

						setStartTime(Math.round(newStartTime))
						break
				}
			}}>
			{keyframes
				? keyframes.map((keyframe, index) => (
						<img
							src={keyframe.source}
							key={keyframe.source}
							alt="keyframe image"
							className={clsx(
								"pointer-events-none aspect-[9/16] object-cover",
								index === 0 && "rounded-l-[8px]",
								index === keyframes.length - 1 &&
									"rounded-r-[8px]",
							)}
							style={{
								width: `${frameWidth}%`,
							}}
						/>
				  ))
				: new Array(PICKER_FRAME_COUNT)
						.fill(null)
						.map((_, index) => (
							<div
								key={index}
								className={clsx(
									"pointer-events-none aspect-[9/16] object-cover",
									index === 0 && "rounded-l-[8px]",
									index === PICKER_FRAME_COUNT - 1 &&
										"rounded-r-[8px]",
								)}
								style={{
									width: `${frameWidth}%`,
								}}
							/>
						))}

			{now !== undefined && (
				<div
					className="absolute top-0 h-full w-1 -translate-x-1/2 bg-color-white transition-all duration-75"
					style={{
						left: `${progressPercent}%`,
					}}
				/>
			)}

			<div className="absolute inset-0 flex">
				<div
					className={clsx(
						"rounded-l-[8px] bg-color-black/60",
						position === null && "cursor-grab",
					)}
					style={{ width: `${leftWidth}%` }}
					onPointerDown={(_) => setPosition("middle")}
				/>
				<div
					className={clsx(
						"flex flex-1 justify-between inner-border-[3px]",
						costsCoins
							? "inner-border-[#FABF2A]"
							: "inner-border-color-success",
						"transition-colors",
					)}>
					<div
						className={clsx(
							"flex h-full w-3 items-center justify-center",
							"-translate-x-[5px] cursor-ew-resize rounded-l-[3px]",
							costsCoins
								? "bg-[#FABF2A]"
								: "bg-color-success",
							"transition-colors",
						)}
						onPointerDown={(_) => setPosition("left")}>
						<div className="h-1/2 w-[3px] rounded-full bg-color-white" />
					</div>
					<div
						className={clsx(
							"-mx-2 flex-1",
							position === null && "cursor-grab",
						)}
						onPointerDown={(_) => setPosition("middle")}
					/>
					<div
						className={clsx(
							"flex h-full w-3 items-center justify-center",
							"translate-x-[5px] cursor-ew-resize rounded-r-[3px]",
							costsCoins
								? "bg-[#FABF2A]"
								: "bg-color-success",
							"transition-colors",
						)}
						onPointerDown={(_) => setPosition("right")}>
						<div className="h-1/2 w-[3px] rounded-full bg-color-white" />
					</div>
				</div>
				<div
					className={clsx(
						"rounded-r-[8px] bg-color-black/60",
						position === null && "cursor-grab",
					)}
					style={{ width: `${rightWidth}%` }}
					onPointerDown={(_) => setPosition("middle")}
				/>
			</div>
		</div>
	)
}

export interface RestyleConfiguratorProps {
	open: boolean
	setOpen: (open: boolean) => void
	promptState: PromptState
	params: PromptParams[] | null
	setPromptState: (state: PromptState | null) => void
}

interface ParametersTriggerProps {
	promptState: PromptState
	setPromptState: (state: PromptState | null) => void
	actionLoading?: boolean
	className?: string
}

export function ParamtersTrigger(
	props: ParametersTriggerProps,
) {
	const [open, setOpen] = useState(false)
	const { actionLoading, className } = props
	const params = useContext(ParamsContext)

	const { t } = useTranslation()

	if (!params) {
		return <>Cannot use params trigger here</>
	}

	if (!params.params) return <></>

	let countVisible = 0
	for (let i = 0; i < params.params.length; i++) {
		const param = params.params[i]
		const type = param.type
		if (type === "slider" && !param.invisible) {
			countVisible++
		}
		if (
			type === "checkbox" ||
			type === "cameras" ||
			type === "select" ||
			type === "switcher"
		) {
			countVisible++
		}
	}

	const invisible = countVisible === 0

	if (invisible) return <></>

	return (
		<>
			<button
				onClick={() => {
					if (actionLoading) return
					setOpen(true)
				}}
				className={clsx(
					"group flex items-center gap-2 rounded-[15px] bg-color-cell px-4 py-6 transition-all hover:bg-blue-200",
					actionLoading && "opacity-30",
					className,
				)}>
				<svg
					width="22"
					height="22"
					viewBox="0 0 22 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.2498 3.66602H12.8331"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M9.16654 3.66602H2.74988"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M19.25 11H11"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.33333 11H2.75"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M19.2498 18.332H14.6665"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11 18.332H2.75"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M12.833 1.83203V5.4987"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.33313 9.16602V12.8327"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M14.6665 16.5V20.1667"
						stroke="var(--color-blue-500)"
						strokeWidth="1.83333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>

				<span className="text-[16px] font-600 text-blue-600 transition-colors group-hover:text-blue-800">
					{t("lbl_parameters")}
				</span>

				<div className="flex-1" />

				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9 6L15 12L9 18"
						stroke="#9195AB"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<RestyleConfigurator
				{...props}
				open={open}
				setOpen={setOpen}
				params={params.params}
			/>
		</>
	)
}

interface ConfigSwitcherProps {
	id: string
	name: string
	value: number | string | boolean
	setState: (state: number) => void
}

export function ConfigSwitcher(props: ConfigSwitcherProps) {
	const { id, name, value, setState } = props

	const { t } = useTranslation()

	return (
		<div className="px-4" key={id}>
			<div className="flex items-center gap-2 rounded-[6px] bg-color-background px-3 py-2">
				<span className="font-700 uppercase text-blue-600">
					{t(name)}
				</span>
				<div className="flex-1" />
				<Switch
					enabled={value === 1}
					onChange={(status) => setState(status ? 0 : 1)}
				/>
			</div>
		</div>
	)
}

interface ConfigCamerasProps {
	id: string
	value: string | number | boolean
	styles: CameraStyle[]
	setState: (state: string) => void
}

function ConfigCameras(props: ConfigCamerasProps) {
	const { id, value, styles, setState } = props

	const cameraRef = useRef<HTMLDivElement>(null)

	const { t } = useTranslation()

	return (
		<div
			key={id}
			className="relative flex w-full justify-start gap-2">
			<div className="absolute right-0 top-0 h-full w-12 bg-gradient-to-l from-color-cell to-color-white/0" />

			<div
				className="no-scrollbar flex gap-2 overflow-x-scroll px-4"
				ref={cameraRef}>
				<button
					className={clsx(
						"font-500 transition-all",
						value === ""
							? "text-primary-500"
							: "text-blue-600",
					)}
					onClick={(_) => setState("")}>
					<div
						aria-checked={value === ""}
						className={clsx(
							"border-2 border-[transparent] aria-checked:border-primary-500",
							"transition-all",
							"h-[84px] w-[84px] shrink-0 overflow-hidden rounded-[12px]",
						)}>
						<CustomThemedResource
							source="/tools/none-image"
							className="h-full w-full object-cover"
						/>
					</div>
					{t("lbl_none")}
				</button>

				{styles.map((style) => (
					<button
						key={style.id}
						className="font-500 text-blue-600"
						onClick={(_) => setState(style.id)}>
						<div
							aria-checked={value === style.id}
							className={clsx(
								"border-2 border-[transparent] aria-checked:border-primary-500",
								"transition-all",
								"h-[84px] w-[84px] shrink-0 overflow-hidden rounded-[12px]",
							)}>
							<img
								src={style.image}
								alt="style image"
								className="h-full w-full object-cover"
							/>
						</div>
						<p
							className={clsx(
								"w-[84px] truncate font-500 transition-all",
								value === style.id
									? "text-primary-500"
									: "text-blue-600",
							)}>
							{style.name}
						</p>
					</button>
				))}
			</div>
		</div>
	)
}

const SCROLL_SPEED = 0.8
export function RestyleConfigurator(
	props: RestyleConfiguratorProps,
) {
	const {
		promptState,
		params,
		setPromptState,
		open,
		setOpen,
	} = props

	const [currentPromptState, setCurrentPromptState] =
		useState(promptState)
	const cameraRef = useRef<HTMLDivElement>(null)

	const [showHints, setShowHints] = useState(false)

	const { t } = useTranslation()

	useEffect(() => {
		const element = cameraRef.current
		if (!element) {
			return
		}

		function handleWheel(event: WheelEvent) {
			if (!element) {
				return
			}
			event.preventDefault()

			element.scrollLeft +=
				event.deltaY * SCROLL_SPEED + event.deltaX
		}

		element.addEventListener("wheel", handleWheel, {
			passive: false,
		})

		return () =>
			element.removeEventListener("wheel", handleWheel)
	}, [cameraRef])

	useEffect(() => {
		setCurrentPromptState(promptState)
	}, [promptState])

	if (!params) return <></>

	let countSlider = 0
	for (let i = 0; i < params.length; i = i + 1) {
		const param = params[i]
		if (param.type === "slider" && param.invisible) {
			countSlider = countSlider + 1
		}
	}

	const invisible = params.length === countSlider

	if (invisible) return <></>

	return (
		<div
			className={clsx(
				"fixed inset-0 flex items-end justify-start bg-color-popup tablet:items-center tablet:justify-center",
				!open && "pointer-events-none opacity-0",
				"z-[120] transition-opacity",
			)}
			id="ai-config-popup"
			onClick={(event) => {
				if (event.target instanceof Element) {
					if (event.target.id === event.currentTarget.id) {
						setOpen(false)
						if (currentPromptState !== promptState) {
							setCurrentPromptState(promptState)
						}
					}
				}
			}}>
			<div
				className={clsx(
					"relative w-full rounded-2xl bg-color-cell pb-9 pt-5 transition-all tablet:w-[575px] tablet:py-4",
					open
						? "translate-y-0"
						: "translate-y-full tablet:translate-y-0",
				)}>
				<button
					onClick={() => {
						setOpen(false)
						if (currentPromptState !== promptState) {
							setCurrentPromptState(promptState)
						}
					}}
					className={clsx(
						"absolute -right-10 top-0 hidden h-8 w-8 items-center justify-center rounded-full desktop:flex",
						"bg-color-cell transition-colors hover:bg-blue-100",
					)}>
					<CustomThemedResource
						format="svg"
						source="/general/close"
					/>
				</button>
				<span className="block w-full pb-1 text-center text-[20px] font-600 text-blue-600">
					{t("lbl_parameters")}
				</span>
				<button
					onClick={() => setShowHints(!showHints)}
					className="absolute right-4 top-6">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="transition-all">
						<g clipPath="url(#clip0_8639_9982)">
							<circle
								cx="9.99935"
								cy="10.0001"
								r="8.33333"
								fill={
									!showHints
										? "var(--color-background-cell)"
										: "var(--color-blue-500)"
								}
								stroke="var(--color-blue-500)"
								strokeWidth="1.66667"
								className="transition-all"
							/>
							<path
								d="M10.0007 11.25V11.0167C10.0007 10.2612 10.4224 9.86114 10.8441 9.53891C11.2557 9.2278 11.6673 8.82782 11.6673 8.09448C11.6673 7.07226 10.9244 6.25 10.0007 6.25C9.07694 6.25 8.33398 7.07226 8.33398 8.09448"
								stroke={
									!showHints
										? "var(--color-blue-500)"
										: "var(--color-background-cell)"
								}
								strokeWidth="1.66667"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="transition-all"
							/>
							<path
								d="M9.9969 13.7499H10.0044"
								stroke={
									!showHints
										? "var(--color-blue-500)"
										: "var(--color-background-cell)"
								}
								strokeWidth="1.66667"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="transition-all"
							/>
						</g>
						<defs>
							<clipPath id="clip0_8639_9982">
								<rect
									width="20"
									height="20"
									fill="var(--color-background-cell)"
								/>
							</clipPath>
						</defs>
					</svg>
				</button>
				<div className="mt-1 flex flex-col gap-3">
					{params.map((param) => {
						switch (param.type) {
							case "switcher":
								return (
									<ConfigSwitcher
										key={param.id}
										id={param.id}
										name={param.name}
										value={currentPromptState[param.id]}
										setState={(value: number) =>
											setCurrentPromptState({
												...currentPromptState,
												[param.id]: value,
											})
										}
									/>
								)

							case "cameras":
								return (
									<ConfigCameras
										key={param.id}
										id={param.id}
										styles={param.styles}
										value={currentPromptState[param.id]}
										setState={(value: string) =>
											setCurrentPromptState({
												...currentPromptState,
												[param.id]: value,
											})
										}
									/>
								)

							case "slider":
								return (
									<SliderConfigurator
										key={param.id}
										value={currentPromptState[param.id]}
										id={param.id}
										name={param.name}
										kind={param.kind}
										hint={param.hint}
										suffix={param.suffix}
										values={param.values}
										maxFreeValue={param.maxFreeValue}
										showHints={showHints}
										current={currentPromptState[param.id]}
										invisible={param.invisible}
										updateValue={(value) =>
											setCurrentPromptState({
												...currentPromptState,
												[param.id]: value,
											})
										}
									/>
								)
						}
					})}
				</div>

				<div className="mt-4 flex flex-row gap-3 px-4">
					<ConditionalButton
						style="empty"
						className="!w-[97px] !min-w-[97px] tablet:!w-[200px] tablet:!min-w-[200px]"
						onClick={() =>
							setPromptState(createPromptStateFrom(params))
						}>
						{t("lbl_reset")}
					</ConditionalButton>
					<ConditionalButton
						className="flex-1"
						onClick={() => {
							setPromptState(currentPromptState)
							setOpen(false)
						}}>
						{t("lbl_done")}
					</ConditionalButton>
				</div>
			</div>
		</div>
	)
}

interface SliderConfiguratorProps {
	showHints: boolean
	name: string
	hint: string
	id: string
	suffix: string
	kind: SliderKind
	values: number[]
	value: number | string | boolean
	maxFreeValue: number
	current: number | string | boolean
	updateValue: (value: number) => void
	invisible: boolean
}

export function SliderConfiguratorDashboard(
	props: SliderConfiguratorProps,
) {
	const {
		name,
		value,
		id,
		suffix,
		kind,
		values,
		current,
		updateValue,
		maxFreeValue,
		invisible,
	} = props

	const selected = typeof value === "number" ? value : 0

	const { t } = useTranslation()
	const isFree =
		typeof value === "string" ||
		typeof value === "boolean" ||
		value <= maxFreeValue

	if (invisible) return <></>

	return (
		<div className="flex flex-col" key={id}>
			<div className="flex gap-2">
				<div className="flex gap-[4px]">
					<span className="text-[14px] font-600 text-blue-800">
						{t(name)}
					</span>
					<img
						src={assetUrl("/general/coin.svg")}
						alt="coin icon"
						className={clsx(
							isFree ? "opacity-0" : "opacity-100",
							"transition-opacity",
						)}
					/>
				</div>
				<div className="flex-1" />
				<span className="flex w-[44px] items-center justify-center rounded-[4px] bg-blue-200 text-[14px] text-blue-600">
					{current}
					{suffix}
				</span>
			</div>
			<SliderWithValues
				suffix={suffix}
				kind={kind}
				values={values}
				selected={selected}
				maxFreeValue={maxFreeValue}
				onResolve={updateValue}
			/>
		</div>
	)
}

function SliderConfigurator(
	props: SliderConfiguratorProps,
) {
	const {
		showHints,
		name,
		value,
		id,
		hint,
		suffix,
		kind,
		values,
		current,
		updateValue,
		maxFreeValue,
		invisible,
	} = props

	const selected = typeof value === "number" ? value : 0

	const { t } = useTranslation()
	const isFree =
		typeof value === "string" ||
		typeof value === "boolean" ||
		value <= maxFreeValue

	if (invisible) return <></>

	return (
		<div className="px-4">
			<div
				className="flex flex-col rounded-[6px] bg-color-background px-4 py-2"
				key={id}>
				<div className="flex gap-2">
					<span className="text-grey-600 font-700 uppercase">
						{t(name)}
					</span>
					<img
						src={assetUrl("/general/coin.svg")}
						alt="coin icon"
						className={clsx(
							isFree ? "opacity-0" : "opacity-100",
							"transition-opacity",
						)}
					/>
					<div className="flex-1" />
					<span className="text-grey-600 flex w-[44px] items-center justify-center rounded-[4px] bg-blue-200 text-[14px]">
						{current}
						{suffix}
					</span>
				</div>
				<div
					className={clsx(
						"text-grey-600 text-[12px]",
						showHints ? "max-h-[36px]" : "max-h-0",
						"overflow-hidden transition-all duration-300",
					)}>
					{t(hint)}
				</div>
				<SliderWithValues
					suffix={suffix}
					kind={kind}
					values={values}
					selected={selected}
					maxFreeValue={maxFreeValue}
					onResolve={updateValue}
				/>
			</div>
		</div>
	)
}

interface SliderWithValuesProps {
	kind: SliderKind
	values: number[]
	suffix: string
	maxFreeValue: number
	selected: number
	onResolve: (value: number) => void
}

export function SliderWithValues(
	props: SliderWithValuesProps,
) {
	const {
		values,
		maxFreeValue,
		selected,
		onResolve,
		kind,
		suffix,
	} = props

	const defaultIndex = values.indexOf(maxFreeValue)

	const [minNormal] = values
	const [maxNormal] = values.slice(-1)

	const min = kind === "normal" ? minNormal : 0
	const max =
		kind === "normal" ? maxNormal : values.length - 1

	const range = max - min
	const initialPercent =
		((maxFreeValue - min) / range) * 100
	const initialPart =
		(defaultIndex / (values.length - 1)) * 100

	const selectedIndex = values.indexOf(selected)

	const selectedPercent = ((selected - min) / range) * 100
	const selectedPart =
		(selectedIndex / (values.length - 1)) * 100

	const defaultWidth =
		kind === "normal" ? initialPercent : initialPart
	const selectedPosition =
		kind === "normal" ? selectedPercent : selectedPart

	const selectedLeft = selectedPosition

	const greenWidth = Math.min(
		defaultWidth,
		selectedPosition,
	)

	return (
		<div className="flex flex-col">
			<div className="relative">
				<input
					type="range"
					min={min}
					max={max}
					step={1}
					value={
						kind === "normal" ? selected : selectedIndex
					}
					className="relative z-[100] w-full cursor-grab opacity-0 active:cursor-grabbing"
					onChange={(e) => {
						const newSelected = parseInt(
							e.currentTarget.value,
						)
						const realValue =
							kind === "normal"
								? newSelected
								: values[newSelected]
						onResolve(realValue)
					}}
				/>

				<div>
					<div className="absolute left-0 top-1/2 z-10 h-1 w-full -translate-y-1/2 bg-blue-200" />

					<div
						className="absolute left-0 top-1/2 z-20 h-1 -translate-y-1/2 bg-[#FABF2A]"
						style={{ width: `${selectedLeft}%` }}
					/>
					<div
						className="absolute left-0 top-1/2 z-30 h-1 -translate-y-1/2 bg-primary-500"
						style={{ width: `${greenWidth}%` }}
					/>
					<div
						className={clsx(
							"absolute top-1/2 z-50 h-[16px] w-[16px] -translate-x-1/2 -translate-y-1/2",
							"rounded-full border-2 border-primary-500 bg-color-cell",
						)}
						style={{ left: `${selectedLeft}%` }}
					/>
					{values.map((value, index) => {
						const percent = (value - min) / range
						const part = index / (values.length - 1)
						const position =
							kind === "normal" ? percent : part

						const activePoint = value <= selected

						const greenPoint = maxFreeValue >= value

						const greenIndicator = greenPoint && activePoint
						const yellowIndicator =
							!greenPoint && activePoint

						return (
							<div
								key={index}
								className={clsx(
									"absolute top-1/2  h-[7px] w-[7px] -translate-y-1/2",
									"rounded-full",
									greenIndicator && "z-40 bg-primary-500",
									yellowIndicator && "z-20 bg-[#FABF2A]",
									!activePoint && "z-10 bg-blue-200",
								)}
								style={{ left: `${position * 100}%` }}
							/>
						)
					})}
				</div>
			</div>
			<div className="relative h-4">
				{values.map((value, index) => {
					const percent = (value - min) / range
					const part = index / (values.length - 1)
					const position =
						kind === "normal" ? percent : part

					const firstPoint = index === 0
					const lastPoint = index === values.length - 1
					const middlePoint = !firstPoint && !lastPoint

					return (
						<span
							key={value}
							className={clsx(
								"absolute bottom-0 text-[11px] font-500 text-blue-600",
								middlePoint && "-translate-x-1/2",
								lastPoint && "-translate-x-full",
							)}
							style={{ left: `${position * 100}%` }}>
							{value}
							{suffix}
						</span>
					)
				})}
			</div>
		</div>
	)
}

interface CheckboxProps {
	id: string
	icon_light: string
	icon_dark: string
	name: string
	default_value: number
	promptState: PromptState
	setPromptState: (state: PromptState) => void
}

export function ConfigCheckbox(props: CheckboxProps) {
	const {
		id,
		icon_light,
		icon_dark,
		name,
		default_value,
		promptState,
		setPromptState,
	} = props

	const [checked, setChecked] = useState(
		Boolean(default_value),
	)
	return (
		<>
			<div className="flex flex-row items-center justify-between">
				<div className="flex flex-row items-center gap-3 text-[18px] font-500 text-blue-600">
					<img
						src={icon_light}
						alt="ter icon light"
						className="h-[20px] w-[20px] dark:hidden"
					/>
					<img
						src={icon_dark}
						alt="ter icon dark"
						className="hidden h-[20px] w-[20px] dark:block"
					/>
					{name}
				</div>
				<SquareSwitch
					enabled={checked}
					onChange={(value) => {
						setChecked(!checked)
						setPromptState({
							...promptState,
							[id]: value,
						})
					}}
				/>
			</div>
		</>
	)
}

interface GroupSectionProps {
	components: PromptParams[]
	promptState: PromptState | null
	setPromptState: (state: PromptState | null) => void
}

export function GroupSection(props: GroupSectionProps) {
	const { components, promptState, setPromptState } = props

	const cameraRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const element = cameraRef.current
		if (!element) {
			return
		}

		function handleWheel(event: WheelEvent) {
			if (!element) {
				return
			}
			event.preventDefault()

			element.scrollLeft +=
				event.deltaY * SCROLL_SPEED + event.deltaX
		}

		element.addEventListener("wheel", handleWheel, {
			passive: false,
		})

		return () =>
			element.removeEventListener("wheel", handleWheel)
	}, [cameraRef])

	if (!promptState) return <></>

	let countSlider = 0
	for (let i = 0; i < components.length; i = i + 1) {
		const param = components[i]
		if (param.type === "slider" && param.invisible) {
			countSlider = countSlider + 1
		}
	}

	const invisible = components.length === countSlider

	if (invisible) return <></>

	return (
		<>
			<div className="flex flex-col gap-[22px] rounded-[14px] bg-color-cell py-[22px] pl-[12px] pr-[22px]">
				{components.map((param) => {
					switch (param.type) {
						case "switcher":
							return (
								<ConfigSwitcher
									key={param.id}
									id={param.id}
									name={param.name}
									value={promptState[param.id]}
									setState={(value: number) =>
										setPromptState({
											...promptState,
											[param.id]: value,
										})
									}
								/>
							)

						case "cameras":
							return (
								<ConfigCameras
									key={param.id}
									id={param.id}
									styles={param.styles}
									value={promptState[param.id]}
									setState={(value: string) =>
										setPromptState({
											...promptState,
											[param.id]: value,
										})
									}
								/>
							)

						case "slider":
							return (
								<SliderConfigurator
									key={param.id}
									value={promptState[param.id]}
									id={param.id}
									name={param.name}
									kind={param.kind}
									hint={param.hint}
									showHints={false}
									suffix={param.suffix}
									values={param.values}
									maxFreeValue={param.maxFreeValue}
									current={promptState[param.id]}
									invisible={param.invisible}
									updateValue={(value) =>
										setPromptState({
											...promptState,
											[param.id]: value,
										})
									}
								/>
							)
						case "checkbox":
							return (
								<>
									<ConfigCheckbox
										key={param.id}
										id={param.id}
										icon_light={param.icon_light}
										icon_dark={param.icon_dark}
										name={param.name_en}
										default_value={param.defaultValue}
										promptState={promptState}
										setPromptState={setPromptState}
									/>
									<div className="flex-1 border-b border-blue-200 last:hidden"></div>
								</>
							)
						case "select":
							return <>Aystex karox e linel dzer govazdy</>
					}
				})}
			</div>
		</>
	)
}
