import { devices } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { contextAwareTime } from "@/utils/millions"
import queryfy from "@/utils/queryfy"
import {
  flattenAndFilterComments,
  flattenAndFilterGeneralComments,
  getGeneralNextPage,
  TutorialCommentPage,
  TutorialGeneralCommentPage,
} from "@/utils/scroll-context"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useMemo, useState } from "react"
import { useInfiniteQuery } from "react-query"
import styled from "styled-components"
import * as TutorialComments from "types/endpoints/tutorial-comments"
import * as TutorialReplies from "types/endpoints/tutorial-comments-replies"
import { CustomThemedResource } from "../image"
import { MarkupTaggedSlice } from "../player"
import { CustomSkeleton, SmallLoading } from "../skeleton"

type CommentFetcher = (props: {
  pageParam?: number
}) => Promise<TutorialCommentPage>

const commentsOnEachRequest = 10
const getCommentFetcher =
  (template: string, token: string): CommentFetcher =>
  async ({ pageParam }) =>
    await axios
      .get<TutorialComments.Response>(
        "/api/tutorial-comments" +
          queryfy({
            offset: pageParam ?? 0,
            limit: commentsOnEachRequest,
            id: template,
          }),
        {
          headers: {
            Authorization: "Bearer " + token,
            "web-version": HEADER_WEB_VERSION,
          },
        },
      )
      .then((res) => ({
        data: res.data.comments,
        next: getGeneralNextPage(
          res.data.comments.length,
          pageParam,
        ),
      }))

type ReplyFetcher = (_: {
  pageParam?: number
}) => Promise<TutorialGeneralCommentPage>

const getReplyFetcher =
  (
    template: string,
    comment: number,
    token: string,
  ): ReplyFetcher =>
  async ({ pageParam }) =>
    await axios
      .get<TutorialReplies.Response>(
        "/api/tutorial-comments-replies" +
          queryfy({
            offset: pageParam ?? 0,
            limit: commentsOnEachRequest,
            tutorial: template,
            comment,
          }),
        {
          headers: {
            Authorization: "Bearer " + token,
            "web-version": HEADER_WEB_VERSION,
          },
        },
      )
      .then((res) => ({
        data: res.data.replies,
        next: getGeneralNextPage(
          res.data.replies.length,
          pageParam,
        ),
      }))

const CommentContainer = styled.div<{ reply: boolean }>`
  display: flex;
  flex-direction: row;

  gap: 16px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`

const NameContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`

const ProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid var(--color-separator);
`

const ProfilePictureAnchor = styled.a`
  line-height: 0;
  font-size: 0;
  flex-shrink: 0;
`

const CreatorIndicator = styled.span`
  background-color: #009bbd;
  padding: 5px 8px;
  text-transform: uppercase;
  border-radius: 3px;

  color: var(--color-background-cell);
  font-weight: 700;
  font-size: 12px;
  line-height: 10px;
`

const CommentText = styled.span``

const InfoRow = styled.div`
  display: flex;
  gap: 12px;
`

const InfoText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--color-blue-700);
`

const CommentReplies = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 56px;
`

const AllCommentsContainer = styled.div`
  width: 100%;
  height: 500px;
  max-height: 100vh;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--color-background-cell);
  border-radius: 12px 12px 0px 0px;

  padding: 16px;

  overflow-y: scroll;
  overflow-x: hidden;

  @media ${devices.desktop} {
    border-top: 1px solid var(--color-separator);

    padding: 16px 0 0 0;
    overflow: hidden;
    width: unset;
    height: unset;
    max-height: unset;
    border-radius: unset;
  }
`

const AllComments = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 16px;
`

const LoadMoreButton = styled.button<{ align: boolean }>`
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;

  align-self: flex-start;
  padding: 4px 8px;

  ${(props) => props.align && "margin-left: -8px;"};

  border-radius: 4px;
  color: var(--color-blue-600);

  transition: background-color 200ms ease-in-out;
  :hover {
    background-color: var(--color-025);
  }
`

const CommentCount = styled.span`
  align-self: stretch;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: var(--color-blue-700);

  position: relative;
  text-transform: uppercase;

  @media ${devices.desktop} {
    display: none;
  }
`

type SingleCommentProps =
  | {
      template: string
      creator: string
      reply: true
      comment: TutorialComments.TutorialGeneralComment
    }
  | {
      template: string
      creator: string
      reply: false
      comment: TutorialComments.TutorialComment
    }

const SingleComment = (props: SingleCommentProps) => {
  const { reply, comment, creator, template } = props
  const { userInfo } = useAuth()

  const [opened, setOpened] = useState(false)

  const enabled = !reply && comment.replies > 0 && opened

  const query = useInfiniteQuery({
    queryFn: getReplyFetcher(
      template,
      comment.id,
      userInfo.accessToken,
    ),
    queryKey: "replies/" + template + comment.id,
    getNextPageParam: (page) => page.next,
    enabled,
  })

  const replies = useMemo(
    () =>
      flattenAndFilterGeneralComments(
        query.data?.pages ?? [],
      ),
    [query],
  )

  const { t } = useTranslation("common")

  const isCreator = comment.user.username === creator

  const time = contextAwareTime(comment.time)
  const repliesToLoad =
    !reply && comment.replies - replies.length

  const showReplies = !reply && comment.replies > 0
  const showLoading = query.isFetchingNextPage
  const existsMore =
    query.hasNextPage !== false &&
    (typeof repliesToLoad === "number"
      ? repliesToLoad > 0
      : repliesToLoad)
  const canOpen = !opened && !reply && comment.replies > 0
  const showLoadMore = canOpen || existsMore

  return (
    <>
      <CommentContainer reply={reply} data-id={comment.id}>
        <ProfilePictureAnchor
          href={"/" + comment.user.username}>
          <ProfilePicture
            onError={(e) =>
              e.currentTarget.setAttribute(
                "src",
                assetUrl("/icons/avatar-default.svg"),
              )
            }
			alt="profile icon"
            src={
              comment.user.picture ??
              assetUrl("/icons/avatar-default.svg")
            }
          />
        </ProfilePictureAnchor>
        <InfoContainer>
          <NameContainer href={"/" + comment.user.username}>
            <>{comment.user.username}</>
            {isCreator && (
              <CreatorIndicator>
                {t("lbl_creator")}
              </CreatorIndicator>
            )}
          </NameContainer>
          <CommentText>
            <MarkupTaggedSlice tags={comment.text} />
          </CommentText>
          <InfoRow>
            <InfoText>
              {time.locale !== null
                ? t(time.locale, { count: time.text })
                : t(time.text)}
            </InfoText>
            {comment.likes > 0 && (
              <InfoText>
                {t("fs_likes", { count: comment.likes })}
              </InfoText>
            )}
          </InfoRow>
        </InfoContainer>
      </CommentContainer>
      {showReplies && (
        <CommentReplies>
          {replies.map((reply) => (
            <SingleComment
              key={reply.id}
              comment={reply}
              reply={true}
              creator={creator}
              template={template}
            />
          ))}
          {showLoadMore && (
            <div className="flex flex-shrink-0 items-center gap-1">
              <LoadMoreButton
                align
                onClick={() => {
                  query
                    .fetchNextPage()
                    .then(() => !opened && setOpened(true))
                    .catch(console.error)
                }}>
                {opened
                  ? t("txt_view_more")
                  : t("txt_view_replies")}
              </LoadMoreButton>
              {showLoading && <SmallLoading />}
            </div>
          )}
        </CommentReplies>
      )}
    </>
  )
}

const CommentsSkeleton = () => {
  return (
    <div className="flex flex-col items-start justify-start gap-[30px]">
      <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-start justify-start gap-[15px]">
        <CustomSkeleton className="h-10 w-10 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-center justify-start gap-[23px]">
          <div className="relative flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-[7px]">
            <CustomSkeleton className="h-5 w-[30%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[204px]" />
            <CustomSkeleton className="h-5 w-[70%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[327px]" />
            <CustomSkeleton className="h-5 w-[50%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[247px]" />
          </div>
          <CustomSkeleton className="h-5 w-5 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        </div>
      </div>

      <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-start justify-start gap-[15px]">
        <CustomSkeleton className="h-10 w-10 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-center justify-start gap-[23px]">
          <div className="relative flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-[7px]">
            <CustomSkeleton className="h-5 w-[30%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[204px]" />
            <CustomSkeleton className="h-5 w-[70%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[327px]" />
            <CustomSkeleton className="h-5 w-[50%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[247px]" />
          </div>
          <CustomSkeleton className="h-5 w-5 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        </div>
      </div>

      <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-start justify-start gap-[15px]">
        <CustomSkeleton className="h-10 w-10 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        <div className="relative flex w-full flex-shrink-0 flex-grow-0 items-center justify-start gap-[23px]">
          <div className="relative flex w-full flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-[7px]">
            <CustomSkeleton className="h-5 w-[30%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[204px]" />
            <CustomSkeleton className="h-5 w-[70%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[327px]" />
            <CustomSkeleton className="h-5 w-[50%] flex-shrink-0 flex-grow-0 rounded-[30px] desktop:w-[247px]" />
          </div>
          <CustomSkeleton className="h-5 w-5 flex-shrink-0 flex-grow-0 rounded-[30px]" />
        </div>
      </div>
    </div>
  )
}

interface CommentsProps {
  template: string
  count: number
  username: string
  allowed: boolean
  close?: () => void
}

const Comments = (props: CommentsProps) => {
  const { template, count, username, close, allowed } =
    props
  const { userInfo } = useAuth()

  const [clicked, setClicked] = useState(false)

  const { t } = useTranslation("common")

  const query = useInfiniteQuery({
    queryFn: getCommentFetcher(
      template,
      userInfo.accessToken,
    ),
    queryKey: "comments/" + template,
    getNextPageParam: ({ next }) => next,
    enabled: count > 0 && allowed,
  })

  const comments = useMemo(
    () => flattenAndFilterComments(query.data?.pages || []),
    [query.data],
  )

  const commentsToLoad = count - comments.length

  const empty =
    count === 0 ||
    (query.hasNextPage === false && comments.length === 0)
  const showComments = comments.length > 0
  const showSkeleton = !showComments && !query.isError

  const showLoading = query.isFetching
  const showViewMore =
    showComments && query.hasNextPage && commentsToLoad > 0

  if (!allowed || empty) {
    return (
      <AllCommentsContainer>
        <span className="align-self-center relative hidden text-base font-700 uppercase text-blue-700 desktop:block">
          {t("lbl_comments")}
        </span>

        <div className="flex h-full min-h-[300px] w-full flex-col items-center justify-center gap-2 font-600 text-blue-800">
          <CustomThemedResource
            source="/player/comments-off"
            alt="comments are turned off"
            className="h-[120px] w-[120px]"
          />
          {allowed
            ? t("txt_comments_none")
            : t("txt_comments_off")}
        </div>
      </AllCommentsContainer>
    )
  }

  return (
    <AllCommentsContainer>
      <CommentCount>
        {t("fs_comments", { count })}
        {close !== undefined && (
          <img
            src={assetUrl("/player/close.svg")}
            alt="close comments"
            onClick={() => {
              clicked && close()
              setClicked(true)
            }}
            className={clsx(
              "absolute right-0 top-0 h-[20px] w-[20px] p-[4px]",
              "cursor-pointer rounded-full bg-color-black/40 transition-colors hover:bg-color-black/70",
            )}
          />
        )}
      </CommentCount>

      <span className="align-self-center relative hidden text-base font-700 uppercase text-blue-700 desktop:block">
        {t("lbl_comments")}
      </span>

      <AllComments>
        {showComments &&
          comments.map((comment) => (
            <SingleComment
              template={template}
              creator={username}
              comment={comment}
              key={comment.id}
              reply={false}
            />
          ))}
        {showViewMore && (
          <div
            className={clsx("flex items-center gap-2")}
            key={comments.length}>
            <LoadMoreButton
              align={false}
              onClick={() => {
                query.fetchNextPage().catch(console.error)
              }}>
              {t("txt_view_more")}
            </LoadMoreButton>
            {showLoading && <SmallLoading />}
          </div>
        )}
        {showSkeleton && <CommentsSkeleton />}
      </AllComments>
    </AllCommentsContainer>
  )
}

export default Comments
