import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"

import { useEffect, useState } from "react"

type SocialButtonIconProps =
  | {
      dark?: undefined
      light?: undefined
      icon: string
    }
  | {
      dark: string
      light: string
      icon?: undefined
    }

type SocialButtonProps = SocialsData & {
  slug: string
}

type UrlOrOnClick =
  | {
      url: (link: string) => string
      onClick?: undefined
    }
  | {
      onClick: (link: string) => unknown
      url?: undefined
    }

type SocialsData = SocialButtonIconProps &
  UrlOrOnClick & {
    desktop?: (link: string) => string
    className?: string
  }

function SocialButton(props: SocialButtonProps) {
  const { onClick, url, slug, desktop, className } = props

  const imageClasses =
    "hover:bg-color-background transition-colors p-2 desktop:w-full h-full " +
    className

  const anchorClasses =
    "flex overflow-hidden rounded-full pointer-events-auto flex-1"
  const desktopUrl = onClick
    ? undefined
    : desktop
    ? desktop(slug)
    : url && url(slug)

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={desktopUrl}
        onClick={() => onClick && onClick(slug)}
        className={clsx(
          "hidden desktop:block",
          onClick && "cursor-pointer",
          anchorClasses
        )}>
        {props.icon ? (
          <img src={props.icon} alt="props icon" className={imageClasses} />
        ) : (
          <>
            <img
              src={props.dark}
              data-hide-on-theme="light"
			  alt="social dark"
              className={imageClasses}
            />
            <img
              src={props.light}
              data-hide-on-theme="dark"
			  alt="social light"
              className={imageClasses}
            />
          </>
        )}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "desktop:hidden",
          anchorClasses,
          onClick && "cursor-pointer"
        )}
        href={url && url(slug)}>
        {props.icon ? (
          <img src={props.icon} alt="social icon" className={imageClasses} />
        ) : (
          <>
            <img
              src={props.dark}
              data-hide-on-theme="light"
			  alt="social dark"
              className={imageClasses}
            />
            <img
              src={props.light}
			  alt="social light"
              data-hide-on-theme="dark"
              className={imageClasses}
            />
          </>
        )}
      </a>
    </>
  )
}

const socials: SocialsData[] = [
  {
    icon: assetUrl("/comps/facebook-colorful.svg"),
    url: (link) =>
      `https://www.facebook.com/sharer/sharer.php?u=${link}`,
  },
  {
    icon: assetUrl("/comps/messenger-colorful.svg"),
    url: (link) =>
      `fb-messenger://share?link=${link}&app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}}`,
    desktop: (link) =>
      `https://www.facebook.com/dialog/send?link=${link}&app_id=${
        process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
      }&redirect_uri=${encodeURIComponent(
        "https://zoomerang.app"
      )}`,
  },
  {
    icon: assetUrl("/comps/whatsapp-colorful.svg"),
    url: (link) => `https://wa.me/?text=${link}`,
  },
  {
    light: assetUrl("/comps/viber-colorful-light.svg"),
    dark: assetUrl("/comps/viber-colorful-dark.svg"),
    url: (link) => `viber://forward?text=${link}`,
  },
  {
    icon: assetUrl("/comps/pinterest-colorful.svg"),
    url: (link) =>
      `https://pinterest.com/pin/create/button/?url=${link}`,
  },
  {
    icon: assetUrl("/comps/email-colorful.svg"),
    url: (link) => `mailto:?body=${link}`,
  },
  {
    icon: assetUrl("/comps/url-colorful.svg"),
    onClick: async (link) => {
      try {
        await navigator.clipboard.writeText(link)
      } catch {}
    },
  },
]

type BlogSocialsProps = {
  isSmall?: boolean
}

export default function BlogSocials(
  props: BlogSocialsProps
) {
  const { isSmall } = props

  const [link, setLink] = useState("")

  useEffect(() => {
    setLink(location.href)
  }, [])

  return (
    <div
      className={clsx(
        isSmall || [
          "left-1/2 top-[262px] z-50 desktop:fixed",
          "mb-12 flex w-full justify-center desktop:block",
          "pointer-events-none",
        ]
      )}>
      <div
        className={clsx(
          "px-3 py-4 desktop:h-auto desktop:w-[75px] desktop:flex-col",
          "rounded-[10px] tablet:rounded-[18px]",
          "bg-color-cell dark:bg-[#363636]",
          "inline-flex h-[75px] gap-2",
          "desktop:[transform:translateX(-580px)]",
          "shadow-[0px_0px_40px_rgba(0,0,0,0.08)]"
        )}>
        {socials.map((props) => (
          <SocialButton
            {...props}
            slug={link}
            key={props.icon ?? props.light}
          />
        ))}
      </div>
    </div>
  )
}

export function BlogStickySocials() {
  const [link, setLink] = useState("")

  useEffect(() => {
    setLink(location.href)
  }, [])

  return (
    <div
      className={clsx(
        "hidden desktop:sticky desktop:block desktop:max-h-[460px]",
        "top-[80px]",
        "bottom-0"
      )}>
      <div
        className={clsx(
          "related z-20",
          "px-3 py-4 desktop:h-auto desktop:w-[75px] desktop:flex-col",
          "rounded-[18px] bg-color-cell",
          "inline-flex h-[75px] gap-2",
          "desktop:shadow-[0px_0px_40px_rgba(0,0,0,0.08)]"
        )}>
        {socials.map((props) => (
          <SocialButton
            {...props}
            slug={link}
            key={props.icon ?? props.light}
          />
        ))}
      </div>
    </div>
  )
}

export function BlogSocialsHorizontal() {
  const [link, setLink] = useState("")

  useEffect(() => {
    setLink(location.href)
  }, [])

  return (
    <div
      className={clsx(
        "flex w-full flex-row justify-center"
      )}>
      <div
        className={clsx(
          "no-scrollbar w-full overflow-x-scroll desktop:h-auto desktop:flex-row",
          "inline-flex h-[75px] gap-2",
          "shadow-[0px_0px_40px_rgba(0,0,0,0.08)]"
        )}>
        {socials.map((props) => (
          <SocialButton
            {...props}
            slug={link}
            key={props.icon ?? props.light}
            className="h-[46px] w-[46px] bg-color-background"
          />
        ))}
      </div>
    </div>
  )
}
