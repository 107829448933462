import { assetUrl } from "@/utils/cdn"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { withNotify } from "@/utils/trigger"
import { getImageDimensions } from "@/utils/video"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { DeforumImageUrlResponse } from "pages/api/deforum-image-url"
import {
  AllParams,
  PromptOption,
  PromptParams,
} from "pages/api/deforum-params"
import {
  AISubmitRequest,
  AISubmitResponse,
} from "pages/api/deforum-submit"
import { useContext, useMemo, useState } from "react"
import { useQueryClient } from "react-query"
import {
  ChannelsParams,
  DynamicEditorProps,
  useEditorParamsContext,
} from "sections/editor/editor"
import { z } from "zod"
import {
  createPromptStateFrom,
  ParamtersTrigger,
} from "../ai-config"
import { BorderTextareaResizable } from "../border-input"
import Checkbox from "../checkbox"
import { CustomThemedResource } from "../image"
import { ErrorMessage } from "../message"
import { mimeTypes } from "../simple-drop-area"
import { SubscriptionPopup } from "../subscription-popup"
import { ParamsContext } from "./deform"

const remoteStorageSchema = z.object({
  location: z.literal("remote"),
  url: z.string(),
})

const localStorageSchema = z.object({
  location: z.literal("local"),
  blob: z.unknown(),
})

export const DEFORUM_STORAGE_KEY = "last"
export const photoAiStorageSchema = z
  .object({
    tool: z.literal("ai_photo").default("ai_photo"),
    id: z.literal(DEFORUM_STORAGE_KEY),
    promptType: z.string().nullable(),
    input: z.string(),
  })
  .and(z.union([remoteStorageSchema, localStorageSchema]))

type PhotoAiStorageType = z.infer<
  typeof photoAiStorageSchema
>
export const MIN_RESOLUTION = 450
export const MIN_INPUT_CHARACTERS = 3
export default function PhotoAiEditor(props: {
  content: DynamicEditorProps
  params: {
    styles: PromptOption[]
    params: AllParams
  } | null
  channels: ChannelsParams | null
  blob: Blob | null
}) {
  if (props.content.tool !== "ai_photo") {
    throw new Error("You are not in deform editor.")
  }

  if (props.blob === null) {
    throw new Error("No file uploaded.")
  }

  const { content, params: parameters, blob: file } = props

  if (parameters === null) {
    throw new Error("Parameters for filters are null.")
  }

  const [uploadedUrl, setUploadedUrl] = useState<string>()

  const editor = useEditorParamsContext()
  const { styles, params } = parameters

  const [input, setInput] = useState<string>(content.input)
  const [promptType, setPromptType] = useState<
    string | null
  >(content.promptType)

  const fileType = useMemo(() => getFileType(file), [file])

  const { t } = useTranslation()

  const { notify } = useContext(NotificationContext)
  const [keepFace, setKeepFace] = useState(true)

  const { userInfo } = useAuth()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )

  const queryClient = useQueryClient()

  const router = useRouter()

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const [actionLoading, setActionLoading] = useState(false)

  const initialConfig = createPromptStateFrom(params.params)

  const sectionsConfig = params.sections
    ? params.sections.map((section) =>
        createPromptStateFrom(section.components),
      )
    : null

  const config = sectionsConfig
    ? sectionsConfig.reduce((acc, section) => {
        return section ? { ...acc, ...section } : acc
      }, initialConfig || {})
    : initialConfig || {}

  const [promptState, setPromptState] = useState(config)

  const indexed = useIndexedDB(
    "ai_art",
    "ai_photo",
    photoAiStorageSchema,
  )

  async function addToStorage() {
    if (indexed.status !== "ready") {
      console.error("Indexed is not ready")
      return
    }

    const payload: PhotoAiStorageType = {
      tool: "ai_photo",
      id: DEFORUM_STORAGE_KEY,
      input,
      promptType,
      location: "local",
      blob: file,
    }

    await indexed.setData(payload)
  }

  async function submitData() {
    if (actionLoading) {
      return
    }

    try {
      setActionLoading(true)

      let url = uploadedUrl

      if (!url) {
        const { upload, download, contentType } =
          await axios
            .post<DeforumImageUrlResponse>(
              "/api/deforum-image-url",
              { content: file.type },
            )
            .then((res) => res.data)

        await axios.put(upload, file, {
          headers: {
            "Content-Type": contentType,
            "web-version": HEADER_WEB_VERSION,
          },
        })
        url = download
        setUploadedUrl(download)
      }

      let res = null
      if (promptState && promptState["resolution"])
        res = Number(promptState["resolution"])

      const resolution = res ?? MIN_RESOLUTION

      const dimensions = await getImageDimensions(file)

      const changedDimensions = resizeDimensions(
        dimensions,
        resolution,
      )

      let face = null
      if (!isKeepFaceNotAllowed && keepFace) face = url

      const submitData: AISubmitRequest = {
        url,
        style: promptType,
        additional: input,
        tool: "ai_photo",
        params: promptState ?? { resolution: 720 },
        face_object_url: face,
        ...changedDimensions,
      }

      await axios
        .post<AISubmitResponse>(
          "/api/deforum-submit",
          submitData,
        )
        .then((res) => res.data)

      setActionLoading(false)
      window.removeEventListener(
        "beforeunload",
        handleLeave,
      )

      queryClient.invalidateQueries("coins")

      await router.push("/profile/generations/all")
      editor.closePage()
    } catch (error) {
      const notifier = withNotify((t) =>
        notify(<ErrorMessage>{t}</ErrorMessage>),
      )

      notifier(error)

      console.error(error)

      setSubscriptionPopupOpen(false)
      setActionLoading(false)
    }
  }

  const isKeepFaceNotAllowed = styles.find(
    (style) => style.id === promptType,
  )?.ignoreFace
  const isFirstInputCorrect =
    input.length >= MIN_INPUT_CHARACTERS
  const canSubmit =
    promptType !== null || isFirstInputCorrect

  return (
    <ParamsContext.Provider value={params}>
      <div className="flex w-full justify-center overflow-y-scroll">
        <div className="mx-auto my-[24px] flex w-[1175] gap-[25px]">
          <div className="w-[calc(70vh/16*9)] min-w-[300px] max-w-[575px]">
            <PhotoPreview
              file={file}
              closePage={async () => {
                window.removeEventListener(
                  "beforeunload",
                  handleLeave,
                )
                if (router.asPath === "/editor/photo-ai") {
                  router.back()
                }
                editor.closePage()
              }}
            />
          </div>
          <div className="flex w-[575px] flex-col gap-3">
            <div
              className={clsx(
                "flex flex-col items-stretch gap-[22px] rounded-[15px] bg-color-cell px-6 pb-[22px] pt-[16px] transition-all",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              <div className="relative flex flex-col gap-2">
                <span className="text-[16px] font-700 text-blue-600">
                  {t("lbl_styles")}
                </span>
                <div className="absolute bottom-0 right-0 z-10 h-3 w-full bg-gradient-to-t from-color-cell to-color-white/0"></div>
                <div className="no-scrollbar grid max-h-[340px] grid-cols-3 gap-2 overflow-y-scroll pb-3">
                  <div
                    className={clsx(
                      "relative h-[122px] overflow-hidden rounded-[8px]",
                      promptType === null
                        ? "border-primary-500 bg-[#FF31661F]"
                        : "bg-blue-100",
                      "select-none border-2 border-[transparent]",
                      "cursor-pointer transition-colors",
                      "flex flex-col items-center justify-center gap-1",
                    )}
                    onClick={() => setPromptType(null)}>
                    <CustomThemedResource
                      format="svg"
                      source="/general/custom-prompt"
                      alt="custom prompt"
                    />
                    <span className="font-600 text-blue-800">
                      {t("lbl_custom")}
                    </span>
                  </div>
                  {styles.map(({ name, id, image }) => (
                    <div
                      key={id}
                      className={clsx(
                        "relative h-[122px] overflow-hidden rounded-[8px]",
                        promptType === id &&
                          "border-primary-500",
                        "select-none border-2 border-[transparent]",
                        "cursor-pointer transition-colors",
                      )}
                      onClick={() => setPromptType(id)}>
                      <img
                        src={image}
                        alt={`cover of ${name} style`}
                        className={clsx(
                          "pointer-events-none h-full w-full object-cover",
                        )}
                      />
                      <div className="absolute bottom-0 left-0 h-8 w-full bg-gradient-to-t from-color-black/60 to-[transparent]"></div>
                      <span className="absolute bottom-1 left-2 text-[13px] font-600 text-color-white">
                        {name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <span className="text-[16px] font-700 text-blue-600">
                  {t("lbl_prompts")}
                </span>
                <div
                  className={clsx(
                    "no-scrollbar flex max-h-[312px] shrink-0 flex-col items-stretch gap-3 overflow-y-scroll transition-all",
                    actionLoading &&
                      "pointer-events-none opacity-30",
                  )}>
                  <div className="flex flex-col items-stretch gap-3">
                    <div className={clsx("relative")}>
                      <BorderTextareaResizable
                        placeholder={t(
                          "txt_prompt_placeholder_additional",
                        )}
                        setValue={setInput}
                        defaultValue={input}
                        className="group inline-block w-full resize-none !pr-10"
                        autoCorrect="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={clsx(
                "flex flex-row items-center justify-between rounded-[14px] bg-color-cell py-[22px] pl-3 pr-[22px] transition-opacity",
                isKeepFaceNotAllowed &&
                  "pointer-events-none opacity-30",
                actionLoading &&
                  "pointer-events-none opacity-30",
              )}>
              <label
                htmlFor="keep_face_check"
                className="flex cursor-pointer select-none flex-row items-center gap-3 [--icon-color:var(--color-blue-500)]">
                <FaceIcon />
                <p className="text-[18px] font-500 text-blue-600">
                  {t("txt_keep_face")}
                </p>
              </label>
              <Checkbox
                id="keep_face_check"
                checked={keepFace}
                onClick={() => {
                  setKeepFace(!keepFace)
                }}
              />
            </div>

            {promptState && (
              <ParamtersTrigger
                actionLoading={actionLoading}
                promptState={promptState}
                setPromptState={setPromptState}
              />
            )}

            {isPro ? (
              <button
                disabled={
                  fileType === "unrecognized" || !canSubmit
                }
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "disabled:pointer-events-none",
                  "bg-primary-500 hover:bg-primary-600",
                  "relative py-3 transition-colors",
                  "flex items-center justify-center gap-4",
                  actionLoading && "pointer-events-none",
                )}
                onClick={() => {
                  if (actionLoading) return
                  submitData()
                }}>
                <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2">
                  <img
                    className={clsx(
                      "h-6 w-6 animate-[spin_1s_infinite_linear]",
                      actionLoading
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                    src={assetUrl(
                      "/general/loading-dark.webp",
                    )}
                    alt="loading"
                  />
                </div>

                {t("lbl_generate")}
                {promptState && params.params && (
                  <CostsCoins
                    coins={getPromptPrice(
                      promptState,
                      params.params,
                      params.price,
                    )}
                  />
                )}
              </button>
            ) : (
              <button
                disabled={fileType === "unrecognized"}
                className={clsx(
                  "mt-[15px] rounded-[10px] font-500",
                  "text-[16px] text-color-white disabled:opacity-50",
                  "disabled:pointer-events-none",
                  "bg-[#2F91FD] hover:bg-[#0264CF]",
                  "py-3 transition-colors",
                )}
                onClick={() =>
                  setSubscriptionPopupOpen(true)
                }>
                {t("txt_subscribe_to_continue")}
              </button>
            )}
            <div className="h-[70px] w-full shrink-0"></div>
          </div>
        </div>
        <SubscriptionPopup
          isOpen={subscriptionPopupOpen}
          close={() => setSubscriptionPopupOpen(false)}
          location="/editor/photo-ai"
          addToStorage={addToStorage}
        />
      </div>
    </ParamsContext.Provider>
  )
}

type FileType = "image" | "video" | "unrecognized"
function getFileType(file: Blob): FileType {
  if (file.type.startsWith("video")) {
    return "video"
  }

  if (file.type.startsWith("image")) {
    return "image"
  }

  return "unrecognized"
}

function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

interface MediaPreviewProps {
  file: Blob
  closePage: () => void
}

function PhotoPreview(props: MediaPreviewProps) {
  const { file, closePage } = props

  const imageUrl = useMemo(
    () => URL.createObjectURL(file),
    [file],
  )

  return (
    <div className="flex flex-col gap-3">
      <div className="relative w-full overflow-hidden rounded-[16px] bg-blue-100">
        <button
          className="group absolute left-2 top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-blue-300 hover:bg-blue-400"
          onClick={closePage}>
          <img
            className="group-hover:hidden"
			alt="close circle icon"
            src={assetUrl("/general/close-in-circle.svg")}
          />
          <img
            className="hidden group-hover:block dark:hidden dark:group-hover:hidden"
			alt="circle hover icon"
            src={assetUrl(
              "/general/close-in-circle-hover.svg",
            )}
          />
          <img
            className="hidden dark:hidden dark:group-hover:block"
			alt="circle dark"
            src={assetUrl(
              "/general/close-in-circle-hover-dark.svg",
            )}
          />
        </button>
        <img
          src={imageUrl}
		  alt="image"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  )
}

export const FaceIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.7505 13.7358L14.5704 12.9307C14.2943 12.9925 14.0692 13.1913 13.9738 13.4577L14.7505 13.7358ZM3.61789 13.7002L4.39591 13.4258C4.30985 13.1818 4.1143 12.9923 3.86771 12.9139L3.61789 13.7002ZM2.56909 7.59516L2.89406 8.35346C3.17536 8.23291 3.36682 7.96653 3.39142 7.66147L2.56909 7.59516ZM15.931 7.59519L15.1087 7.6615C15.1333 7.96655 15.3247 8.23292 15.606 8.35348L15.931 7.59519ZM13.9738 13.4577C13.0167 16.1301 11.6576 17.975 9.25005 17.975V19.625C12.8076 19.625 14.5379 16.7762 15.5272 14.014L13.9738 13.4577ZM9.25005 17.975C6.83783 17.975 5.33321 16.0831 4.39591 13.4258L2.83987 13.9746C3.83017 16.7822 5.71936 19.625 9.25005 19.625V17.975ZM2.24413 6.83685C1.41813 7.19083 0.951739 7.88621 0.744793 8.63443C0.542481 9.36589 0.572475 10.1829 0.73605 10.9378C0.900726 11.6979 1.21332 12.4544 1.63844 13.0786C2.05538 13.6908 2.63546 14.2537 3.36807 14.4865L3.86771 12.9139C3.61896 12.8349 3.30555 12.5952 3.0022 12.1498C2.70703 11.7164 2.47253 11.1603 2.34863 10.5884C2.22364 10.0115 2.22359 9.4774 2.33509 9.07428C2.44195 8.68792 2.63321 8.46524 2.89406 8.35346L2.24413 6.83685ZM3.39142 7.66147C3.64833 4.47578 6.20038 2.02501 9.25005 2.02501V0.375012C5.27721 0.375012 2.06758 3.5507 1.74676 7.52884L3.39142 7.66147ZM9.25005 2.02501C12.2997 2.02501 14.8518 4.4758 15.1087 7.6615L16.7533 7.52888C16.4325 3.55072 13.2229 0.375012 9.25005 0.375012V2.02501ZM15.606 8.35348C15.8717 8.46736 16.0668 8.69749 16.172 9.10065C16.2815 9.52023 16.2726 10.0737 16.1344 10.6637C15.9974 11.249 15.747 11.8094 15.4392 12.2331C15.1221 12.6696 14.8078 12.8776 14.5704 12.9307L14.9306 14.5409C15.7115 14.3662 16.3317 13.8118 16.7741 13.203C17.2257 12.5814 17.5598 11.8137 17.741 11.0399C17.9211 10.2707 17.9643 9.43389 17.7686 8.68401C17.5686 7.91771 17.1012 7.19914 16.256 6.8369L15.606 8.35348Z"
          fill="var(--icon-color)"
        />
      </svg>
    </>
  )
}

export interface PromptState {
  [id: string]: number | string | boolean
}

export function getPromptPrice(
  prompt: PromptState,
  params: PromptParams[],
  non_free_price: number,
) {
  for (const param of params) {
    const promptID = prompt[param.id]
    if (typeof promptID !== "number") {
      continue
    }

    if (param.type !== "slider") {
      continue
    }

    if (promptID > param.maxFreeValue) {
      return non_free_price
    }
  }

  return 0
}

export function CostsCoins(props: { coins: number }) {
  const { coins } = props
  const { userInfo } = useAuth()
  const { isCoinFree } = getUserEntitlements(
    userInfo.entitlements,
  )
  if (isCoinFree || !coins) {
    return <></>
  }

  return (
    <div className="flex gap-1 rounded-full bg-[#C6113F] px-1 text-[14px] font-700 text-color-white">
      +{coins}
      <img
        src={assetUrl("/general/coin.svg")}
        alt="coins"
      />
    </div>
  )
}

export interface MediaDimensions {
  width: number
  height: number
}

export function resizeDimensions(
  dimensions: MediaDimensions,
  max: number,
): MediaDimensions {
  if (Math.max(dimensions.width, dimensions.height) < max) {
    return dimensions
  }

  if (dimensions.width > dimensions.height) {
    return {
      width: Math.floor(
        (max / dimensions.height) * dimensions.width,
      ),
      height: max,
    }
  }

  return {
    width: max,
    height: Math.floor(
      (max / dimensions.width) * dimensions.height,
    ),
  }
}

const actionMimes = {
  deform: ["images", "videos"],
  restyle: ["videos"],
  photo_ai: ["images"],
} as const

export function getAcceptedMimes(): string {
  const mimes = [...actionMimes["photo_ai"]]
  const empty: string[] = []
  const allMimes = mimes.reduce(
    (all, single) => [...all, ...mimeTypes[single]],
    empty,
  )

  return allMimes.join(", ")
}
