import {
  ArrowIcon,
  Email,
  Facebook,
  Messenger,
  Pinterest,
  URLIcon,
  Viber,
  WhatsApp,
} from "@/comps/template/icons"
import { devices } from "@/utils/breakpoints"
import { getFormattedDate } from "@/utils/dates"
import prettyMillions from "@/utils/millions"
import { useTranslation } from "next-i18next"

import QRCode from "qrcode.react"
import { useState } from "react"
import styled from "styled-components"

import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import { TutorialReportRequest } from "pages/api/tutorial-report"
import { ReportPopup } from "sections/history/report-popup"
import {
  TaggedTextSlice,
  TutorialTemplate,
} from "types/endpoints/tutorial"
import { FollowStatus } from "types/endpoints/user-profile"
import { FollowButton } from "../advanced-profile"
import { moreIcon } from "../ai-tools/icons"
import { CustomThemedResource } from "../image"
import Comments from "./comments"

const TemplateName = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`

const DescriptionContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  color: var(--color-blue-700);

  display: flex;
  flex-wrap: wrap;
`

export const Hashtag = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-right: 4px;
`

export const Username = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-right: 4px;

  cursor: pointer;

  border-bottom: 1px solid transparent;

  transition: border-bottom-color 200ms ease-in-out;

  &:hover {
    border-bottom: 1px solid var(--color-blue-900);
  }
`

export const Word = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  white-space: pre-wrap;
`

interface TemplateDescriptionProps {
  description: TaggedTextSlice[]
}

const TemplateDescription = (
  props: TemplateDescriptionProps,
) => {
  return (
    <DescriptionContainer>
      {props.description.map((tag, index) => {
        switch (tag.format) {
          case "username":
            return (
              <Username
                key={index}
                href={"/user/" + tag.uid}>
                @{tag.label}
              </Username>
            )
          case "hashtag":
            return (
              <Hashtag key={index}>#{tag.content} </Hashtag>
            )
          default:
            return <Word key={index}>{tag.content}</Word>
        }
      })}
    </DescriptionContainer>
  )
}

const TemplateDate = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--color-blue-600);
`

const UseTemplateContainer = styled.div<{ show: boolean }>`
  display: none;

  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 16px;

    padding: 16px 0;
    margin-bottom: 16px;

    border-top: 1px solid var(--color-separator);
    border-bottom: 1px solid var(--color-separator);

    overflow: hidden;

    max-height: ${(props) =>
      props.show ? "200px" : "58px"};
    transition: max-height 200ms ease-in-out;
  }

  svg {
    flex-shrink: 0;
  }
`

const QrContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  align-self: stretch;
`

const UseTemplateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;
`

const UseTemplateLabel = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  text-transform: uppercase;

  color: var(--color-blue-700);
`

const StepsContainer = styled.ol`
  max-width: 329px;

  @media ${devices.desktop} {
    max-width: 270px;
  }
`

const Step = styled.li`
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;

  color: var(--color-blue-800);
`

const ScanIconContainer = styled.svg`
  display: inline-block;

  & path {
    stroke: var(--color-blue-800);
  }
`

const ScanIcon = () => (
  <ScanIconContainer
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0195 11H3.51953"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.436 3.66602H16.1027C17.7596 3.66602 19.1027 5.00916 19.1027 6.66602V7.33268"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.10303 18.334L7.43636 18.334C5.77951 18.334 4.43636 16.9908 4.43636 15.334L4.43636 14.6673"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1027 14.666V15.3327C19.1027 16.9895 17.7596 18.3327 16.1027 18.3327H15.436"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.43636 7.33398L4.43636 6.66732C4.43636 5.01046 5.77951 3.66732 7.43636 3.66732L8.10303 3.66732"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </ScanIconContainer>
)

const ShareTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  text-transform: uppercase;
  color: var(--color-blue-700);

  display: none;
  @media ${devices.desktop} {
    display: block;
  }
`

const ShareMobile = styled.span`
  font-size: 18px;
  line-height: 21px;

  align-self: center;
  margin-top: 16px;

  font-weight: 600;
  color: var(--color-blue-400);

  b {
    font-weight: 700;
    color: var(--color-blue-800);
  }

  @media ${devices.desktop} {
    display: none;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  max-width: 646px;
  background-color: var(--color-separator);

  @media ${devices.desktop} {
    display: none;
  }
`

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-shrink: 0;

  overflow-x: scroll;

  max-width: var(--w-screen);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  padding: 0 16px 16px 16px;

  @media ${devices.desktop} {
    margin: 0 -16px;
  }
`

const PanelScrollable = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  padding: 12px 0;

  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  height: calc(100vh - 82px);
`

export const ShareRow = ({
  share,
  name,
}: {
  share: string
  name?: string
}) => {
  const { t } = useTranslation("common")

  return (
    <div className="flex flex-col gap-[16px] rounded-t-[14px] bg-color-cell">
      <ShareTitle>{t("txt_share_on")}</ShareTitle>
      <ShareMobile>
        {t("lbl_share")} <b>{name}</b>
      </ShareMobile>
      <Divider />
      <SocialRow>
        <URLIcon url={share} index={0} />
        <Facebook
          url={encodeURIComponent(share)}
          index={1}
        />
        <Messenger
          url={encodeURIComponent(share)}
          index={2}
        />
        <WhatsApp
          url={encodeURIComponent(share)}
          index={3}
        />
        <Viber url={encodeURIComponent(share)} index={4} />
        <Pinterest
          url={encodeURIComponent(share)}
          index={5}
        />
        <Email url={encodeURIComponent(share)} index={6} />
      </SocialRow>
    </div>
  )
}

const CategoryIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33337 3.83398H2.35226"
      stroke="var(--color-blue-900)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6516 13.168L5.60556 13.168M5.60581 8.5013L13.6516 8.5013M5.60556 3.83464L13.6516 3.83464"
      stroke="var(--color-blue-900)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M2.33337 8.5H2.35226"
      stroke="var(--color-blue-900)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33337 13.168H2.35226"
      stroke="var(--color-blue-900)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const AuthorContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 6px;
`

const AuthorPicture = styled.img`
  border-radius: 100%;
  border-color: var(--color-separator);
  border-width: 1px;
  width: 40px;
  height: 40px;
`

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const AuthorName = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
`

const AuthorFollowers = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--color-blue-600);
`
const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface SidePanelProps {
  template: TutorialTemplate
  closeFn: () => void
}

const SidePanel = (props: SidePanelProps) => {
  const {
    name,
    description,
    created,
    share,
    creator,
    category,
    id,
    categoryId,
  } = props.template

  const { closeFn } = props

  const { userInfo } = useAuth()
  const { uid, follow_status } = creator
  const [show, setShow] = useState(true)
  const isSelf = userInfo.userId === creator.uid
  const date = getFormattedDate(created)
  const [reportPopup, setReportPopup] = useState(false)
  const [followStatus, setFollowStatus] =
    useState<FollowStatus | null>(
      (follow_status as FollowStatus) ?? null,
    )
  const [payload, setPayload] =
    useState<TutorialReportRequest>({
      tid: id,
      info: {
        reason: "",
        message: "",
      },
    })

  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        "hidden bg-color-cell text-blue-900 desktop:flex",
        "absolute right-0 h-full flex-col items-center",
        "self-stretch justify-self-end p-[8px]",
      )}>
      <div className="m-[16px] flex flex-col tablet:w-[478px] desktop:w-[425px]">
        <div className="flex w-full items-center justify-between border-b border-color-separator pb-[16px]">
          <AuthorContainer href={"/" + creator.username}>
            <AuthorPicture
              onError={(e) =>
                e.currentTarget.setAttribute(
                  "src",
                  assetUrl("/icons/avatar-default.svg"),
                )
              }
			  alt="author picture"
              src={
                creator.picture ??
                assetUrl("/icons/avatar-default.svg")
              }
            />
            <AuthorInfo>
              <AuthorName>{creator.username}</AuthorName>
              <AuthorFollowers>
                {prettyMillions(creator.followers)}{" "}
                {t("common:lbl_followers")}
              </AuthorFollowers>
            </AuthorInfo>
          </AuthorContainer>
          <div
            className={clsx(
              isSelf && "hidden",
              "flex items-center gap-[11px]",
            )}>
            <div
              className={clsx(
                isSelf && "hidden",
                "flex h-[44px] items-center justify-center tablet:w-[183px]",
                "w-[132px] overflow-hidden rounded-[6px]",
              )}>
              <FollowButton
                followStatus={
                  followStatus ?? "not_following"
                }
                uid={uid}
                setFollowStatus={setFollowStatus}
                userPage={false}
              />
            </div>
            <div
              className={clsx(
                "group relative cursor-pointer",
                "-mb-[30px] pb-[30px]",
              )}>
              <div
                className={clsx(
                  "h-[44px] w-[44px] [--icon-color:var(--color-blue-900)] hover:bg-blue-100",
                  "flex items-center justify-center rounded-md",
                )}>
                {moreIcon()}
              </div>
              <button
                onClick={() => {
                  setReportPopup(true)
                }}
                className={clsx(
                  "fixed hidden w-[123px] rounded-[12px] px-[10px] py-[8px] group-hover:flex",
                  "z-[200] items-center gap-[8px] shadow-xl hover:bg-blue-100",
                  "right-[20px] top-[740px] bg-color-popup-cell desktop:top-[80px]",
                )}>
                <CustomThemedResource
                  source="/general/report"
                  format="svg"
                />
                <span className="text-[16px] font-500 text-blue-800">
                  {t("txt_report")}
                </span>
              </button>
              {reportPopup && setReportPopup && (
                <ReportPopup
                  closeFn={closeFn}
                  payload={payload}
                  setReportPopup={setReportPopup}
                  payloadLocation="tutorial"
                  setPayload={setPayload}
                  endpoint="tutorial-report"
                  galleryOrTemplate="template"
                  className="absolute -right-1 top-[55px] z-[201]"
                />
              )}
            </div>
          </div>
        </div>
        <PanelScrollable>
          <TemplateName>{name}</TemplateName>
          <TemplateDescription description={description} />
          {category !== null && (
            <a
              href={`/video-templates?categoryId=${categoryId}`}
              className="flex items-center gap-1 text-base text-blue-900">
              <CategoryIcon />
              {category}
            </a>
          )}
          <TemplateDate>{date}</TemplateDate>
          {share && (
            <ShareContainer>
              <UseTemplateContainer show={show}>
                <UseTemplateRow>
                  <UseTemplateLabel>
                    {t("txt_use_template")}
                  </UseTemplateLabel>
                  <ArrowIcon
                    show={show}
                    onClick={() => setShow(!show)}
                  />
                </UseTemplateRow>
                <QrContainer>
                  <QRCode
                    size={100}
                    bgColor="#ecedef00"
                    fgColor="#ff3166"
                    value={share}
                    renderAs={"canvas"}
                  />
                  <StepsContainer>
                    <Step>
                      1. {t("txt_open_zoomerang_app")}
                    </Step>
                    <Step>
                      2. {t("txt_on_profile_tap")}{" "}
                      <ScanIcon />
                      {" " /* required */}
                      {t("txt_and_scan_qr")}
                    </Step>
                  </StepsContainer>
                </QrContainer>
              </UseTemplateContainer>
            </ShareContainer>
          )}
          {share && <ShareRow share={share} />}
          <Comments
            username={props.template.creator.username}
            template={props.template.id}
            count={props.template.comments}
            allowed={props.template.allowComments}
          />
        </PanelScrollable>
      </div>
    </div>
  )
}

export default SidePanel
