import { PromptOption, PromptParams } from "@/ssr/ai-tools"
import { assetUrl } from "@/utils/cdn"
import useAuth, {
  getUserEntitlements,
} from "@/utils/client-auth"

import { HEADER_WEB_VERSION } from "@/utils/cdn"
import { useIndexedDB } from "@/utils/indexed"
import { NotificationContext } from "@/utils/notification"
import { withNotify } from "@/utils/trigger"
import { getVideoDurationMillis } from "@/utils/video"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { AllParams } from "pages/api/deforum-params"
import {
  AISubmitRequest,
  AISubmitResponse,
} from "pages/api/deforum-submit"
import { DeforumVideoUrlResponse } from "pages/api/deforum-video-url"
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useQueryClient } from "react-query"
import {
  ChannelsParams,
  DynamicEditorProps,
  useEditorParamsContext,
} from "sections/editor/editor"
import { VideoProvider } from "video-provider"
import { z } from "zod"
import {
  ClipVideoSector,
  createPromptStateFrom,
  MAX_FREE_MILLIS,
  ParamtersTrigger,
  useVideoClipper,
  UseVideoClipperResult,
} from "../ai-config"
import { BorderTextareaResizable } from "../border-input"
import { CustomThemedResource } from "../image"
import { ErrorMessage } from "../message"
import { PlayWithProvider } from "../player"
import { mimeTypes } from "../simple-drop-area"
import { SubscriptionPopup } from "../subscription-popup"
import { ParamsContext } from "./deform"

const actionMimes = {
  deform: ["images", "videos"],
  restyle: ["videos"],
  photo_ai: ["images"],
} as const

export function getAcceptedMimes(): string {
  const mimes = [...actionMimes["restyle"]]
  const empty: string[] = []
  const allMimes = mimes.reduce(
    (all, single) => [...all, ...mimeTypes[single]],
    empty,
  )

  return allMimes.join(", ")
}

const ControllerContext =
  createContext<UseVideoClipperResult | null>(null)
const DurationContext = createContext<number | null>(null)

const remoteStorageSchema = z.object({
  location: z.literal("remote"),
  url: z.string(),
})

const localStorageSchema = z.object({
  location: z.literal("local"),
  blob: z.unknown(),
})

const DEFORUM_STORAGE_KEY = "last"
export const restyleStorageSchema = z
  .object({
    tool: z.literal("ai_restyle").default("ai_restyle"),
    id: z.literal(DEFORUM_STORAGE_KEY),
    promptType: z.string().nullable(),
    input: z.string(),
  })
  .and(z.union([remoteStorageSchema, localStorageSchema]))

type RestyleStorageType = z.infer<
  typeof restyleStorageSchema
>

export const MIN_INPUT_CHARACTERS = 3
export const MIN_RESOLUTION = 450
export default function RestyleEditor(props: {
  content: DynamicEditorProps
  params: {
    styles: PromptOption[]
    params: AllParams
  } | null
  channels: ChannelsParams | null
  blob: Blob | null
}) {
  if (props.content.tool !== "ai_restyle") {
    throw new Error("You are not in restyle editor.")
  }

  if (props.blob === null) {
    throw new Error("No file uploaded.")
  }

  const { content, params: parameters, blob: file } = props

  if (parameters === null) {
    throw new Error("Parameters for filters are null")
  }

  const [uploadedUrl, setUploadedUrl] = useState<string>()

  const editor = useEditorParamsContext()
  const { styles, params } = parameters

  const controller = useVideoClipper({
    file,
  })
  const fileType = useMemo(() => getFileType(file), [file])

  const indexed = useIndexedDB(
    "ai_art",
    "ai_restyle",
    restyleStorageSchema,
  )

  const [input, setInput] = useState<string>(content.input)
  const [promptType, setPromptType] = useState<
    string | null
  >(content.promptType)

  const { t } = useTranslation()

  const { notify } = useContext(NotificationContext)

  const queryClient = useQueryClient()

  const { userInfo } = useAuth()
  const { isPro } = getUserEntitlements(
    userInfo.entitlements,
  )

  const isFirstInputCorrect =
    input.length >= MIN_INPUT_CHARACTERS
  const canSubmit =
    promptType !== null || isFirstInputCorrect

  const [duration, setDuration] = useState<number | null>(
    null,
  )

  useEffect(() => {
    if (duration !== null) {
      return
    }

    getVideoDurationMillis(file).then(setDuration)
  }, [duration, file])

  const initialConfig = createPromptStateFrom(params.params)

  const sectionsConfig = params.sections
    ? params.sections.map((section) =>
        createPromptStateFrom(section.components),
      )
    : null

  const config = sectionsConfig
    ? sectionsConfig.reduce((acc, section) => {
        return section ? { ...acc, ...section } : acc
      }, initialConfig || {})
    : initialConfig || {}

  const [promptState, setPromptState] = useState(config)

  const router = useRouter()

  const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
    useState(false)

  const [actionLoading, setActionLoading] = useState(false)

  async function addToStorage() {
    if (indexed.status !== "ready") {
      console.error("Indexed is not ready")
      return
    }

    const payload: RestyleStorageType = {
      tool: "ai_restyle",
      id: DEFORUM_STORAGE_KEY,
      input,
      promptType,
      location: "local",
      blob: file,
    }

    await indexed.setData(payload)
  }

  async function submitData() {
    try {
      if (actionLoading) {
        throw new Error("Action is loading")
      }

      if (!controller) {
        throw new Error("Controller is not ready")
      }

      if (!params) {
        throw new Error("Params are not ready")
      }

      setActionLoading(true)

      let url = uploadedUrl

      if (!url) {
        const { upload, download, contentType } =
          await axios
            .post<DeforumVideoUrlResponse>(
              "/api/deforum-video-url",
              { content: file.type },
            )
            .then((res) => res.data)

        await axios.put(upload, file, {
          headers: {
            "Content-Type": contentType,
            "web-version": HEADER_WEB_VERSION,
          },
        })
        url = download
        setUploadedUrl(download)
      }

      let res = null
      if (promptState && promptState["resolution"])
        res = Number(promptState["resolution"])

      const resolution = res ?? MIN_RESOLUTION

      const changedDimensions = resizeDimensions(
        {
          width: controller.width,
          height: controller.height,
        },
        resolution,
      )

      const submitData: AISubmitRequest = {
        url,
        additional: input,
        style: promptType,
        tool: "ai_restyle",
        start: controller.startTime,
        duration: controller.selectedDuration,
        params: promptState ?? { resolution: 720 },
        price: calculateDurationPrice(
          controller.selectedDuration,
        ),
        ...changedDimensions,
      }

      await axios
        .post<AISubmitResponse>(
          "/api/deforum-submit",
          submitData,
        )
        .then((res) => res.data)

      setActionLoading(false)
      window.removeEventListener(
        "beforeunload",
        handleLeave,
      )

      queryClient.invalidateQueries("coins")

      await router.push("/profile/generations/all")
      editor.closePage()
    } catch (error) {
      const notifier = withNotify((t) =>
        notify(<ErrorMessage>{t}</ErrorMessage>),
      )

      notifier(error)
      console.error(error)

      setSubscriptionPopupOpen(false)
      setActionLoading(false)
    }
  }

  return (
    <ParamsContext.Provider value={params}>
      <DurationContext.Provider value={duration}>
        <ControllerContext.Provider value={controller}>
          <div className=" flex w-full justify-center overflow-y-scroll">
            <div className="mx-auto my-[24px] flex w-[1175] gap-[25px]">
              <div className="w-[calc(70vh/16*9)] min-w-[300px] max-w-[575px]">
                <VideoPreviewWithFrames
                  file={file}
                  closePage={async () => {
                    window.removeEventListener(
                      "beforeunload",
                      handleLeave,
                    )

                    if (
                      router.asPath === "/editor/restyle"
                    ) {
                      router.back()
                    }
                    editor.closePage()
                  }}
                />
              </div>
              <div className="flex w-[575px] flex-col gap-3">
                <div
                  className={clsx(
                    "flex flex-col items-stretch gap-[22px] rounded-[15px] bg-color-cell px-6 pb-[22px] pt-[16px] transition-all",
                    actionLoading &&
                      "pointer-events-none opacity-30",
                  )}>
                  <div className="relative flex flex-col gap-2">
                    <span className="text-[16px] font-700 text-blue-600">
                      {t("lbl_styles")}
                    </span>
                    <div className="absolute bottom-0 right-0 z-10 h-3 w-full bg-gradient-to-t from-color-cell to-color-white/0"></div>
                    <div className="no-scrollbar grid max-h-[340px] grid-cols-3 gap-2 overflow-y-scroll pb-3">
                      <div
                        className={clsx(
                          "relative h-[122px] overflow-hidden rounded-[8px]",
                          promptType === null
                            ? "border-primary-500 bg-[#FF31661F]"
                            : "bg-blue-100",
                          "select-none border-2 border-[transparent]",
                          "cursor-pointer transition-colors",
                          "flex flex-col items-center justify-center gap-1",
                        )}
                        onClick={() => setPromptType(null)}>
                        <CustomThemedResource
                          format="svg"
                          source="/general/custom-prompt"
                          alt="custom prompt"
                        />
                        <span className="font-600 text-blue-800">
                          {t("lbl_custom")}
                        </span>
                      </div>
                      {styles.map(({ name, id, image }) => (
                        <div
                          key={id}
                          className={clsx(
                            "relative h-[122px] overflow-hidden rounded-[8px]",
                            promptType === id &&
                              "border-primary-500",
                            "select-none border-2 border-[transparent]",
                            "cursor-pointer transition-colors",
                          )}
                          onClick={() => setPromptType(id)}>
                          <img
                            src={image}
                            alt={`cover of ${name} style`}
                            className={clsx(
                              "pointer-events-none h-full w-full object-cover",
                            )}
                          />
                          <div className="absolute bottom-0 left-0 h-8 w-full bg-gradient-to-t from-color-black/60 to-[transparent]"></div>
                          <span className="absolute bottom-1 left-2 text-[13px] font-600 text-color-white">
                            {name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <span className="text-[16px] font-700 text-blue-600">
                      {t("lbl_prompts")}
                    </span>
                    <div
                      className={clsx(
                        "no-scrollbar flex max-h-[312px] shrink-0 flex-col items-stretch gap-3 overflow-y-scroll transition-all",
                        actionLoading &&
                          "pointer-events-none opacity-30",
                      )}>
                      <div className="flex flex-col items-stretch gap-3">
                        <div className={clsx("relative")}>
                          <BorderTextareaResizable
                            placeholder={t(
                              "txt_prompt_placeholder_additional",
                            )}
                            setValue={setInput}
                            defaultValue={input}
                            className="group inline-block w-full resize-none !pr-10"
                            autoCorrect="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {promptState && (
                  <ParamtersTrigger
                    actionLoading={actionLoading}
                    promptState={promptState}
                    setPromptState={setPromptState}
                  />
                )}

                {isPro ? (
                  <button
                    disabled={
                      fileType === "unrecognized" ||
                      !canSubmit
                    }
                    className={clsx(
                      "mt-[15px] rounded-[10px] font-500",
                      "text-[16px] text-color-white disabled:opacity-50",
                      "disabled:pointer-events-none",
                      "bg-primary-500 hover:bg-primary-600",
                      "relative py-3 transition-colors",
                      "flex items-center justify-center gap-4",
                      actionLoading &&
                        "pointer-events-none",
                    )}
                    onClick={() => {
                      if (actionLoading) return
                      submitData()
                    }}>
                    <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2">
                      <img
                        className={clsx(
                          "h-6 w-6 animate-[spin_1s_infinite_linear]",
                          actionLoading
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                        src={assetUrl(
                          "/general/loading-dark.webp",
                        )}
                        alt="loading"
                      />
                    </div>

                    {t("lbl_generate")}

                    {promptState && params.params && (
                      <CostsCoins
                        coins={
                          getPromptPrice(
                            promptState,
                            params.params,
                            params.price,
                          ) +
                          calculateDurationPrice(
                            controller?.selectedDuration,
                          )
                        }
                      />
                    )}
                  </button>
                ) : (
                  <button
                    disabled={fileType === "unrecognized"}
                    className={clsx(
                      "mt-[15px] rounded-[10px] font-500",
                      "text-[16px] text-color-white disabled:opacity-50",
                      "disabled:pointer-events-none",
                      "bg-[#2F91FD] hover:bg-[#0264CF]",
                      "py-3 transition-colors",
                    )}
                    onClick={() =>
                      setSubscriptionPopupOpen(true)
                    }>
                    {t("txt_subscribe_to_continue")}
                  </button>
                )}
                <div className="h-[70px] w-full shrink-0"></div>
              </div>
            </div>
            <SubscriptionPopup
              isOpen={subscriptionPopupOpen}
              close={() => setSubscriptionPopupOpen(false)}
              location="/editor/restyle"
              addToStorage={addToStorage}
            />
          </div>
        </ControllerContext.Provider>
      </DurationContext.Provider>
    </ParamsContext.Provider>
  )
}

type FileType = "image" | "video" | "unrecognized"
function getFileType(file: Blob): FileType {
  try {
    if (file.type.startsWith("video")) {
      return "video"
    }

    if (file.type.startsWith("image")) {
      return "image"
    }
  } catch {}

  return "unrecognized"
}

export interface MediaDimensions {
  width: number
  height: number
}

export function resizeDimensions(
  dimensions: MediaDimensions,
  max: number,
): MediaDimensions {
  if (Math.max(dimensions.width, dimensions.height) < max) {
    return dimensions
  }

  if (dimensions.width > dimensions.height) {
    return {
      width: Math.floor(
        (max / dimensions.height) * dimensions.width,
      ),
      height: max,
    }
  }

  return {
    width: max,
    height: Math.floor(
      (max / dimensions.width) * dimensions.height,
    ),
  }
}

const COINS_PER_SECOND = 50
export function calculateDurationPrice(
  duration?: number,
): number {
  if (!duration) {
    return 0
  }

  if (duration <= MAX_FREE_MILLIS) {
    return 0
  }

  const additionalDuration = duration - MAX_FREE_MILLIS

  return Math.floor(
    (additionalDuration * COINS_PER_SECOND) / 1_000,
  )
}

function handleLeave(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = " "
}

interface MediaPreviewProps {
  file: Blob
  closePage: () => void
}

function VideoPreviewWithFrames(props: MediaPreviewProps) {
  const { file, closePage } = props
  const controller = useContext(ControllerContext)
  useVideoClipper({ file })
  const video = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const elem = video.current
    if (!elem) {
      return
    }

    if (!controller) {
      return
    }

    const handleProgress = () => {
      const startSeconds = controller.startTime / 1000
      const endSeconds =
        (controller.startTime +
          controller.selectedDuration) /
        1000

      if (elem.currentTime < startSeconds) {
        elem.currentTime = startSeconds
      } else if (elem.currentTime > endSeconds) {
        elem.currentTime = startSeconds
      }
    }

    elem.addEventListener("timeupdate", handleProgress)
    return () =>
      elem.removeEventListener("timeupdate", handleProgress)
  }, [controller])

  const videoUrl = useMemo(
    () => URL.createObjectURL(file),
    [file],
  )

  const { t } = useTranslation()

  return (
    <VideoProvider element={video} remember={[]}>
      <div className="flex flex-col gap-3">
        <div className="relative w-full overflow-hidden rounded-[16px] bg-blue-100">
          <button
            className="group absolute left-2 top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-blue-300 hover:bg-blue-400"
            onClick={closePage}>
            <img
              className="group-hover:hidden"
			  alt="close-in-circle"
              src={assetUrl("/general/close-in-circle.svg")}
            />
            <img
              className="hidden group-hover:block dark:hidden dark:group-hover:hidden"
			  alt="close-in-circle"
              src={assetUrl(
                "/general/close-in-circle-hover.svg",
              )}
            />
            <img
              className="hidden dark:hidden dark:group-hover:block"
              src={assetUrl(
                "/general/close-in-circle-hover-dark.svg",
              )}
			  alt="close-in-circle"
            />
          </button>
          <PlayWithProvider />
          <video
            loop
            src={videoUrl}
            className={clsx(
              "w-full object-cover",
              "transition-opacity",
              controller ? "opacity-100" : "opacity-0",
            )}
            ref={video}
          />
        </div>
        {controller ? (
          <>
            <div className="flex items-center gap-[6px]">
              <span className="font-600 uppercase text-blue-600">
                {t("label_duration")}
              </span>
              <img
                src={assetUrl("/general/coin.svg")}
                className={clsx(
                  calculateDurationPrice(
                    controller.selectedDuration,
                  ) > 0
                    ? "opacity-100"
                    : "opacity-0",
                  "transition-opacity",
                )}
				alt="coin icon"
              />
              <div className="flex-1" />
              <span className="rounded-[4px] bg-blue-200 p-1 text-[13px] font-500 text-blue-400">
                <span className="text-blue-700">
                  {(
                    controller.selectedDuration / 1000
                  ).toFixed(1)}
                  {" / "}
                </span>
                {(controller.duration / 1000).toFixed(1)}
                {"s"}
              </span>
            </div>
            {controller && (
              <ClipVideoSector
                className="h-[70px]"
                controller={controller}
                file={file}
              />
            )}
          </>
        ) : (
          <div className="h-[91px] w-3" />
        )}
      </div>
    </VideoProvider>
  )
}

export function CostsCoins(props: { coins: number }) {
  const { coins } = props

  const { userInfo } = useAuth()
  const { isCoinFree } = getUserEntitlements(
    userInfo.entitlements,
  )
  if (isCoinFree || !coins) {
    return <></>
  }

  return (
    <div className="flex gap-1 rounded-full bg-[#C6113F] px-1 text-[14px] font-700 text-color-white">
      +{coins}
      <img
        src={assetUrl("/general/coin.svg")}
        alt="coins"
      />
    </div>
  )
}

export interface PromptState {
  [id: string]: number | string | boolean
}

export function getPromptPrice(
  prompt: PromptState,
  params: PromptParams[],
  non_free_price: number,
) {
  for (const param of params) {
    const promptID = prompt[param.id]
    if (typeof promptID !== "number") {
      continue
    }

    if (param.type !== "slider") {
      continue
    }

    if (promptID > param.maxFreeValue) {
      return non_free_price
    }
  }

  return 0
}
