import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import axios from "axios"
import { NotificationFunction } from "./notification"

export async function mutateWithNotify(
	notify: NotificationFunction,
	result: () => Promise<unknown>,
	successMessage: string,
	errorMessage: string,
) {
	try {
		await result()
		notify(
			<SuccessMessage>{successMessage}</SuccessMessage>,
		)
	} catch (error) {
		console.error(error)
		notify(<ErrorMessage>{errorMessage}</ErrorMessage>)
	}
}

type Result<T> = [true, T] | [false, unknown]

export async function withSkeleton<T>(
	action: Promise<T>,
	setter: (status: boolean) => void,
): Promise<Result<T>> {
	setter(true)
	try {
		const result = await action
		setter(false)
		return [true, result]
	} catch (e) {
		console.error("Failed to perform action")
		setter(false)
		return [false, e]
	}
}

export function getErrorLog(e: unknown, page: string) {
	if (axios.isAxiosError(e)) {
		const statusCode = e.response?.status
		const message = e.response?.data.message || ""
		const endpoint = e.config?.url

		if (statusCode === 404) {
			console.warn(
				"Resource Not Found. \n",
				"From Page: " + page + "\n",
				"Endpoint: " + endpoint + "\n",
				"Message: " + message + "\n",
			)
		} else if (statusCode === 401) {
			console.error(
				"Unauthorized access." + "\n",
				"From Page: " + page + "\n",
				"Endpoint: " + endpoint + "\n",
				"Message: " + message + "\n",
			)
		} else if (statusCode === 500) {
			console.error(
				"Internal server error." + "\n",
				"From Page: " + page + "\n",
				"Endpoint: " + endpoint + "\n",
				"Message: " + message + "\n",
			)
		} else {
			console.error(
				"An unknown error occurred." + "\n",
				"From Page: " + page + "\n",
				"Endpoint: " + endpoint + "\n",
				"Message: " + message + "\n",
			)
		}
	} else {
		console.error("Unexpected error. From Page: " + page, e)
	}
}
