import { DropDownAlternative } from "@/comps/dropdown"
import { CustomThemedResource } from "@/comps/image"
import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import { useProgressive } from "@/comps/progressive"
import { SizedSkeleton } from "@/comps/skeleton"
import Tooltip, { TextTooltip } from "@/comps/tooltip"
import { useAdjustLogger } from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import {
	AITool,
	DeformEditorProps,
	DEFORUM_STORAGE_KEY,
	DynamicEditorProps,
	InputWithId,
	PhotoAiEditorProps,
	Prompt,
	RestyleEditorProps,
	TextToImageEditorProps,
	TextToVideoEditorProps,
} from "controllers/editors"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { HistoryEntity } from "pages/api/deforum-history"
import { DeforumPrivacyRequest } from "pages/api/deforum-privacy"
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useQueryClient } from "react-query"
import { DialogProps } from "sections/history/generations"
import { HistoryPopupProps } from "sections/history/history-popup"

export interface GeneratedVideoProps {
	canTryOther: boolean
	entity: HistoryEntity
	dontPushUrl?: boolean
	historyPopupState: HistoryPopupProps | null
	setHistoryPopupState: (
		history: HistoryPopupProps | null,
	) => void
	setDialogContent: (content: DialogProps) => void
	handleDelete: (id: number) => void
	setContentDeforum: (
		content: DeformEditorProps,
	) => Promise<boolean>
	setContentRestyle: (
		content: RestyleEditorProps,
	) => Promise<boolean>
	setContentPhotoAi: (
		content: PhotoAiEditorProps,
	) => Promise<boolean>
	setContentTextToImage: (
		content: TextToImageEditorProps,
	) => Promise<boolean>
	setContentTextToVideo: (
		content: TextToVideoEditorProps,
	) => Promise<boolean>
	selectCreatorTool?: (
		tool: AITool,
		content: DynamicEditorProps,
	) => void
}

export const skeletonColors = [
	"#81E68526",
	"#81E6E326",
	"#81B7E626",
	"#E681A626",
	"#E6D08126",
	"#8185E626",
	"#B081E626",
	"#E381E626",
	"#E6818126",
]

export function GeneratedVideo(props: GeneratedVideoProps) {
	const {
		dontPushUrl,
		canTryOther,
		setDialogContent,
		setHistoryPopupState,
		handleDelete,
		selectCreatorTool,
	} = props

	const {
		id,
		tool,
		privacy,
		initial,
		width,
		height,
		additional_prompt,
		face_object_url,
		createdAt,
		prompt,
		prompt_name,
		model_id,
	} = props.entity
	const { thumbnail, result, isVideo } =
		getThumbnailResultOfGeneration(props.entity)

	const thumbImage =
		typeof thumbnail === "string" ? thumbnail : thumbnail[0]

	const resultImage =
		typeof result === "string" ? result : result[0]

	const thumbReady = useProgressive(thumbImage)
	const resultReady = useProgressive(resultImage)

	const { t } = useTranslation()

	const [offset, setOffset] = useState(0)
	const [isHovered, setIsHovered] = useState(false)
	const [videoPaused, setVideoPaused] = useState(true)

	const isPublic = privacy === "public"

	const { notify } = useContext(NotificationContext)

	const queryClient = useQueryClient()

	const router = useRouter()
	const [isPublicLoading, setIsPublicLoading] =
		useState(false)

	const logAdjust = useAdjustLogger()

	async function handleCopyPrompt(text: string) {
		try {
			await navigator.clipboard.writeText(text)
			notify(
				<SuccessMessage>
					{t("common:txt_successfully_copied")}
				</SuccessMessage>,
			)
		} catch (e) {
			console.error(e)
			notify(
				<ErrorMessage>
					{t("common:txt_couldnt_copy")}
				</ErrorMessage>,
			)
		}
	}

	const availableButtons: (RoundButtonProps | null)[] = [
		{
			title: t("txt_save"),
			icon: "/ai-tools/history/download-monochrome.svg",
			href:
				typeof result === "string"
					? result
					: result[offset],
			onClick: () =>
				logAdjust?.logEvent("deform_ai_video_save"),
			download: true,
		},
		{
			title: t("txt_share"),
			icon: "/ai-tools/history/link-monochrome.svg",
			onClick: () =>
				setDialogContent({
					open: true,
					isPublic: isPublic,
					link: `${APP_HOST}/profile/generations/${id}`,
					id: id,
				}),
		},
		null,
	]

	async function handleRetry() {
		const promptType = props.entity.prompt
		const inputs =
			promptType === "custom"
				? createInputs({ type: "custom", content: [""] })
				: createInputs({
						type: "predefined",
						id: promptType,
				  })

		if (!selectCreatorTool) {
			return
		}

		switch (tool) {
			case "deform":
				selectCreatorTool(tool, {
					tool: "deform",
					id: DEFORUM_STORAGE_KEY,
					inputs,
					promptType,
					location: "remote",
					url: initial,
				})
				setHistoryPopupState(null)
				break

			case "ai_photo":
			case "ai_restyle":
				selectCreatorTool(tool, {
					tool,
					id: DEFORUM_STORAGE_KEY,
					input: "",
					promptType: promptType,
					location: "remote",
					url: initial,
				})
				setHistoryPopupState(null)
				break

			case "text_to_video":
			case "text_to_image":
				selectCreatorTool(tool, {
					tool,
					id: DEFORUM_STORAGE_KEY,
					promptType,
					text: initial,
					location: "remote",
					url: face_object_url,
				})
				setHistoryPopupState(null)
				break
		}
	}

	async function handleMakePublic(shouldBePublic: boolean) {
		if (isPublicLoading) {
			return
		}

		setIsPublicLoading(true)

		try {
			const payload: DeforumPrivacyRequest = {
				privacy: shouldBePublic ? "public" : "private",
				job: id,
			}

			await axios.post("/api/deforum-privacy", payload)
			queryClient.invalidateQueries("deforum-history")
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t(
						shouldBePublic
							? "txt_could_not_make_public"
							: "txt_could_not_make_private",
					)}
				</ErrorMessage>,
			)
		}

		setIsPublicLoading(false)
	}

	const scrollToImage = useCallback(
		function (index: number) {
			const nextImage = document
				.getElementsByClassName(
					`ai-art-generation-${thumbnail}-${index}`,
				)
				.item(0)
			if (!nextImage) {
				return
			}
			const parent = nextImage.parentElement
			if (!parent) {
				return
			}

			const childX = nextImage.getBoundingClientRect().x
			const parentX = parent.getBoundingClientRect().x
			const childOffset = childX - parentX
			parent.scrollBy({
				behavior: "smooth",
				left: childOffset,
			})
		},
		[thumbnail],
	)

	useEffect(() => {
		scrollToImage(0)
	}, [scrollToImage, thumbnail])

	const backgroundColor = useMemo(
		() =>
			skeletonColors[
				Math.floor(Math.random() * skeletonColors.length)
			],
		[],
	)

	if (!thumbReady && !resultReady) {
		return (
			<div
				style={{
					aspectRatio: width / height,
					backgroundColor,
				}}
				className={clsx(
					`min-h-[130px] w-full overflow-hidden rounded-[6px] desktop:min-h-[210px]`,
				)}>
				<SizedSkeleton
					className={`h-full w-full !bg-[transparent]`}
				/>
			</div>
		)
	}

	return (
		<div
			className={clsx(
				"relative w-full rounded-[6px] bg-blue-200",
				"group cursor-pointer text-color-white",
			)}>
			{typeof thumbnail !== "string" &&
				thumbnail.length > 1 && (
					<>
						<button
							disabled={offset === 0}
							onClick={() => scrollToImage(offset - 1)}
							className={clsx(
								"absolute left-2 top-[calc(50%-12px)] z-20 flex h-6 w-6 items-center",
								"justify-center rounded-full border border-[transparent] bg-[#00000052]",
								"opacity-0 backdrop-blur-[10px] transition-all",
								"hover:border-color-white/60 disabled:border-[transparent]",
								isHovered
									? "opacity-0"
									: "group-hover:opacity-100",
							)}>
							<img
								src={assetUrl(
									"/ai-tools/history/arrow-white.svg",
								)}
								alt="arrow"
							/>
						</button>
						<button
							disabled={offset === thumbnail.length - 1}
							onClick={() => scrollToImage(offset + 1)}
							className={clsx(
								"absolute right-2 top-[calc(50%-12px)] z-20 flex",
								"h-6 w-6 items-center justify-center rounded-full",
								"border border-[transparent] bg-[#00000052] opacity-0",
								"backdrop-blur-[10px] transition-all hover:border-color-white/60",
								"disabled:border-[transparent]",
								isHovered
									? "opacity-0"
									: "group-hover:opacity-100",
							)}>
							<img
								src={assetUrl(
									"/ai-tools/history/arrow-white.svg",
								)}
								alt="arrow"
								className="rotate-180"
							/>
						</button>
					</>
				)}

			<div
				className={clsx(
					"absolute bottom-0 left-0 z-20 hidden w-full desktop:block",
					"opacity-0 transition-all group-hover:opacity-100",
					"rounded-b-[6px]",
					"bg-gradient-to-t from-color-black/80 to-[transparent]",
					"flex cursor-auto flex-col items-center",
				)}>
				{(additional_prompt.trim().length > 0 ||
					tool === "text_to_image") && (
					<div
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						className={clsx(
							"peer relative flex w-full flex-row items-end justify-between p-3 pt-0",
						)}>
						<TextTooltip
							showAlways={initial.length > 144}
							className={clsx("max-w-full pr-6")}
							title={
								tool === "text_to_image"
									? initial
									: additional_prompt
							}>
							<p
								className={clsx(
									"text-sm font-400 transition-[max-height]",
									isHovered
										? "line-clamp-4 max-h-[80px] whitespace-normal"
										: "max-h-[20px] truncate",
								)}>
								{tool === "text_to_image"
									? initial
									: additional_prompt}
							</p>
						</TextTooltip>
						<Tooltip
							title={t("common:txt_copy")}
							className={clsx(
								"!absolute bottom-3 right-3",
								isHovered && "!absolute bottom-3 right-3",
							)}
							showAlways>
							<div
								onClick={() =>
									handleCopyPrompt(
										tool === "text_to_image"
											? initial
											: additional_prompt,
									)
								}
								className={clsx(
									"flex h-6 w-6 shrink-0 cursor-pointer items-center",
									"justify-center rounded-full bg-[#E9EDF738] transition-colors",
									"border border-[transparent] hover:border-color-white/60",
								)}>
								<img
									src={assetUrl(
										"/ai-tools/history/copy.svg",
									)}
									alt="copy"
									className="h-3 w-3"
								/>
							</div>
						</Tooltip>
					</div>
				)}

				<div className="flex w-full flex-row items-end justify-between px-3 pb-3">
					<div className="hidden gap-2 desktop:flex">
						{availableButtons.map((singleButton, index) => {
							if (singleButton === null) {
								return
							}

							return (
								<RoundButton
									{...singleButton}
									key={index}
								/>
							)
						})}
					</div>

					{typeof thumbnail !== "string" &&
						thumbnail.length > 1 && (
							<div className="flex flex-row gap-[5px] rounded-[40px] bg-[#00000066] px-[10px] py-1 opacity-100 transition-all">
								{thumbnail.map((_, index) => (
									<div
										key={index}
										onClick={() => scrollToImage(index)}
										className={clsx(
											"h-[6px] w-[6px] cursor-pointer rounded-full transition-all",
											offset === index
												? "scale-[1.4] bg-primary-500"
												: "scale-100 bg-color-white",
										)}></div>
								))}
							</div>
						)}
				</div>
			</div>

			{typeof thumbnail !== "string" &&
				thumbnail.length > 1 && (
					<div
						style={{
							left: `calc(50% - ${
								(thumbnail.length * 5 +
									(thumbnail.length - 1) * 5 +
									20) /
								2
							}px)`,
						}}
						className="absolute bottom-2 z-10 flex flex-row gap-[5px] rounded-[40px] bg-[#00000066] px-[10px] py-1 desktop:hidden">
						{thumbnail.map((_, index) => (
							<div
								key={index}
								onClick={() => scrollToImage(index)}
								className={clsx(
									"h-[5px] w-[5px] cursor-pointer rounded-full transition-all",
									offset === index
										? "scale-[1.4] bg-primary-500"
										: "scale-100 bg-color-white",
								)}></div>
						))}
					</div>
				)}

			<div className="absolute left-2 top-[14px]">
				{isVideo && (
					<img
						src={assetUrl(
							"/ai-tools/history/video-icon.svg",
						)}
						alt="video-icon"
						className={clsx(
							"h-4 w-4 transition-opacity",
							videoPaused ? "opacity-100" : "opacity-0",
						)}
					/>
				)}
			</div>

			<div className="absolute left-[calc(50%-9px)] top-3">
				{privacy === "public" && (
					<img
						src={assetUrl(
							"/ai-tools/history/globe-icon.svg",
						)}
						alt="private-icon"
						className="h-5 w-5"
					/>
				)}
			</div>

			<a
				href={`/profile/generations/${encodeURIComponent(
					id,
				)}`}
				onClick={(e) => {
					e.preventDefault()

					if (!dontPushUrl) {
						router.push(
							`/profile/generations/${id}`,
							undefined,
							{ shallow: true },
						)
					}

					setHistoryPopupState({
						onClose: () => setHistoryPopupState(null),
						selectCreatorTool: selectCreatorTool,
						id,
						imageOrVideo: isVideo
							? result + "#t=0.001"
							: result,
						previewImage:
							typeof thumbnail === "string"
								? thumbnail
								: thumbnail[0],
						tool: tool,
						createdAt,
						customPrompt: additional_prompt,
						privacy: privacy,
						prompt,
						setDialogContent,
						promptName: prompt_name,
						initial,
						handleRetry,
						notShallow: Boolean(dontPushUrl),
						handleDelete,
						aspectRatio: width / height,
						model_id,
					})
				}}>
				{typeof result === "string" ? (
					!isVideo ? (
						<img
							src={result}
							alt="thumbnail of the result"
							className="min-h-[130px] w-full rounded-[6px] object-cover desktop:min-h-[210px]"
						/>
					) : (
						<video
							src={result + "#t=0.001"}
							poster={
								typeof thumbnail === "string"
									? thumbnail
									: thumbnail[0]
							}
							playsInline
							loop
							preload="none"
							muted
							onPointerEnter={(e) => {
								setVideoPaused(false)
								e.currentTarget.play().catch(console.error)
							}}
							onPointerLeave={(e) => {
								setVideoPaused(true)
								e.currentTarget.pause()
								e.currentTarget.currentTime = 0.001
							}}
							style={{ aspectRatio: width / height }}
							className={clsx(
								"w-full object-cover",
								"min-h-[130px] rounded-[6px] desktop:min-h-[210px]",
							)}
						/>
					)
				) : (
					<div
						className={clsx(
							"w-full overflow-hidden object-cover",
							"rounded-[6px]",
						)}>
						<div
							onScroll={(event) => {
								const container = event.target
								if (container instanceof HTMLElement) {
									const snapIndex = Math.floor(
										container.scrollLeft /
											container.offsetWidth,
									)

									setOffset(snapIndex)
								}
							}}
							className="no-scrollbar top-0 flex h-full snap-x snap-mandatory flex-row gap-12 overflow-x-scroll transition-all ">
							{typeof thumbnail !== "string" &&
								thumbnail.map((thumb, index) => (
									<img
										key={thumb}
										src={thumb}
										alt="thumb"
										className={clsx(
											" h-full min-h-[130px] w-full shrink-0 snap-center rounded-[6px] object-cover desktop:min-h-[210px]",
											`ai-art-generation-${thumbnail}-${index}`,
										)}
									/>
								))}
						</div>
					</div>
				)}
			</a>

			<div className="absolute right-2 top-2">
				<DropDownAlternative
					hover
					className="h-[32px] w-[32px]"
					trigger={
						<img
							src={assetUrl("/ai-tools/history/more.svg")}
							alt="more icon"
							className={clsx(
								"z-30 h-[32px] w-[32px] rotate-90 rounded-full bg-[transparent] object-cover transition-all hover:bg-color-black/30",
							)}
						/>
					}
					contentClassName="pt-3 absolute z-[50] right-1 top-full w-[170px] translate-x-4 tablet:w-[231px] desktop:left-1/2 desktop:-translate-x-1/2 desktop:right-[unset]">
					<div className="rounded-[12px] bg-color-popup-cell px-1 py-3 tablet:px-2">
						<button
							disabled={isPublicLoading}
							onClick={(e) => {
								e.preventDefault()
								handleMakePublic(!isPublic)
							}}
							className={clsx(
								"mb-1 flex items-center gap-3 px-4 py-3 text-[14px] font-500 text-blue-800",
								"w-full rounded-[8px] transition-colors hover:bg-[rgba(111,131,187,0.1)]",
								"disabled:hover:bg-transparent disabled:opacity-50",
							)}>
							<CustomThemedResource
								format="svg"
								source={
									isPublic
										? "/ai-tools/history/lock"
										: "/ai-tools/history/globe"
								}
							/>
							{isPublic
								? t("txt_make_private")
								: t("txt_make_public")}
						</button>
						<div className="h-[2px] w-full border-b border-blue-100"></div>
						<button
							disabled={!canTryOther}
							className={clsx(
								"mt-1 flex items-center gap-3 px-4 py-3 text-[14px] font-500 text-blue-800",
								"w-full rounded-[8px] transition-colors hover:bg-[rgba(111,131,187,0.1)]",
								"disabled:hover:bg-transparent disabled:opacity-50",
							)}
							onClick={handleRetry}>
							<CustomThemedResource
								format="svg"
								source="/general/try-again"
							/>
							{tool === "text_to_image"
								? t("txt_regenerate")
								: t("txt_try_other_style")}
						</button>
						<a
							onClick={() =>
								logAdjust?.logEvent("deform_ai_video_save")
							}
							href={
								typeof result === "string"
									? result
									: result[offset]
							}
							download={true}
							className={clsx(
								"flex items-center gap-3 px-4 py-3 text-[14px] font-500 text-blue-800 desktop:hidden",
								"w-full rounded-[8px] transition-colors hover:bg-[rgba(111,131,187,0.1)]",
								"disabled:hover:bg-transparent disabled:opacity-50",
							)}>
							<CustomThemedResource
								format="svg"
								source="/ai-tools/history/save"
							/>
							{t("txt_save")}
						</a>
						<button
							onClick={() =>
								setDialogContent({
									open: true,
									isPublic: isPublic,
									link: `${APP_HOST}/profile/generations/${id}`,
									id: id,
								})
							}
							className={clsx(
								"flex items-center gap-3 px-4 py-3 text-[14px] font-500 text-blue-800 desktop:hidden",
								"w-full rounded-[8px] transition-colors hover:bg-[rgba(111,131,187,0.1)]",
								"disabled:hover:bg-transparent disabled:opacity-50",
							)}>
							<CustomThemedResource
								format="svg"
								source="/ai-tools/history/share"
								className="w-[23px]"
							/>
							{t("txt_share")}
						</button>
						<button
							onClick={() => handleDelete(id)}
							className={clsx(
								"flex items-center gap-3 px-4 py-3 text-[14px] font-500",
								"w-full rounded-[8px] transition-colors hover:bg-[rgba(111,131,187,0.1)]",
								"disabled:hover:bg-transparent text-color-error disabled:opacity-50",
							)}>
							<img
								src={assetUrl(
									"/ai-tools/history/trash.svg",
								)}
								alt="trash"
							/>
							{t("lbl_delete")}
						</button>
					</div>
				</DropDownAlternative>
			</div>
		</div>
	)
}

function getThumbnailResultOfGeneration(
	entity: HistoryEntity,
) {
	switch (entity.tool) {
		case "ai_restyle":
			return {
				thumbnail: entity.thumbnailAfter,
				isVideo: true,
				result: entity.result,
				prompt_name: entity.prompt_name,
			}
		case "deform":
			return {
				thumbnail: entity.thumbnail,
				isVideo: true,
				result: entity.result,
				prompt_name: entity.prompt_name,
			}

		case "ai_photo":
			return {
				thumbnail: entity.thumbnailAfter,
				isVideo: false,
				result: entity.result,
				prompt_name: entity.prompt_name,
			}

		case "text_to_image":
			return {
				thumbnail: entity.thumbnail,
				isVideo: false,
				result: entity.result,
				prompt_name: entity.prompt_name,
			}

		case "text_to_video":
			return {
				thumbnail: entity.thumbnail,
				isVideo: true,
				result: entity.result,
				prompt_name: entity.prompt_name,
			}
	}
}

interface RoundButtonProps {
	title: string
	icon: string
	onClick?: () => void
	href?: string
	download?: boolean
}

function RoundButton(props: RoundButtonProps) {
	const { href, icon, download, onClick, title } = props

	if (href !== undefined) {
		return (
			<Tooltip title={title} showAlways>
				<a
					href={href}
					download={download}
					className="h-[38px] w-[38px] rounded-full border border-[transparent] bg-[transparent] transition-all hover:border-color-white/60">
					<img
						src={assetUrl(icon)}
						alt="round icon"
						className={clsx("h-full w-full object-cover")}
					/>
				</a>
			</Tooltip>
		)
	}

	return (
		<Tooltip title={title} showAlways>
			<button
				onClick={onClick}
				className="h-[38px] w-[38px] rounded-full border border-[transparent] bg-[transparent] transition-all hover:border-color-white/60">
				<img
					src={assetUrl(icon)}
					alt="round icon"
					className={clsx("h-full w-full object-cover")}
				/>
			</button>
		</Tooltip>
	)
}

function createInput(text = ""): InputWithId {
	return {
		text,
		id: Math.random().toString(),
	}
}

function createInputs(initial: Prompt): InputWithId[] {
	if (initial.type === "predefined") {
		return [createInput()]
	}

	return initial.content.map(createInput)
}
